import { FillInfoContent } from './FillInfoContent'
import TrialRequestContent, { type TrialRequestContentConfig } from './TrialRequestContent'

type RegistrationProps =
  | {
      step: 'trial-request'
      trialRequestContentConfig: TrialRequestContentConfig
    }
  | {
      step: 'fill-info'
    }

const Registration = (props: RegistrationProps) => (
  <>
    {props.step === 'trial-request' && (
      <TrialRequestContent config={props.trialRequestContentConfig} />
    )}
    {props.step === 'fill-info' && <FillInfoContent />}
  </>
)

export default Registration
