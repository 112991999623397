import type { UntitledIconData } from '@faceup/icons'
import { ulUpload03 } from '@faceup/icons/ulUpload03'
import type { Plan } from '@faceup/utils'
import type { ReactNode } from 'react'

export type UpsellData = {
  name: string
  title: ReactNode
  description: ReactNode
  features: ReactNode[]
  icon: UntitledIconData
  image?: ReactNode
  upgradeTo: Plan | null // null is to show only `Contact us` button, without upgrading to specific plan
  showUpsell: boolean
}

export const upsellDisabled: UpsellData = {
  title: '',
  description: '',
  features: [],
  icon: ulUpload03,
  upgradeTo: null,
  showUpsell: false,
  name: '',
}
