import { UntitledIcon } from '@faceup/icons'
import { ulRocket02 } from '@faceup/icons/ulRocket02'
import { Link, useMatchPath } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Space, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import useSubscriptionDetail from '../../hooks/useSubscriptionDetail'
import useAnalytics from '../../utils/analytics'
import SubscriptionEndedModal from '../SubscriptionEndedModal'
import { PaymentNoticeAlertBar } from './PaymentNoticeAlertBar'
import { PaymentNoticeMessage } from './PaymentNoticeMessage'

const { Text } = Typography

const messages = defineMessages({
  buy: 'Administration.trial.buy',
  renew: 'Administration.paymentNotice.renew',
})

const fragments = {
  PaymentNotice_company: graphql(`
    fragment PaymentNotice_company on Company {
      id
      realInstitution

      billing {
        id
        subscriptionStatus
        subscriptionEnd
      }

      ...SubscriptionDetail_company
      ...PaymentNoticeMessage_company
      ...PaymentNoticeAlertBar_company
    }
  `),
}

type Variant = 'inline' | 'alertBar'

type PaymentNoticeProps = {
  mother: FragmentType<typeof fragments.PaymentNotice_company>
  variant?: Variant
}

const PaymentNotice = ({ mother: _mother, variant = 'inline' }: PaymentNoticeProps) => {
  const mother = getFragmentData(fragments.PaymentNotice_company, _mother)
  const { state } = useSubscriptionDetail({ institution: mother })
  const { trackBuyActionClicked } = useAnalytics()

  const realInstitution = mother?.realInstitution
  const showPaymentInfo =
    ['trialExpired', 'cancelled', 'inTrial', 'nonRenewing'].includes(state) && realInstitution

  const matchPath = useMatchPath()
  const isSettingsInvoicing = !!matchPath(routes => routes.settingsInvoicing())
  const forceSubscriptionEndedModal = ['trialExpired', 'cancelled'].includes(state)

  if (!showPaymentInfo) {
    return null
  }

  const variants: Record<Variant, () => ReactNode> = {
    alertBar: () => (
      <>
        <PaymentNoticeAlertBar company={mother} />{' '}
        <SubscriptionEndedModal
          visible={forceSubscriptionEndedModal && !isSettingsInvoicing}
          variant={state === 'trialExpired' ? 'trialEnded' : 'subscriptionEnded'}
        />
      </>
    ),
    inline: () => (
      <Space size='large'>
        <Text strong>
          <PaymentNoticeMessage institution={mother} />
        </Text>
        <Link to={routes => routes.settingsInvoicing()} onClick={() => trackBuyActionClicked()}>
          <Button
            iconBefore={
              <UntitledIcon icon={ulRocket02} fontSize='1rem' style={{ marginRight: '0.5rem' }} />
            }
          >
            {state === 'nonRenewing' ? (
              <FormattedMessage {...messages.renew} />
            ) : (
              <FormattedMessage {...messages.buy} />
            )}
          </Button>
        </Link>
      </Space>
    ),
  }
  return variants[variant]()
}

export default PaymentNotice
