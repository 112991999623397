import { useUser } from '@faceup/member'
import { useEffect, useState } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  useUnreadFollowUpMessages_followupComment: graphql(`
    fragment useUnreadFollowUpMessages_followupComment on CompanyReportFollowUpComment {
      id
      author {
        ... on CompanyVictim {
          id
        }
        ... on Member {
          id
        }
      }
      readByMemberIds
      isMessageReadByVictim
    }
  `),
}

type useUnreadFollowUpMessages_followupComment = NonNullable<
  FragmentType<typeof fragments.useUnreadFollowUpMessages_followupComment>[' $fragmentRefs']
>['useUnreadFollowUpMessages_followupCommentFragment']

type UseUnreadFollowUpMessages = (
  comments: FragmentType<typeof fragments.useUnreadFollowUpMessages_followupComment>[],
  _wrapPropsInBrackets?: never
) => {
  unreadMessagesCount: number
  firstUnreadMessageId: null | string
  updateCachedUnreadMessages: () => void
}

export const useUnreadFollowUpMessages: UseUnreadFollowUpMessages = (
  _comments,
  // Otherwise comments are not wrapped in brackets and biome doesn't like if as hook dependency
  _wrapPropsInBrackets
) => {
  const comments = getFragmentData(fragments.useUnreadFollowUpMessages_followupComment, _comments)
  const [cachedMessages, setCachedMessages] = useState<useUnreadFollowUpMessages_followupComment[]>(
    []
  )
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0)
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<null | string>(null)
  const user = useUser()

  useEffect(() => {
    if (comments.length !== cachedMessages.length) {
      setCachedMessages(comments)
    }
  }, [comments, cachedMessages.length])

  useEffect(() => {
    const userId = user.data.id
    const numberOfUnreadFollowUpMessages = cachedMessages.filter(item => {
      return item?.author ? isMessageUnread(item, userId, false) : false
    }).length
    setNumberOfUnreadMessages(numberOfUnreadFollowUpMessages)
    const firstUnreadMessageId =
      cachedMessages.find(message =>
        message ? isMessageUnread(message, userId, user.application === 'follow-up') : false
      )?.id ?? ''
    setFirstUnreadMessageId(firstUnreadMessageId)
  }, [cachedMessages, user])

  return {
    unreadMessagesCount: numberOfUnreadMessages,
    firstUnreadMessageId,
    updateCachedUnreadMessages: () => {
      setCachedMessages(comments)
    },
  }
}

const isMessageUnread = (
  message: NonNullable<useUnreadFollowUpMessages_followupComment>,
  userId: string,
  isFollowUp: boolean
) => {
  return (
    message?.author?.id !== userId &&
    // in akutan, filter messages, which are not already read by myself
    ((!message?.readByMemberIds?.some(m => m === userId) && !isFollowUp) ||
      // in followup, filter messages, which are not already read by myself
      (!message?.isMessageReadByVictim && isFollowUp))
  )
}
