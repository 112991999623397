import { FrontendPermissionType } from '@faceup/utils'

export type Categories = {
  id: string
  name: string
}[]

export type Permission = {
  type: FrontendPermissionType
  enabled: boolean
  additionalData?: {
    categoryIds?: string[] | null
  } | null
}

export type OrganizationalUnit = {
  id: string
}

export type Permissions = Permission[]

export const findPermissionByPermissionType: (
  permissions: Permissions,
  FrontendPermissionType: FrontendPermissionType
) => Permission | undefined = (permissions, FrontendPermissionType) =>
  permissions.find(permission => permission.type === FrontendPermissionType)

export const getReportAccessCategories: (
  permissions: Permissions,
  categories: Categories
) => string[] = (permissions, categories) => {
  const categoryIds = findPermissionByPermissionType(
    permissions,
    FrontendPermissionType.ReportAccess
  )?.additionalData?.categoryIds
  if (categoryIds === undefined) {
    return []
  }
  if (categoryIds === null) {
    return categories.map(category => category.id)
  }
  return categoryIds
}

export type ReportAccessVariant = 'all' | 'specific' | 'assigned'

export const getReportAccessVariant: (
  permissions: Permissions,
  institutionIds: string[],
  allInstitutions: OrganizationalUnit[]
) => ReportAccessVariant = (permissions, institutionIds, allInstitutions) => {
  const categoryIds = findPermissionByPermissionType(
    permissions,
    FrontendPermissionType.ReportAccess
  )?.additionalData?.categoryIds
  const hasAccessToAllOrganizationalUnits = institutionIds.length === allInstitutions.length
  if (categoryIds === null && hasAccessToAllOrganizationalUnits) {
    return 'all'
  }
  if ((categoryIds && categoryIds.length === 0) || institutionIds.length === 0) {
    return 'assigned'
  }
  return 'specific'
}
