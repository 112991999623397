import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { usCheckCircle } from '@faceup/icons/usCheckCircle'
import { usInfoCircle } from '@faceup/icons/usInfoCircle'
import { usXCircle } from '@faceup/icons/usXCircle'
import { App, type ModalFuncProps } from 'antd'

const sharedStyles: ModalFuncProps['styles'] = {
  content: { padding: '20px 24px', borderRadius: '12px' },
  mask: {
    backgroundColor: '#062D46B2',
  },
}

const sharedProps: ModalFuncProps = {
  styles: sharedStyles,
  closable: true,
  centered: true,
  okCancel: true,
  closeIcon: <UntitledIcon icon={ulXClose} size={22} color='#062D46' />,
}

// this seems like an antd error / duplicate type
type ModalType = Exclude<NonNullable<ModalFuncProps['type']>, 'warn'>

const icons: Record<ModalType, { icon: UntitledIconData; color: string }> = {
  info: { icon: usInfoCircle, color: '#0085FF' },
  success: { icon: usCheckCircle, color: '#32C27B' },
  error: { icon: usXCircle, color: '#F54070' },
  warning: { icon: usInfoCircle, color: '#FA914B' },
  confirm: { icon: ulInfoCircle, color: '#FA914B' },
}

export const useModal = () => {
  const app = App.useApp()

  const getIcon = (type: ModalType) => {
    return type ? (
      <UntitledIcon icon={icons[type].icon} color={icons[type].color} size={21} />
    ) : undefined
  }

  const createCustomModal = (type: ModalType, props: ModalFuncProps) => {
    return app.modal[type]({
      icon: getIcon(type),
      ...sharedProps,
      ...props,
    })
  }

  return {
    info: (props: ModalFuncProps) => createCustomModal('info', props),
    success: (props: ModalFuncProps) => createCustomModal('success', props),
    error: (props: ModalFuncProps) => createCustomModal('error', props),
    warning: (props: ModalFuncProps) => createCustomModal('warning', props),
    confirm: (props: ModalFuncProps) => createCustomModal('confirm', props),
  }
}
