import { UntitledIcon } from '@faceup/icons'
import { ulX } from '@faceup/icons/ulX'
import { UserContext } from '@faceup/member'
import { Avatar, Badge, HubSpotCard } from '@faceup/ui'
import { Language, VerificationStatus } from '@faceup/utils'
import { LoadingOverlay, UnstyledButton } from '@mantine/core'
import { useContext, useEffect, useState } from 'react'
import { ProductFruits, useProductFruitsApi } from 'react-product-fruits'
import { ModalsContext } from '../Contexts/ModalsContext'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

import { ucLifeRingButton } from '../customIcons/ucLifeRingButton'
import useHubSpotChat from '../hooks/useHubSpotChat'
import Auth from '../utils/auth'

const fragments = {
  ProductFruitsLifeRing_institution: graphql(`
    fragment ProductFruitsLifeRing_institution on Company {
      id
      isVerified
      type
      country
      defaultForm {
        id
        shortLink
      }
      billing {
        id
        plan
        subscriptionStatus
        subscriptionStart
      }
    }
  `),
}

type ProductFruitsLifeRingProps = {
  mother?: FragmentType<typeof fragments.ProductFruitsLifeRing_institution>
}

const attachProductFruitsToCustomElement = () => {
  const customLifeRingLauncher = document.querySelector('#customLifeRingLauncher')
  window?.productFruits?.api?.inAppCenter?.attachToCustomElement(customLifeRingLauncher)
}

export const ProductFruitsLifeRing = ({ mother: _mother }: ProductFruitsLifeRingProps) => {
  const mother = getFragmentData(fragments.ProductFruitsLifeRing_institution, _mother)

  const { load: loadChat } = useHubSpotChat()
  const { isChatVisible, setIsChatVisible, chatUnreadMessages, isChatLoading } =
    useContext(ModalsContext)
  const [isProductFruitsVisible, setIsProductFruitsVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('productfruits_ready', attachProductFruitsToCustomElement)

    return () => {
      window.removeEventListener('productfruits_ready', attachProductFruitsToCustomElement)
    }
  }, [])

  useProductFruitsApi(() => {
    window.addEventListener('productfruits_button_ext_widget_init', loadChat)

    return () => {
      window.removeEventListener('productfruits_button_ext_widget_init', loadChat)
    }
  }, [])

  const { viewer } = useContext(UserContext)
  const isPartner = viewer?.isPartner === true

  // Username is static string except on production because of costs - ProductFruits is paid by active user
  const username =
    import.meta.env.VITE_ENVIRONMENT === 'production'
      ? Auth.getUserIdFromJWT(Auth.getJwt() ?? '')
      : (import.meta.env.VITE_ENVIRONMENT ?? 'stage')
  const [firstname, ...lastname] = viewer?.name?.split(' ') ?? ''
  const isVerified =
    mother?.isVerified || viewer?.partner?.verificationStatus === VerificationStatus.Approved
  const showProductFruits =
    !['development', 'preview'].includes(import.meta.env.VITE_ENVIRONMENT ?? '') && isVerified

  if (!viewer?.id) {
    return null
  }

  return (
    <>
      <HubSpotCard isVisible={isChatVisible}>
        <div id='hubspot-chat' />
        <LoadingOverlay transitionDuration={300} visible={isChatLoading} />
      </HubSpotCard>

      {showProductFruits && (
        <ProductFruits
          workspaceCode='cgcB6TXTebQXZieM'
          language={
            viewer?.language === Language.Sk || viewer?.language === Language.Cs ? 'cs' : 'en'
          }
          config={{
            hideInAppCenterLauncher: true,
          }}
          user={{
            username: username ?? '',
            firstname,
            email: viewer?.email ?? '',
            lastname: lastname.join(' '),
            signUpAt: viewer?.createdAt ?? '',
            props: {
              name: viewer?.name ?? '',
              status: mother?.billing?.subscriptionStatus as string,
              institution: mother?.type as string,
              version: mother?.billing?.plan as string,
              environment: import.meta.env.VITE_ENVIRONMENT ?? 'stage',
              shortId: mother?.defaultForm.shortLink as string,
              accountType: isPartner ? 'partner' : 'institution',
              country: mother?.country as string,
              subscriptionStart: mother?.billing?.subscriptionStart ?? '',
            },
          }}
        />
      )}

      <Badge color='error' count={isChatVisible ? 0 : chatUnreadMessages}>
        <UnstyledButton
          id='customLifeRingLauncher'
          onClick={() => {
            const productFruitsAppCenter = window?.productFruits?.api?.inAppCenter

            if (isChatVisible) {
              setIsChatVisible(!isChatVisible)
              productFruitsAppCenter?.show()
              productFruitsAppCenter?.close()
            }

            setIsProductFruitsVisible(productFruitsAppCenter?.isOpened())
          }}
        >
          <Avatar
            icon={
              <UntitledIcon
                icon={isChatVisible || isProductFruitsVisible ? ulX : ucLifeRingButton}
                size={20}
              />
            }
            style={{ backgroundColor: 'transparent', color: '#6F91B7', boxShadow: 'none' }}
          />
        </UnstyledButton>
      </Badge>
    </>
  )
}
