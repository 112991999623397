import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronUp: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-up',
  paths: [
    {
      shape: 'M18 15L12 9L6 15',
    },
  ],
}

export default ulChevronUp
