import { useApolloClient, useLazyQuery } from '@apollo/client'
import { useSearchParams } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { type Region as AwsRegion, parseRegion, regionHeaderName } from '@faceup/utils'
import { useLocalStorage } from '@mantine/hooks'
import { useEffect } from 'react'
import { sharedMessages } from '../Shared/translations'
import { useIntl } from '../TypedIntl'
import { graphql } from '../__generated__'

const query = {
  EmailDiscovery: graphql(`
    query EmailDiscoveryQuery($email: GraphQLString!) {
      region: emailDiscovery(email: $email)
    }
  `),
  TokenDiscovery: graphql(`
    query TokenDiscoveryQuery($token: GraphQLString!) {
      region: tokenDiscovery(token: $token)
    }
  `),
}

const REGION_KEY = 'region'

const deserialize = (value: string | null) => {
  return value ? (value as AwsRegion) : null
}

export const retrieveRegion = () => {
  const value = localStorage.getItem(REGION_KEY)
  return deserialize(value)
}

type RegionHookReturn = {
  region: AwsRegion | null | undefined
  setRegion: (region: AwsRegion) => void
  discoverByEmail: (email: string) => Promise<AwsRegion | null>
  discoverByToken: (token: string) => Promise<AwsRegion | null>
  loading: boolean
}

const useRegion = (): RegionHookReturn => {
  const client = useApolloClient()
  const [params] = useSearchParams()

  const { formatMessage } = useIntl()
  // this should be SupportedRegion
  const [region, setRegion] = useLocalStorage<AwsRegion | null>({
    key: REGION_KEY,
    defaultValue: parseRegion(params.get(regionHeaderName)),
    // we need custom de/serializing to keep backward compatibility, see the commit
    serialize: (value: AwsRegion | null): string => value ?? '',
    deserialize: (value: string): AwsRegion | null => deserialize(value),
  })

  const [fetchEmailDiscovery, { loading: emailDiscoveryLoading }] = useLazyQuery(
    query.EmailDiscovery,
    {
      fetchPolicy: 'cache-and-network',
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const [fetchTokenDiscovery, { loading: tokenDiscoveryLoading }] = useLazyQuery(
    query.TokenDiscovery,
    {
      fetchPolicy: 'cache-and-network',
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  // TODO: check whether this useEffect is necessary
  useEffect(() => {
    const storedRegion = retrieveRegion()

    if (region && region !== storedRegion && storedRegion) {
      client.stop()
      client.resetStore()
    }
  }, [region, client])

  const discoverByEmail = async (email: string) => {
    /* if (region) {
      return null
    } */

    const { data } = await fetchEmailDiscovery({
      variables: { email },
    })

    if (!data?.region) {
      return null
    }

    setRegion(data.region as AwsRegion)

    return data.region as AwsRegion
  }

  const discoverByToken = async (token: string) => {
    if (region) {
      return null
    }

    const { data } = await fetchTokenDiscovery({
      variables: { token },
    })

    if (!data?.region) {
      return null
    }

    setRegion(data.region as AwsRegion)

    return data.region as AwsRegion
  }

  return {
    region,
    setRegion,
    discoverByEmail,
    discoverByToken,
    loading: emailDiscoveryLoading || tokenDiscoveryLoading,
  }
}

export default useRegion
