import { Tag } from '@faceup/ui-base'
import type { ReportStatus as ReportStatusType } from '@faceup/utils'
import type { ComponentProps } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages<ReportStatusType>({
  Archived: 'Shared.report.status.Archived',
  Open: 'Shared.report.status.Open',
  Closed: 'Shared.report.status.Closed',
})

const config: Record<ReportStatusType, ComponentProps<typeof Tag>['color']> = {
  Archived: 'default',
  Open: 'blue',
  Closed: 'green',
}

type ReportStatusProps = {
  status: ReportStatusType
}

export const ReportStatus = (props: ReportStatusProps) => {
  const { status } = props
  return (
    <Tag color={config[status]} bordered={false} data-cy='report-info-status'>
      <FormattedMessage {...messages[status]} />
    </Tag>
  )
}
