import { UntitledIcon } from '@faceup/icons'
import { ulMessageChatSquare } from '@faceup/icons/ulMessageChatSquare'
import { Button, Card } from '@faceup/ui'
import { Alert } from '@faceup/ui-base'
import { BillingFrequency, ContactIssue, Institution, planMapName } from '@faceup/utils'
import { Grid, Group, Stack, Text, Title } from '@mantine/core'
import moment from 'moment-timezone'
import { useState } from 'react'
import { FormattedNumber } from 'react-intl'
import ContactSalesModal from '../../../Components/ContactSalesModal'
import { FormattedMessage, type TypedMessageDescriptor, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import useChargebeePortal from '../../../hooks/chargebee/useChargebeePortal'
import useSubscriptionDetail from '../../../hooks/useSubscriptionDetail'
import { useGlobalInfo } from '../../../locales'
import { mapPriceDivider } from '../CompanyInvoicing'
import type { BillingFrequency as BillingFrequencyType } from '../Components/BillingPeriodSwitcher'
import { useEmployeesCount } from '../hooks'

const messages = defineMessages({
  contactSalesTitle: 'Administration.trialEnded.contactSales',
  planEmployeesMonthly: 'Administration.companyInvoicing.plan.employeesMonthly',
  planEmployeesQuarterly: 'Administration.companyInvoicing.plan.employeesQuarterly',
  planEmployeesYearly: 'Administration.companyInvoicing.plan.employeesYearly',
  planStudentsMonthly: 'Administration.companyInvoicing.plan.studentsMonthly',
  planStudentsQuarterly: 'Administration.companyInvoicing.plan.studentsQuarterly',
  planStudentsYearly: 'Administration.companyInvoicing.plan.studentsYearly',
  invoiceHistory: 'Administration.companyInvoicing.invoiceHistory',
  nextEstimatedInvoiceTitle: 'Administration.companyInvoicing.nextEstimatedInvoice.title',
  changePlan: 'Administration.companyInvoicing.changePlan',
  renew: 'Administration.companyInvoicing.renew',
  manageBilling: 'Administration.companyInvoicing.manageBilling',
  billingPlanTitle: 'Administration.companyInvoicing.plan',
  vatInfo: 'Administration.companyInvoicing.vatInfo',
  subscriptionValidTo: 'Administration.companyInvoicing.subscriptionValidTo',
})

const fragments = {
  AfterPurchaseFragments_billing: graphql(`
    fragment AfterPurchaseFragments_billing on Billing {
      id
      subscriptionEnd
      price
      currency
      employees
      plan
      frequency
    }
  `),
  AfterPurchaseFragments_mother: graphql(`
    fragment AfterPurchaseFragments_mother on Company {
      id
      billing {
        id
        subscriptionEnd
        price
        currency
        employees
        plan
        frequency
      }
      ...SubscriptionDetail_company
    }
  `),
  AfterPurchaseFragments_priceList: graphql(`
    fragment AfterPurchaseFragments_priceList on PriceList {
      ...useEmployeesCountFragments_priceList
    }
  `),
}

// TODO: we are handling only `Up to` case, not `Over` case - create translations and simplify
const messagesForPlan: Record<Institution, Record<BillingFrequency, TypedMessageDescriptor>> = {
  [Institution.School]: {
    [BillingFrequency.Monthly]: messages.planStudentsMonthly,
    [BillingFrequency.Quarterly]: messages.planStudentsQuarterly,
    [BillingFrequency.Yearly]: messages.planStudentsYearly,
  },
  [Institution.Company]: {
    [BillingFrequency.Monthly]: messages.planEmployeesMonthly,
    [BillingFrequency.Quarterly]: messages.planEmployeesQuarterly,
    [BillingFrequency.Yearly]: messages.planEmployeesYearly,
  },
}

type AfterPurchaseProps = {
  mother: FragmentType<typeof fragments.AfterPurchaseFragments_mother>
  priceList: FragmentType<typeof fragments.AfterPurchaseFragments_priceList>
  changePlanAction: () => void
}

export const AfterPurchase = ({
  mother: _mother,
  priceList: _priceList,
  changePlanAction,
}: AfterPurchaseProps) => {
  const mother = getFragmentData(fragments.AfterPurchaseFragments_mother, _mother)
  const priceList = getFragmentData(fragments.AfterPurchaseFragments_priceList, _priceList)
  const { institution } = useGlobalInfo()
  const [isContactSalesModalVisible, setIsContactSalesModalVisible] = useState(false)
  const { isPortalAvailable, openBillingHistory, openManageBilling } = useChargebeePortal({
    motherId: mother.id,
  })
  const { getPriceVariant, getMessagesForVariants } = useEmployeesCount({ priceList })
  const subscriptionDetail = useSubscriptionDetail({ institution: mother })

  if (!mother.billing) {
    return null
  }

  const {
    price,
    subscriptionEnd: nextInvoice,
    plan,
    employees,
    currency,
    frequency,
  } = mother.billing

  const messagesForVariants = currency ? getMessagesForVariants(frequency, currency) : []
  const variant =
    (currency && employees && getPriceVariant(frequency, currency, employees)?.members) ?? 0
  const employeesMessage = messagesForVariants[variant]

  const billingFrequency = frequency.toLowerCase() as BillingFrequencyType

  const changePlanActionText =
    subscriptionDetail.state === 'nonRenewing' ? (
      <FormattedMessage {...messages.renew} />
    ) : (
      <FormattedMessage {...messages.changePlan} />
    )

  return (
    <>
      <Grid>
        <Grid.Col span={9}>
          <Card title={<FormattedMessage {...messages.billingPlanTitle} />}>
            <Stack>
              <Group position='apart'>
                <div>
                  <Title order={4}>
                    {planMapName[plan]}
                    {typeof price === 'number' && currency && (
                      <>
                        {' '}
                        -{' '}
                        <FormattedNumber
                          style='currency'
                          value={price / 100 / mapPriceDivider[billingFrequency]}
                          currency={currency}
                          maximumFractionDigits={0}
                        />
                      </>
                    )}
                  </Title>
                  <Text>
                    {institution && (
                      <FormattedMessage
                        {...messagesForPlan[institution][frequency]}
                        values={{
                          employees: employeesMessage,
                        }}
                      />
                    )}
                  </Text>
                </div>
                <Group>
                  <Button
                    variant='secondary'
                    onClick={() => setIsContactSalesModalVisible(true)}
                    iconBefore={<UntitledIcon icon={ulMessageChatSquare} />}
                  >
                    <FormattedMessage {...messages.contactSalesTitle} />
                  </Button>
                  <Button onClick={changePlanAction} variant='primary'>
                    {changePlanActionText}
                  </Button>
                </Group>
              </Group>
              {subscriptionDetail.state === 'nonRenewing' && (
                <Alert
                  message={
                    <FormattedMessage
                      {...messages.subscriptionValidTo}
                      values={{
                        date: subscriptionDetail.humanizedSubscriptionEndDate,
                      }}
                    />
                  }
                  type='warning'
                />
              )}
            </Stack>
          </Card>
        </Grid.Col>
        <Grid.Col span={3}>
          <Card title={<FormattedMessage {...messages.nextEstimatedInvoiceTitle} />}>
            <Stack>
              <Title order={4}>
                {subscriptionDetail.state !== 'nonRenewing' &&
                price !== null &&
                price !== undefined &&
                currency ? (
                  <FormattedNumber
                    style='currency'
                    value={price / 100}
                    currency={currency}
                    maximumFractionDigits={0}
                  />
                ) : (
                  '-'
                )}
              </Title>
              <Group position='apart'>
                <Text>
                  {nextInvoice && subscriptionDetail.state !== 'nonRenewing'
                    ? moment(nextInvoice).format('L')
                    : '-'}
                </Text>
                {isPortalAvailable && (
                  <Text>
                    <Button onClick={openBillingHistory} variant='text'>
                      <FormattedMessage {...messages.invoiceHistory} />
                    </Button>
                  </Text>
                )}
              </Group>
              <Text c='dimmed' fz='xs'>
                <FormattedMessage {...messages.vatInfo} />
              </Text>
              {isPortalAvailable && (
                <Button onClick={() => openManageBilling({})} variant='secondary'>
                  <FormattedMessage {...messages.manageBilling} />
                </Button>
              )}
            </Stack>
          </Card>
        </Grid.Col>
      </Grid>
      {isContactSalesModalVisible && (
        <ContactSalesModal
          contactIssue={ContactIssue.ContactViaBilling}
          onClose={() => setIsContactSalesModalVisible(false)}
          opened
        />
      )}
    </>
  )
}
