import { UntitledIcon } from '@faceup/icons'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Button, SimpleDragListItem } from '@faceup/ui'
import { Input } from '@faceup/ui-base'
import { MAX_SELECT_FORM_ITEM_OPTIONS_COUNT, SHORT_STRING_MAX_LENGTH } from '@faceup/utils'
import { Group, Stack, UnstyledButton } from '@mantine/core'
import { type Dispatch, type SetStateAction, useCallback } from 'react'
import ReactDragListView from 'react-drag-listview'
import { FormattedMessage, defineMessages } from '../../../../../../TypedIntl'
import type { SelectOption } from './hooks/useFormItemForm'

const messages = defineMessages({
  addOption: 'Administration.customization.formItems.select.addOption',
})

type OptionsListProps = {
  options: SelectOption[]
  setOptions: Dispatch<SetStateAction<{ value: SelectOption[]; error?: boolean | undefined }>>
  onReorder: (fromIndex: number, toIndex: number) => void
  onDeleteOption: (optionId: string) => void
  shouldDisplayDeleteButton: boolean
}

const dragListItemIdentifier = `select-form-item-option`

export const OptionsList = ({
  options,
  setOptions,
  onReorder,
  onDeleteOption,
  shouldDisplayDeleteButton,
}: OptionsListProps) => {
  const onAddOption = useCallback(() => {
    setOptions(prevOptions => ({
      ...prevOptions,
      value: [
        ...prevOptions.value,
        {
          id: `new-${Date.now()}`,
          label: '',
          order: prevOptions.value.length + 1,
        },
      ],
    }))
  }, [setOptions])

  return (
    <Stack>
      <ReactDragListView
        handleSelector={`.draggable.${dragListItemIdentifier}`}
        nodeSelector={`.${dragListItemIdentifier}-item`}
        onDragEnd={(fromIndex, toIndex) => {
          // toIndex is -1 when the item is dragged outside the list
          if (toIndex < 0) {
            return
          }
          onReorder(fromIndex, toIndex)
        }}
        lineClassName='drag-list-line'
      >
        {options.map(option => (
          <SimpleDragListItem key={option.id} isDraggable identifier={dragListItemIdentifier}>
            <Input
              suffix={
                shouldDisplayDeleteButton ? (
                  <UnstyledButton
                    onClick={() => {
                      onDeleteOption(option.id)
                    }}
                  >
                    <UntitledIcon icon={ulTrash03} />
                  </UnstyledButton>
                ) : null
              }
              value={option.label}
              onChange={event =>
                setOptions(prevOptions => ({
                  ...prevOptions,
                  value: prevOptions.value.map(prevOption => {
                    if (prevOption.id === option.id) {
                      return {
                        ...prevOption,
                        label: event.target.value,
                      }
                    }
                    return prevOption
                  }),
                }))
              }
              maxLength={SHORT_STRING_MAX_LENGTH}
            />
          </SimpleDragListItem>
        ))}
      </ReactDragListView>
      {options.length < MAX_SELECT_FORM_ITEM_OPTIONS_COUNT && (
        <Group position='left'>
          <Button onClick={onAddOption} variant='secondary'>
            <FormattedMessage {...messages.addOption} />
          </Button>
        </Group>
      )}
    </Stack>
  )
}
