import { useLazyQuery } from '@apollo/client'
import {
  type FieldValues,
  type FormItemWrapperProps,
  type UseControllerProps,
  useCustomController,
  yup,
} from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { FormItem, usePaginator } from '@faceup/ui'
import {
  Checkbox,
  Divider,
  Select,
  type SelectProps,
  Tooltip,
  Typography,
  notification,
} from '@faceup/ui-base'
import { NULL_UUID } from '@faceup/utils'
import type { ResultOf, VariablesOf } from '@graphql-typed-document-node/core'
import debounce from 'debounce'
import {
  type ComponentProps,
  type ReactNode,
  type SyntheticEvent,
  useEffect,
  useState,
} from 'react'
import { FormattedNumber } from 'react-intl'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'

const messages = defineMessages({
  selectedXFromY: 'Administration.multiSelect.selectedXFromY',
  selectAllWithTotal: 'Administration.multiSelect.selectAllWithTotal',
  loading: 'Administration.integrations.apps.addIntegration.loading',
  totalSelected: 'Administration.multiSelect.totalSelected',
  emailModalHasBeenSharedWith: 'Administration.surveys.emailModal.hasBeenSharedWith',
})

const messagesEmpty = defineMessages<EmptyVariant>({
  nothingFound: 'Administration.multiSelect.nothingFound',
  loading: 'Administration.integrations.apps.addIntegration.loading',
})

const employeeMultiselectQueries = {
  search: graphql(`
    query EmployeeMultiSelectSearchQuery(
      $motherId: UUID!
      $search: GraphQLString
      $activeOnly: Boolean!
      $surveyChannelConfig: UUID!
      $isSurveyChannelConfigId: Boolean!
      $page: Int!
      $rowsPerPage: Int!
    ) {
      employeesSearch(
        motherId: $motherId
        search: $search
        activeOnly: $activeOnly
        surveyChannelConfigId: $surveyChannelConfig
        page: $page
        rowsPerPage: $rowsPerPage
      ) {
        items {
          id
          name
          email
          hasBeenSharedSurvey(surveyChannelConfig: $surveyChannelConfig)
            @include(if: $isSurveyChannelConfigId)
        }
        totalCount
        totalUnsharedSurveyCount @include(if: $isSurveyChannelConfigId)
      }
    }
  `),
  preloadEmployees: graphql(`
    query DataManagementEmployeesPreloadQuery(
      $institutionId: UUID!
      $ids: [UUID!]!
      $surveyChannelConfig: UUID!
      $isSurveyChannelConfigId: Boolean!
    ) {
      employees(ids: $ids, motherId: $institutionId) {
        items {
          id
          name
          email
          hasBeenSharedSurvey(surveyChannelConfig: $surveyChannelConfig)
            @include(if: $isSurveyChannelConfigId)
        }
      }
    }
  `),
}

type FetchSearchVariables = VariablesOf<typeof employeeMultiselectQueries.search>
type Employee = NonNullable<
  NonNullable<ResultOf<typeof employeeMultiselectQueries.search>['employeesSearch']>['items']
>[number]
type EmployeeMultiSelectSearchQuery = NonNullable<
  ReturnType<
    NonNullable<(typeof employeeMultiselectQueries.search)['__apiType']>
  >['employeesSearch']
>

type ConfigItemType = 'default' | 'surveyShare'

type ConfigItem<T extends ConfigItemType, P extends object> = {
  type: T
} & P

type EmployeeMultiSelectConfig =
  | ConfigItem<'default', Record<string, unknown>>
  | ConfigItem<'surveyShare', { surveyConfigId: string }>

const employeeMultiSelectConfig: Record<
  ConfigItemType,
  {
    activeOnly: boolean
    isDisabled: (employee: Employee) => boolean
    disabledTooltipTitle: ReactNode
    selectableItemsCount: (employeesSearch: EmployeeMultiSelectSearchQuery) => number
  }
> = {
  default: {
    activeOnly: false,
    isDisabled: () => false,
    disabledTooltipTitle: null,
    selectableItemsCount: employeesSearch => {
      return employeesSearch.totalCount
    },
  },
  surveyShare: {
    activeOnly: true,
    disabledTooltipTitle: <FormattedMessage {...messages.emailModalHasBeenSharedWith} />,
    isDisabled: (employee: Employee) => {
      return Boolean(employee.hasBeenSharedSurvey)
    },
    selectableItemsCount: employeesSearch => {
      return employeesSearch.totalUnsharedSurveyCount ?? 0
    },
  },
}

const variants = ['include', 'exclude'] as const
type Variant = (typeof variants)[number]

const selectedTypes = ['empty', 'partial', 'all'] as const
type SelectedType = (typeof selectedTypes)[number]

type YupEmployeeMultiSelectProps = {
  canBeEmpty?: boolean
}

export const useYupEmployeeMultiSelect = ({ canBeEmpty }: YupEmployeeMultiSelectProps) => {
  const { formatMessage } = useIntl()
  return yup
    .object({
      ids: yup.array().of(yup.string().required()).required(),
      variant: yup.string().oneOf(variants).required(),
      _type: yup
        .string()
        .oneOf(
          canBeEmpty ? selectedTypes : ['partial', 'all'],
          formatMessage(sharedMessages.invalidInputError)
        )
        .required(),
    })
    .default({
      ids: [],
      variant: 'include',
      _type: 'empty',
    })
}

export const getSelectedValueFromIds = (ids: SelectedValue['ids']): SelectedValue => {
  if (ids.length === 0) {
    return {
      variant: 'include',
      ids,
      _type: 'empty',
    }
  }
  // We can't handle here if all ids are selected or not
  return {
    variant: 'include',
    ids,
    _type: 'partial',
  }
}

export type SelectedValue = {
  variant: Variant
  ids: string[]
  _type: SelectedType
}

type EmptyVariant = 'nothingFound' | 'loading'

export type EmployeeMultiSelectProps<T extends FieldValues> = {
  showSelectAll?: boolean
  config?: EmployeeMultiSelectConfig
} & UseControllerProps<T> &
  Omit<SelectProps<string> & FormItemWrapperProps, 'value' | 'defaultValue' | 'onChange'>

export const EmployeeMultiSelect = <T extends FieldValues>({
  showSelectAll,
  config = {
    type: 'default',
  },
  ...props
}: EmployeeMultiSelectProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, ...inputProps },
    fieldState,
  } = useCustomController(props)

  const options = useOptions({
    value: value ?? {
      variant: 'include',
      ids: [],
      _type: 'empty',
    },
    setValue: value => fieldOnChange(value),
    showSelectAll,
    config,
  })

  return (
    <FormItem
      {...formItemProps}
      errorMessage={
        (fieldState.error?.type as { message?: string })?.['message'] ?? formItemProps.errorMessage
      }
    >
      <Select {...inputProps} {...options} filterOption={false} mode='multiple' maxTagCount={0} />
    </FormItem>
  )
}

const rows = 18

type UseOptions = (props: {
  value: SelectedValue
  setValue: (value: SelectedValue) => void
  showSelectAll: boolean | undefined
  config: EmployeeMultiSelectConfig
}) => {
  value: string[]
  onChange: (value: string[]) => void
  options: {
    value: string
    label: string
  }[]
  notFoundContent: ReactNode
  loading: boolean
  disabled: boolean
  onSearch: (value: string) => void
  onFocus: () => void
  onPopupScroll: (event: SyntheticEvent) => void
  onDropdownVisibleChange: NonNullable<ComponentProps<typeof Select>['onDropdownVisibleChange']>
  dropdownRender: NonNullable<ComponentProps<typeof Select>['dropdownRender']>
  maxTagPlaceholder: NonNullable<ComponentProps<typeof Select>['maxTagPlaceholder']>
}

/**
 * TODO: What to improve
 * When combining defaultValues with disabled options,
 * there is a bug in items count calculation. Should be fixed!
 */
const useOptions: UseOptions = ({ value, setValue, config, showSelectAll = false }) => {
  const [emptyTextVariant, setEmptyTextVariant] = useState<EmptyVariant>('loading')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [totalEnabledCount, setTotalEnabledCount] = useState<number>(0)
  const [resultObject, setResultObject] = useState<{
    loadedDataType: 'preload' | 'default'
    searchText: string
    results: Employee[]
  }>({
    loadedDataType: 'preload',
    searchText: '',
    results: [],
  })
  const [search, setSearch] = useState<string>('')
  const { page, rowsPerPage, setPage } = usePaginator({
    rowsPerPage: rows,
  })
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const [_fetch, { loading: loadingSearch }] = useLazyQuery(employeeMultiselectQueries.search, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const surveyChannelConfig = config?.type === 'surveyShare' ? config?.surveyConfigId : undefined

  type SharedVariablesBetweenMutations = Omit<
    Parameters<NonNullable<typeof employeeMultiselectQueries.preloadEmployees.__apiType>>[0],
    'institutionId' | 'ids' | 'activeOnly'
  > &
    Omit<
      Parameters<NonNullable<typeof employeeMultiselectQueries.search.__apiType>>[0],
      'motherId' | 'ids' | 'search' | 'page' | 'rowsPerPage' | 'activeOnly'
    >

  const sharedVariablesBetweenMutations: SharedVariablesBetweenMutations = {
    surveyChannelConfig: surveyChannelConfig ?? NULL_UUID,
    isSurveyChannelConfigId: Boolean(surveyChannelConfig),
  }

  const fetch = async (options: {
    variables: Pick<FetchSearchVariables, 'page' | 'rowsPerPage' | 'search'>
  }) => {
    const result = await _fetch({
      variables: {
        ...sharedVariablesBetweenMutations,
        ...options.variables,
        motherId: getMotherId(),
        activeOnly: employeeMultiSelectConfig[config.type].activeOnly,
      },
    })

    return result
  }

  const [fetchPreload, { loading: loadingDefault }] = useLazyQuery(
    employeeMultiselectQueries.preloadEmployees,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted(data) {
        setResultObject(prevState => ({
          ...prevState,
          results: data.employees?.items ?? [],
        }))
        setEmptyTextVariant('nothingFound')
      },
    }
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies(value):
  // biome-ignore lint/correctness/useExhaustiveDependencies(value.ids):
  // biome-ignore lint/correctness/useExhaustiveDependencies(fetchPreload):
  // biome-ignore lint/correctness/useExhaustiveDependencies(getMotherId):
  // biome-ignore lint/correctness/useExhaustiveDependencies(sharedVariablesBetweenMutations):
  useEffect(() => {
    if (!value) {
      setResultObject(prevState => ({
        ...prevState,
        results: [],
      }))
      return
    }
    void fetchPreload({
      variables: {
        ...sharedVariablesBetweenMutations,
        institutionId: getMotherId(),
        ids: value.ids ?? [],
      },
    })
  }, [])

  const loading = loadingSearch || loadingDefault

  const fetchOnFocus = async () => {
    if (resultObject.loadedDataType === 'default') {
      return
    }
    const result = await fetch({
      variables: { search: '', page: 0, rowsPerPage },
    })
    setResultObject({
      loadedDataType: 'default',
      searchText: '',
      results: result.data?.employeesSearch?.items ?? [],
    })
    setTotalCount(result.data?.employeesSearch?.totalCount ?? 0)
    setTotalEnabledCount(prevState => {
      if (!result.data?.employeesSearch) {
        return prevState
      }
      return employeeMultiSelectConfig[config.type].selectableItemsCount(
        result.data?.employeesSearch
      )
    })
  }

  const fetchOnSearch = (value: string) => {
    setSearch(value)
    setPage(0)
    setEmptyTextVariant('loading')
    setResultObject(prevState => ({
      ...prevState,
      results: [],
      searchText: value,
    }))
    debounce(async () => {
      const result = await fetch({
        variables: { search: value, page: 0, rowsPerPage },
      })
      setResultObject(prevState => {
        if (prevState.searchText !== value) {
          return prevState
        }
        setEmptyTextVariant('nothingFound')
        return {
          ...prevState,
          results: result.data?.employeesSearch?.items ?? [],
        }
      })
    }, 300)()
  }

  const loadMoreOnScroll = async (event: SyntheticEvent) => {
    const target = event.target as HTMLElement
    const scrolledFromTop = target.scrollTop + target.offsetHeight
    const scrollHeight = target.scrollHeight
    const loadMoreTriggerHeight = 100
    const isTimeToLoadMore = scrolledFromTop > scrollHeight - loadMoreTriggerHeight

    const countMaxItemsToLoadedPage = page * rowsPerPage + rowsPerPage
    const hasMoreItemsToLoad = countMaxItemsToLoadedPage < totalCount

    if (isTimeToLoadMore && hasMoreItemsToLoad && !loading) {
      setPage(page + 1)
      const result = await fetch({
        variables: { search, page: page + 1, rowsPerPage },
      })
      setResultObject(prevState => {
        if (prevState.searchText !== search) {
          return prevState
        }
        return {
          ...prevState,
          results: [...prevState.results, ...(result.data?.employeesSearch?.items ?? [])],
        }
      })
    }
  }

  const isEmployeeDisabled = (employee: Employee) => {
    return employeeMultiSelectConfig[config.type].isDisabled(employee)
  }

  const disabledEmployeeIds = resultObject.results
    .filter(employee => isEmployeeDisabled(employee))
    .map(({ id }) => id)

  const isSelectedAll =
    value !== null &&
    ((value.variant === 'include' && value.ids.length === totalEnabledCount) ||
      (value.variant === 'exclude' && value.ids.length === 0))

  const sumSelected =
    value.variant === 'include' ? value.ids.length : totalEnabledCount - value.ids.length

  return {
    value:
      value.variant === 'include'
        ? value.ids
        : resultObject.results
            .filter(employee => !value.ids.includes(employee.id) && !isEmployeeDisabled(employee))
            .map(({ id }) => id),
    onChange: selectValue => {
      const getType: () => SelectedType = () => {
        if (value.variant === 'include') {
          if (selectValue.length === 0) {
            return 'empty'
          }
          if (selectValue.length === totalEnabledCount) {
            return 'all'
          }
          return 'partial'
        }
        if (selectValue.length === 0) {
          return 'empty'
        }
        if (selectValue.length === totalEnabledCount) {
          return 'all'
        }
        return 'partial'
      }
      setValue({
        variant: value.variant,
        ids:
          value.variant === 'include'
            ? selectValue.filter(id => !disabledEmployeeIds.includes(id))
            : resultObject.results
                .filter(({ id }) => !selectValue.includes(id) && !disabledEmployeeIds.includes(id))
                .map(({ id }) => id),
        _type: getType(),
      })
    },
    options: resultObject.results.map(employee => ({
      value: employee.id,
      label: `${employee.name} (${employee.email})`,
      disabled: config ? employeeMultiSelectConfig[config.type].isDisabled(employee) : false,
    })),
    notFoundContent: <FormattedMessage {...messagesEmpty[emptyTextVariant]} />,
    loading,
    disabled: loadingDefault,
    maxTagPlaceholder: () => {
      return (
        <FormattedMessage
          {...messages.totalSelected}
          values={{ selected: <FormattedNumber value={sumSelected} /> }}
        />
      )
    },
    onDropdownVisibleChange: open => {
      if (!open) {
        fetchOnSearch('')
      }
    },
    onSearch: fetchOnSearch,
    onFocus: fetchOnFocus,
    onPopupScroll: loadMoreOnScroll,
    dropdownRender: menu => (
      <div className='flex flex-col'>
        <div className='flex flex-col gap-4px'>
          {showSelectAll && (
            <>
              <Checkbox
                disabled={search !== '' || totalEnabledCount === 0}
                className='mx-12px my-5px'
                checked={value._type !== 'empty'}
                indeterminate={value !== null && value._type === 'partial'}
                onClick={() => {
                  if (isSelectedAll) {
                    setValue({
                      variant: 'include',
                      ids: [],
                      _type: 'empty',
                    })
                  } else {
                    setValue({
                      variant: 'exclude',
                      ids: [],
                      _type: 'all',
                    })
                  }
                }}
              >
                <FormattedMessage
                  {...messages.selectAllWithTotal}
                  values={{ total: totalEnabledCount }}
                />
              </Checkbox>
              <Divider />
            </>
          )}
          <Typography.Text className='mx-12px my-5px' type='secondary' size='sm'>
            {totalCount === 0 && emptyTextVariant === 'loading' ? (
              <FormattedMessage {...messages.loading} />
            ) : (
              <FormattedMessage
                {...messages.selectedXFromY}
                values={{
                  selected: (
                    <FormattedNumber value={isSelectedAll ? totalEnabledCount : sumSelected} />
                  ),
                  total: <FormattedNumber value={totalEnabledCount} />,
                }}
              />
            )}
          </Typography.Text>
        </div>
        {menu}
      </div>
    ),
    // @ts-expect-error Option is any
    optionRender: option => {
      const { disabledTooltipTitle } = employeeMultiSelectConfig[config.type]
      if (!option.data.disabled || !disabledTooltipTitle) {
        return option.label
      }
      return <Tooltip title={disabledTooltipTitle}>{option.label}</Tooltip>
    },
  }
}
