import { Form, MultiSelect } from '@faceup/ui'
import { type Language, getTranslation } from '@faceup/utils'
import type { UseFormReturnType } from '@mantine/form'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'

const messages = defineMessages({
  label: 'Administration.ReportSourceCategoryFormItem.label',
  helperText: 'Administration.ReportSourceCategoryFormItem.helperText',
})

const fragments = {
  ReportSourceCategoryFormItem_reportSource: graphql(`
    fragment ReportSourceCategoryFormItem_reportSource on ReportSource {
      id
      defaultLanguage
    }
  `),
  ReportSourceCategoryFormItem_institution: graphql(`
    fragment ReportSourceCategoryFormItem_institution on Company {
      id
      config {
        id
        reportCategoriesTranslations {
          id
          translations {
            language
            translation
          }
        }
      }
    }
  `),
}

export type ReportSourceCategoryFormItems = { categories: string[] }

export const useReportSourceCategoryValidation = () => {
  const { formatMessage } = useIntl()
  return {
    categories: (value: string[]) => {
      if (value.length === 0) {
        return formatMessage(sharedMessages.invalidInputError)
      }
      return null
    },
  }
}

type ReportSourceCategoryFormItemProps<D extends Record<string, unknown>> = {
  institution: FragmentType<typeof fragments.ReportSourceCategoryFormItem_institution>
  reportSource: FragmentType<typeof fragments.ReportSourceCategoryFormItem_reportSource>
  form: UseFormReturnType<ReportSourceCategoryFormItems & D>
  language: Language
}

export const ReportSourceCategoryFormItem = <D extends Record<string, unknown>>(
  props: ReportSourceCategoryFormItemProps<D>
) => {
  const { institution: _institution, reportSource: _reportSource, form, language } = props
  const institution = getFragmentData(
    fragments.ReportSourceCategoryFormItem_institution,
    _institution
  )
  const reportSource = getFragmentData(
    fragments.ReportSourceCategoryFormItem_reportSource,
    _reportSource
  )

  const options = institution.config.reportCategoriesTranslations.map(category => ({
    value: category.id,
    label: getTranslation(category.translations, language, reportSource.defaultLanguage),
  }))

  return (
    <Form.Item
      label={<FormattedMessage {...messages.label} />}
      hint={<FormattedMessage {...messages.helperText} />}
      errorMessage={form.getInputProps('categories').error}
    >
      <MultiSelect
        options={options}
        value={form.getInputProps('categories').value}
        onChange={form.getInputProps('categories').onChange}
      />
    </Form.Item>
  )
}
