import { Table as AntTable, type TableProps as AntTableProps } from 'antd'
import type { AnyObject } from 'antd/es/_util/type'
import type { ColumnType } from 'antd/es/table'
import { type ReactNode, isValidElement } from 'react'

type CustomColumnType<D> = ColumnType<D> & {
  disableRowEvent?: boolean | ((rowData: D) => boolean)
  fitWidthToContent?: boolean
}

export type TableProps<D> = {
  shadow?: boolean
  columns: CustomColumnType<D>[]
} & Omit<AntTableProps<D>, 'bordered' | 'columns'>

type StopPropagationWrapperProps = {
  children: ReactNode
}

const StopPropagationWrapper = ({ children }: StopPropagationWrapperProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return <div onClick={handleClick}>{children}</div>
}

const isObjectWithChildren = (value: unknown): value is { children: React.ReactNode } => {
  return value !== null && typeof value === 'object' && 'children' in value
}

export const Table = <D extends AnyObject>({ shadow, ...props }: TableProps<D>) => {
  const columns = props.columns?.map(column => {
    if (column.disableRowEvent && column.render) {
      const originalRender = column.render
      column.render = (text, record, index) => {
        const shouldDisableRowEvent =
          typeof column.disableRowEvent === 'boolean'
            ? column.disableRowEvent
            : column.disableRowEvent?.(record)

        const rendered = originalRender(text, record, index)
        if (isObjectWithChildren(rendered) && shouldDisableRowEvent) {
          return <StopPropagationWrapper>{rendered.children}</StopPropagationWrapper>
        } else if (isValidElement(rendered) && shouldDisableRowEvent) {
          return <StopPropagationWrapper>{rendered}</StopPropagationWrapper>
        }
        return rendered
      }
    }
    if (column.fitWidthToContent) {
      column.className = `${column.className || ''} ant-table-fit-width-to-content`
    }
    return column
  })

  return (
    <AntTable
      {...props}
      columns={columns}
      className={`${shadow ? 'ant-table-shadow' : ''}`}
      rowClassName={(record, index, indent) => {
        const getClassNameFromProps = (): string => {
          if (!props.rowClassName) {
            return ''
          }
          if (typeof props.rowClassName === 'string') {
            return props.rowClassName
          }
          return props.rowClassName(record, index, indent)
        }
        return `${getClassNameFromProps()} ${props.onRow?.(record).onClick ? 'ant-table-row-clickable' : ''}`
      }}
    />
  )
}
