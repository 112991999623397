import type { FormItemType, Language } from '@faceup/utils'
import { Fragment, type ReactNode } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'
import { useFormItemsAnswers } from '../hooks'

export type FormItemAnswer = {
  id: string
  label: string
  value: string
  type: FormItemType
  // Required for redaction
  formItemId: string
}

type FormItemsAnswersProps = {
  companyReport: FragmentType<typeof fragments.FormItemsAnswers_companyReport>
  renderFormItemAnswer: (props: FormItemAnswer) => ReactNode
  defaultLanguage?: Language
}

const fragments = {
  FormItemsAnswers_companyReport: graphql(`
    fragment FormItemsAnswers_companyReport on CompanyReport {
      id
      answers {
        ...useFormItemsAnswers_caseAnswer
      }
    }
  `),
}

export const FormItemsAnswers = (props: FormItemsAnswersProps) => {
  const { renderFormItemAnswer, companyReport: _companyReport, defaultLanguage } = props
  const companyReport = getFragmentData(fragments.FormItemsAnswers_companyReport, _companyReport)
  const getFormItemsAnswers = useFormItemsAnswers()

  return (
    <>
      {getFormItemsAnswers(companyReport.answers, defaultLanguage).map(answer => (
        <Fragment key={answer.id}>{renderFormItemAnswer(answer)}</Fragment>
      ))}
    </>
  )
}
