import type { Country } from '@faceup/utils'
import { err } from 'neverthrow'
import { validate2FA } from '../multiFactor'
import { verifyPrehashWithHash } from '../password'
import { CURRENT_ENCRYPTION_VERSION } from '../utils/constants'

// @todo: get rid of the nullability, when we have proper type
type User = {
  id: string | null
  type: string | null
  country: Country | null
  tokenVersion: number | null
  isE2EE: boolean | null
  keys: {
    password: string | null
    totp_secret: string | null
    totp_secret_nonce: string | null
    public_key: string | null
    private_key: string | null
    nonce: string | null
  }
}

type LoginInternalUserPayload = {
  passwordPrehash: string
  code2FA: string | null
}

export const getCurrentEncryptionVersion = () => CURRENT_ENCRYPTION_VERSION

export const loginInternalUserKeys = async (
  payload: LoginInternalUserPayload,
  user: User,
  envs: {
    APP_SPECIFIC_SALT: string
    APP_SPECIFIC_KEY: string
  }
) => {
  /**
   * Check if user password is right, or if the row doesnt exist, run hash anyway
   */

  const hash = user.keys.password
  const authentication = await verifyPrehashWithHash(
    payload.passwordPrehash,
    hash ?? null,
    envs.APP_SPECIFIC_SALT
  )

  if (authentication.isOk() && hash) {
    /**
     * User is autheticated, so last thing is to check his 2FA
     */
    if (user.keys.totp_secret && !payload.code2FA) {
      return err({ state: 'missing-2fa' as const })
    }

    return validate2FA(payload.code2FA, user.keys, envs.APP_SPECIFIC_KEY)
  }

  return err({ state: 'not-authenticated' as const })
}

export const loginFaceUpAdminUserKeys = async (
  payload: LoginInternalUserPayload,
  user: User,
  envs: {
    APP_SPECIFIC_SALT: string
    APP_SPECIFIC_KEY: string
    SYSTEM_PUBLIC_KEY: string
    SYSTEM_PRIVATE_KEY: string
    NODE_ENV: string
  }
) => {
  /**
   * Check if user password is right, or if the row doesnt exist, run hash anyway
   */

  const hash = user.keys?.password
  const authentication = await verifyPrehashWithHash(
    payload.passwordPrehash,
    hash,
    envs.APP_SPECIFIC_SALT
  )

  if (authentication.isOk() && hash) {
    /**
     * User is autheticated, so last thing is to check his 2FA
     */
    if (!payload.code2FA && envs.NODE_ENV === 'production') {
      return err({ state: 'missing-2fa' as const })
    }

    return validate2FA(payload.code2FA, user.keys, envs.APP_SPECIFIC_KEY)
  }

  return err({ state: 'not-authenticated' as const })
}

type LoginExternalUserPayload = {
  identity: string
  passwordPrehash: string
  reportPassword: string | null
  organizationalUnitId: string
}

export const loginExternalUserKeys = async (
  payload: LoginExternalUserPayload,
  hash: string | null,
  APP_SPECIFIC_SALT: string
) => {
  /**
   * Check if user password is right, or if the row doesnt exist, run hash anyway
   */

  const authentication = await verifyPrehashWithHash(
    payload.passwordPrehash,
    hash,
    APP_SPECIFIC_SALT
  )

  return authentication.isOk() && hash
}
