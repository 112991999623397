import { Form } from '@faceup/ui'
import {
  FormItemType,
  Institution,
  type Language,
  MEDIUM_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
  getTranslation,
  isLength,
} from '@faceup/utils'
import { Checkbox, TextInput } from '@mantine/core'
import type { UseFormReturnType } from '@mantine/form'
import { type ReactNode, useMemo, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import useMockedReportMessages from '../../../hooks/useMockedReportMessages'
import { useGlobalInfo } from '../../../locales'

const messages = defineMessages({
  labelAddHint: 'Administration.customization.formItems.label.addHint',
  label: 'Administration.customization.formItems.label.hint',
  hintCompany: 'Shared.CreateReportForm.company.moreInfoDetail',
  hintSchool: 'Shared.CreateReportForm.school.moreInfoDetail',
})

const fragments = {
  ReportSourceHintFormItem_formItem: graphql(`
    fragment ReportSourceHintFormItem_formItem on FormItem {
      id
      type
      hasHint
      hintTranslations {
        language
        translation
      }
    }
  `),
}

export type ReportSourceHintFormItems = { hint: string | null }

export const useReportSourceHintFormHookHelper = (
  _formItem: FragmentType<typeof fragments.ReportSourceHintFormItem_formItem> | null,
  language: Language,
  defaultLanguage: Language
) => {
  const formItem = getFragmentData(fragments.ReportSourceHintFormItem_formItem, _formItem)
  const { formatMessage } = useIntl()
  const { formatMessageWithConfigLanguage } = useMockedReportMessages(language ?? defaultLanguage)
  const { institution } = useGlobalInfo()

  return useMemo(() => {
    const getDefaultValueIfEmpty = () => {
      if (!formItem || !institution) {
        return null
      }
      const hintMessages: Record<FormItemType, Record<Institution, string> | null> = {
        [FormItemType.MoreInformation]: {
          [Institution.Company]: formatMessageWithConfigLanguage(messages.hintCompany),
          [Institution.School]: formatMessageWithConfigLanguage(messages.hintSchool),
        },
        [FormItemType.OrganizationalUnit]: null,
        [FormItemType.Category]: null,
        [FormItemType.SimpleText]: null,
        [FormItemType.Select]: null,
        [FormItemType.MultiSelect]: null,
        [FormItemType.SenderName]: null,
        [FormItemType.Classroom]: null,
        [FormItemType.MultilineText]: null,
        [FormItemType.Email]: null,
        [FormItemType.Date]: null,
        [FormItemType.PhoneNumber]: null,
      }
      return hintMessages[formItem.type]?.[institution] ?? null
    }
    const initialHintValue = formItem?.hasHint
      ? getTranslation(formItem?.hintTranslations ?? [], language, defaultLanguage) ||
        (getDefaultValueIfEmpty() ?? '')
      : ''
    return {
      validate: {
        hint: (value: string) => {
          const trimmedHint = value.trim()
          if (!isLength(trimmedHint, { min: STRING_MIN_LENGTH }) && trimmedHint.length > 0) {
            return formatMessage(sharedMessages.invalidInputError)
          }
          return null
        },
      },
      initialValues: {
        hint: initialHintValue,
      },
      transformValues: {
        hint: (hint: string | null) => {
          if (!hint) {
            return null
          }
          const trimmedHint = hint.trim()
          const isHintSameAsOriginal = getDefaultValueIfEmpty() === trimmedHint
          return isHintSameAsOriginal ? null : trimmedHint
        },
      },
    }
  }, [
    formItem,
    language,
    defaultLanguage,
    formatMessageWithConfigLanguage,
    formatMessage,
    institution,
  ])
}

type ReportSourceHintFormItemPropsFormHook<D extends Record<string, unknown>> = {
  form: UseFormReturnType<ReportSourceHintFormItems & D>
  value?: never
  onChange?: never
  errorMessage?: never
}

type ReportSourceHintFormItemPropsCallback = {
  form?: never
  value: string
  onChange: (value: string) => void
  errorMessage: ReactNode | null
}

type ReportSourceHintFormItemProps<D extends Record<string, unknown>> = {
  canNotBeDisabled?: boolean
} & (ReportSourceHintFormItemPropsFormHook<D> | ReportSourceHintFormItemPropsCallback)

export const ReportSourceHintFormItem = <D extends Record<string, unknown>>(
  props: ReportSourceHintFormItemProps<D>
) => {
  const { form, value, onChange, canNotBeDisabled, errorMessage } = props
  const [openedHint, setOpenedHint] = useState(
    Boolean(form?.values.hint) || Boolean(value) || canNotBeDisabled
  )
  return (
    <>
      {!canNotBeDisabled && (
        <Form.Item>
          <Checkbox
            label={<FormattedMessage {...messages.labelAddHint} />}
            onChange={event => {
              if (event.target.checked) {
                setOpenedHint(true)
              } else {
                setOpenedHint(false)
                onChange?.('')
                if (form) {
                  form.setValues(prevState => ({ ...prevState, hint: '' }))
                }
              }
            }}
            checked={openedHint}
          />
        </Form.Item>
      )}
      {openedHint && (
        <Form.Item
          label={<FormattedMessage {...messages.label} />}
          withAsterisk
          errorMessage={errorMessage}
        >
          <TextInput
            {...(form
              ? form.getInputProps('hint')
              : {
                  value,
                  onChange: event => onChange(event.target.value),
                })}
            maxLength={MEDIUM_STRING_MAX_LENGTH}
          />
        </Form.Item>
      )}
    </>
  )
}
