import { graphql } from '../../../__generated__'
// Moved to separate file otherwise there were some loading problems, maybe circular dependency?
export const companyInvoicingInstitutionAndSchoolFragment = graphql(`
  fragment CompanyInvoicingInstitutionAndSchoolFragment on PriceListInstitutionType {
    company {
      members
      versions {
        version
        prices {
          currency
          billingPrice
          originalPrice
          discountTill
        }
      }
    }
    school {
      members
      versions {
        version
        prices {
          currency
          billingPrice
          originalPrice
          discountTill
        }
      }
    }
  }
`)
