import { FormLocalizationProvider } from '@faceup/form'
import { InstitutionProvider as FUInstitutionProvider } from '@faceup/institution'
import { LocalizationProvider } from '@faceup/localization'
import { UserProvider, useAccessRights } from '@faceup/member'
import { Link } from '@faceup/router'
import {
  Button,
  CustomizationContext,
  CustomizationProvider,
  UiProvider as FUUiProvider,
  MantineProvider,
  type PagesTranslationItems,
  type PagesTranslationVariants,
  type UiProviderProps,
} from '@faceup/ui'
import { App, type Locale, UiBaseProvider, useMessage } from '@faceup/ui-base'
import {
  ContactIssue,
  Country,
  DEFAULT_LANGUAGE,
  convertCountryToCountryName,
  planMapName,
} from '@faceup/utils'
import { Box, Tooltip } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { type PropsWithChildren, type ReactNode, useContext, useEffect, useState } from 'react'
import ContactSalesModal from './Components/ContactSalesModal'
import GeoDataProvider from './Contexts/GeoDataProvider'
import { LanguageContext } from './Contexts/LanguageContext'
import { LANGUAGE_STORAGE_KEY } from './Contexts/LanguageProvider'
import { sharedMessages } from './Shared/translations'
import { type DefineMessagesType, FormattedMessage, defineMessages, useIntl } from './TypedIntl'
import { useGlobalInfo } from './locales'
import useAnalytics from './utils/analytics'

type AppProvidersProps = {
  children: ReactNode
  direction: 'rtl' | 'ltr'
  antLocale: Locale | undefined
}

const messages = defineMessages({
  upgradePlanTo: 'Administration.upsell.upgradePlanTo',
  contactSales: 'Administration.companyInvoicing.button.contactSales',
  noSettingsAccess: 'Administration.upsell.noSettingsAccess',
  tableShown: 'Administration.ui.table.shown',
  tableSearch: 'Administration.action.search',
  search: 'Shared.search',
  noResults: 'Shared.noResults',
  confirmRedirectMessageTitle: 'Shared.confirmRedirectMessageTitle',
  confirmRedirectMessageContent: 'Shared.confirmRedirectMessageContent',
  redirectOkButton: 'Shared.redirectOkButton',
  redirectCancelButton: 'Shared.redirectCancelButton',
  ok: 'Shared.ok',
  cancel: 'Shared.cancel',
  unsavedChangesCount: 'Administration.form.unsavedChangesCount',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
  upload: 'Administration.reportCustomization.uploadLogo',
  // yup
  validationStringMin: 'Shared.validation.string.min',
  validationStringMax: 'Shared.validation.string.max',
  // multiselect
  allSelected: 'Shared.multiselect.allSelected',
  // editable sortable list
  addOption: 'Administration.customization.formItems.select.addOption',
  // paginator
  totalRows: 'Administration.pagination.totalRows',
})

export const messagesPages: Record<
  PagesTranslationVariants,
  DefineMessagesType<PagesTranslationItems>
> = {
  freeSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.freeSchool.default.content',
  }),
  unregisteredSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.unregisteredSchool.default.content',
  }),
  testingSchool: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.testingSchool.default.content',
  }),
  school: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.school.default.content',
  }),
  company: defineMessages({
    title: 'Shared.pages.default.title',
    content: 'Shared.pages.company.default.content',
  }),
  surveyBeforeSend: defineMessages({
    title: 'Shared.surveys.detail.beforeSendPageTitle',
    content: 'Shared.surveys.pages.beforeSend.content',
  }),
  surveyAfterSend: defineMessages({
    title: 'Shared.surveys.detail.afterSendPageTitle',
    content: 'Shared.surveys.pages.afterSend.content',
  }),
  // Must be here even if we use only navigation title
  formItems: defineMessages({
    title: 'Administration.surveys.pages.formItems.title',
    content: 'Administration.surveys.pages.formItems.title',
  }),
}

const AppProviders = ({ children, direction, antLocale }: AppProvidersProps) => {
  const { language, changeLanguage } = useContext(LanguageContext)
  const { reset } = useAnalytics()
  const { formatMessage } = useIntl()

  return (
    <CustomizationProvider>
      <LocalizationProvider language={language ?? DEFAULT_LANGUAGE}>
        <GeoDataProvider>
          <CustomizationContext.Consumer>
            {({ colors: { primaryColor, darkColor } }) => (
              <App>
                <UiBaseProvider
                  colorPrimary={primaryColor}
                  colorDark={darkColor}
                  locale={antLocale}
                  direction={direction}
                >
                  <UiProvider
                    theme={{
                      colors: {
                        primary: primaryColor,
                        dark: darkColor,
                      },
                    }}
                  >
                    <FormLocalizationProvider
                      submitButton={{
                        send: formatMessage(sharedMessages.send),
                        add: formatMessage(sharedMessages.add),
                        save: formatMessage(sharedMessages.save),
                      }}
                      successMessage={{
                        send: formatMessage(sharedMessages.savedMessage),
                        add: formatMessage(sharedMessages.savedMessage),
                        save: formatMessage(sharedMessages.savedMessage),
                      }}
                      modalForm={{
                        cancelButton: formatMessage(sharedMessages.cancel),
                      }}
                      unsavedChanges={count =>
                        formatMessage(messages.unsavedChangesCount, { count })
                      }
                      leaveUnsavedChanges={formatMessage(messages.leaveQuestion)}
                      yup={{
                        mixed: {
                          default: formatMessage(sharedMessages.invalidInputError),
                          required: formatMessage(sharedMessages.invalidInputError),
                        },
                        string: {
                          min: ({ min }) =>
                            formatMessage(messages.validationStringMin, {
                              min,
                            }),
                          max: ({ max }) =>
                            formatMessage(messages.validationStringMax, {
                              max,
                            }),
                        },
                      }}
                    >
                      <MantineProvider direction={direction}>
                        <UserProvider
                          application='akutan'
                          onLogout={() => {
                            reset()
                            localStorage.removeItem(LANGUAGE_STORAGE_KEY)
                          }}
                          onChangeLanguage={changeLanguage}
                        >
                          <InstitutionProvider>{children}</InstitutionProvider>
                        </UserProvider>
                      </MantineProvider>
                    </FormLocalizationProvider>
                  </UiProvider>
                </UiBaseProvider>
              </App>
            )}
          </CustomizationContext.Consumer>
        </GeoDataProvider>
      </LocalizationProvider>
    </CustomizationProvider>
  )
}

const InstitutionProvider = ({ children }: { children: ReactNode }) => {
  const { isAllowedSettingsAccess } = useAccessRights()
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <FUInstitutionProvider
      upsell={{
        isAllowed: true,
        buttons: ({ upgradeTo, name }) => (
          <>
            {upgradeTo && (
              <Tooltip
                disabled={isAllowedSettingsAccess}
                label={<FormattedMessage {...messages.noSettingsAccess} />}
              >
                <Box>
                  <Button
                    disabled={!isAllowedSettingsAccess}
                    component={Link}
                    to={routes => routes.settingsInvoicing()}
                    isFullWidth
                  >
                    <FormattedMessage
                      {...messages.upgradePlanTo}
                      values={{
                        plan: planMapName[upgradeTo],
                      }}
                    />
                  </Button>
                </Box>
              </Tooltip>
            )}
            <Button variant={upgradeTo ? 'secondary' : 'primary'} onClick={open}>
              <FormattedMessage {...messages.contactSales} />
            </Button>
            <ContactSalesModal
              contactIssue={ContactIssue.Upsell}
              opened={opened}
              onClose={close}
              note={name}
            />
          </>
        ),
      }}
    >
      {children}
    </FUInstitutionProvider>
  )
}

const UiProvider = ({
  children,
  theme,
}: PropsWithChildren<Required<Pick<UiProviderProps, 'theme'>>>) => {
  const [isDefaultForm, setIsDefaultForm] = useState(false)

  const { locale, formatMessage } = useIntl()
  const { plan, institution: type, institutionName: name } = useGlobalInfo()
  const message = useMessage()

  useEffect(() => {
    if (['stage', 'preview'].includes(import.meta.env.VITE_ENVIRONMENT ?? '')) {
      message.warning({
        key: 'environment-info-box',
        content: `${import.meta.env.VITE_ENVIRONMENT} environment`,
        duration: 0,
        className: 'environment-info-box',
      })
    }
  }, [message])

  return (
    <FUUiProvider
      theme={theme}
      linkExternalConfirm={{
        confirmRedirectMessageTitle: formatMessage(messages.confirmRedirectMessageTitle),
        confirmRedirectMessageContent: formatMessage(messages.confirmRedirectMessageContent),
        redirectOkButton: formatMessage(messages.redirectOkButton),
        redirectCancelButton: formatMessage(messages.redirectCancelButton),
      }}
      inputCopyable={{
        onCopySuccess: () => message.success(formatMessage(sharedMessages.copied)),
        copy: formatMessage(sharedMessages.copy),
      }}
      inputPhone={{
        searchPlaceholder: formatMessage(messages.search),
        localization: Object.values(Country).reduce(
          (acc, country) => ({
            ...acc,
            [country.toLowerCase()]: convertCountryToCountryName(country, locale),
          }),
          {}
        ),
        searchNotFound: formatMessage(messages.noResults),
      }}
      pages={{
        mother: {
          plan,
          type,
          // in akutan we don't deal with the testing school
          isTesting: false,
        },
        isDefaultForm,
        setIsDefaultForm,
        translations: {
          company: {
            title: formatMessage(messagesPages.company.title),
            content: formatMessage(messagesPages.company.content)
              .split('{name}')
              .join(name ?? ''),
          },
          school: {
            title: formatMessage(messagesPages.school.title),
            content: formatMessage(messagesPages.school.content)
              .split('{name}')
              .join(name ?? ''),
          },
          testingSchool: {
            title: formatMessage(messagesPages.testingSchool.title),
            content: formatMessage(messagesPages.testingSchool.content)
              .split('{name}')
              .join(name ?? ''),
          },
          freeSchool: {
            title: formatMessage(messagesPages.freeSchool.title),
            content: formatMessage(messagesPages.freeSchool.content)
              .split('{name}')
              .join(name ?? ''),
          },
          unregisteredSchool: {
            title: formatMessage(messagesPages.unregisteredSchool.title),
            content: formatMessage(messagesPages.unregisteredSchool.content)
              .split('{name}')
              .join(name ?? ''),
          },
          surveyBeforeSend: {
            title: formatMessage(messagesPages.surveyBeforeSend.title),
            content: formatMessage(messagesPages.surveyBeforeSend.content),
          },
          surveyAfterSend: {
            title: formatMessage(messagesPages.surveyAfterSend.title),
            content: formatMessage(messagesPages.surveyAfterSend.content),
          },
          formItems: {
            title: formatMessage(messagesPages.formItems.title),
            content: formatMessage(messagesPages.formItems.content),
          },
        },
      }}
      buttonConfirm={{
        confirm: formatMessage(messages.ok),
        cancel: formatMessage(messages.cancel),
      }}
      table={{
        shown: formatMessage(messages.tableShown),
        search: formatMessage(messages.tableSearch),
        reset: formatMessage(sharedMessages.reset),
        ok: formatMessage(messages.ok),
      }}
      form={{
        submitButton: {
          send: formatMessage(sharedMessages.send),
          add: formatMessage(sharedMessages.add),
          save: formatMessage(sharedMessages.save),
        },
      }}
      modalConfirm={{
        cancelText: formatMessage(sharedMessages.cancel),
        confirmText: formatMessage(sharedMessages.ok),
      }}
      multiSelect={{
        allSelected: formatMessage(messages.allSelected),
      }}
      dropzoneImage={{
        upload: formatMessage(messages.upload),
      }}
      editableSortableList={{
        addItem: formatMessage(messages.addOption),
      }}
      paginator={{
        totalRows: totalRows => formatMessage(messages.totalRows, { totalRows }),
      }}
    >
      {children}
    </FUUiProvider>
  )
}

export default AppProviders
