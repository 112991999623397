import {
  BillingFrequency,
  CHARGEBEE_UNLIMITED_MEMBERS,
  type Currency,
  Institution,
  omitNullInArray,
} from '@faceup/utils'
import { defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useGlobalInfo } from '../../../locales'
import { companyInvoicingInstitutionAndSchoolFragment } from '../CompanyInvoicing/CompanyInvoicingInstitutionAndSchoolFragment'

const messages = defineMessages({
  lowerThan: 'Administration.companyInvoicing.employeeCount.lowerThan',
  higherThan: 'Administration.companyInvoicing.employeeCount.higherThan',
})

const fragments = {
  useEmployeesCountFragments_priceList: graphql(`
    fragment useEmployeesCountFragments_priceList on PriceList {
      monthly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
      yearly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
      quarterly {
        ...CompanyInvoicingInstitutionAndSchoolFragment
      }
    }
  `),
}

type ListVariant = NonNullable<
  NonNullable<
    NonNullable<
      FragmentType<typeof companyInvoicingInstitutionAndSchoolFragment>[' $fragmentRefs']
    >['CompanyInvoicingInstitutionAndSchoolFragmentFragment']['company']
  >[number]
>

const getBillingFrequency = (billingFrequency: BillingFrequency) => {
  switch (billingFrequency) {
    case BillingFrequency.Monthly:
      return 'monthly'
    case BillingFrequency.Yearly:
      return 'yearly'
    case BillingFrequency.Quarterly:
      return 'quarterly'
  }
}

const getInstitution = (institution: Institution) => {
  switch (institution) {
    case Institution.School:
      return 'school'
    case Institution.Company:
      return 'company'
  }
}

export const useEmployeesCount = ({
  priceList: _priceList,
}: { priceList: FragmentType<typeof fragments.useEmployeesCountFragments_priceList> }) => {
  const priceList = getFragmentData(fragments.useEmployeesCountFragments_priceList, _priceList)
  const priceListWithBillingFrequency = {
    monthly: getFragmentData(companyInvoicingInstitutionAndSchoolFragment, priceList.monthly),
    quarterly: getFragmentData(companyInvoicingInstitutionAndSchoolFragment, priceList.quarterly),
    yearly: getFragmentData(companyInvoicingInstitutionAndSchoolFragment, priceList.yearly),
  }

  const { institution } = useGlobalInfo()
  const { formatMessage } = useIntl()
  const getPriceVariants = (
    billingFrequency: BillingFrequency,
    currency: Currency
  ): ListVariant[] => {
    if (!institution) {
      return []
    }
    return (
      omitNullInArray(
        priceListWithBillingFrequency[getBillingFrequency(billingFrequency)]?.[
          getInstitution(institution)
        ] ?? []
      ).filter(
        // We want to include only variants with specified currency
        priceVariant =>
          priceVariant?.versions?.some(version =>
            version?.prices?.find(price => price?.currency === currency)
          )
      ) ?? []
    )
  }

  const getPriceVariant = (
    billingFrequency: BillingFrequency,
    currency: Currency,
    employeesCount: number
  ): ListVariant | null =>
    getPriceVariants(billingFrequency, currency).find(
      priceVariant => (priceVariant?.members ?? 0) >= employeesCount
    ) ?? null

  const getMessagesForVariants = (billingFrequency: BillingFrequency, currency: Currency) => {
    const variants = getPriceVariants(billingFrequency, currency)
    return variants.reduce(
      (acc, variant, i) => {
        const employees = {
          minEmployeesCount: variants?.[i - 1]?.members ?? 0,
          maxEmployeesCount: variants?.[i]?.members ?? 0,
        }
        const translation =
          variant.members === CHARGEBEE_UNLIMITED_MEMBERS ? messages.higherThan : messages.lowerThan
        return { ...acc, [variant.members ?? 0]: formatMessage(translation, employees) }
      },
      {} as Record<number, string>
    )
  }

  return {
    getPriceVariants,
    getPriceVariant,
    getMessagesForVariants,
  }
}
