import { useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulLink01 } from '@faceup/icons/ulLink01'
import { ulMail02 } from '@faceup/icons/ulMail02'
import { ulQrCode02 } from '@faceup/icons/ulQrCode02'
import { useMotherId } from '@faceup/institution'
import { FormItem, InputCopyable, Modal, ModalConfirm } from '@faceup/ui'
import { Collapse } from '@faceup/ui'
import {
  Alert,
  Avatar,
  Button,
  Flex,
  Input,
  QRCode,
  type QRCodeProps,
  Typography,
  notification,
  useMessage,
} from '@faceup/ui-base'
import { ChannelStatus } from '@faceup/utils'
import { type ReactNode, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import useConfigForProject from '../../../hooks/useConfigForProject'
import { downloadImage } from '../../../utils/downloader'
import { ShareByEmailModal } from './ShareByEmailModal'
import type { SurveyModalModes } from './SurveyNavigation'

const messages = defineMessages({
  publish: 'Administration.surveys.modal.publishTitle',
  publishButton: 'Administration.surveys.detail.publish',
  share: 'Administration.surveys.modal.shareTitle',
  shareInputLabel: 'Administration.surveys.modal.shareInputLabel',
  shareLinkTitle: 'Administration.surveys.modal.shareLinkTitle',
  shareLinkDescription: 'Administration.surveys.modal.shareLinkDescription',
  downloadQRCode: 'Administration.surveys.modal.downloadQRCode',
  shareViaQR: 'Administration.surveys.modal.shareViaQRDescription',
  shareViaQRDescription: 'Administration.surveys.modal.shareViaQRTitle',
  shareViaEmail: 'Administration.surveys.modal.shareViaEmailTitle',
  shareViaEmailDescription: 'Administration.surveys.modal.shareViaEmailDescription',
  publishAlert: 'Administration.surveys.modal.publishAlert',
})

const { Text } = Typography
const mutations = {
  ToggleSurveyActivation: graphql(`
    mutation ToggleSurveyActivation($input: ToggleSurveyActivationInput!) {
      toggleSurveyActivation(input: $input) {
        survey {
          id
          isEnabled
        }
      }
    }
  `),
}

const fragments = {
  PublishSurveyModalFragment: graphql(`
    fragment PublishSurveyModalFragment on ReportSource {
      id
      shortLink
      isEnabled
      config {
        ... on SurveyChannelConfiguration {
          id
        }
      }
      institution {
        id
        isAnyHrIntegrationEnabled
      }
      ...ShareSurveyByEmailFragment_reportSource
    }
  `),
}

type Config = Record<
  SurveyModalModes,
  {
    title: ReactNode
    alertText: ReactNode | null
    hasNoCancelButton: boolean
    onConfirm: (() => void) | undefined
  }
>

type PublishSurveyModalProps = {
  mode: SurveyModalModes
  onClose: () => void
  survey: FragmentType<typeof fragments.PublishSurveyModalFragment>
}

export const PublishSurveyModal = ({ mode, onClose, survey: _survey }: PublishSurveyModalProps) => {
  const survey = getFragmentData(fragments.PublishSurveyModalFragment, _survey)
  const { formatMessage } = useIntl()
  const message = useMessage()
  const { getMotherId } = useMotherId()
  const { generateSurveyUrl } = useConfigForProject()
  const [shareByEmailModalOpen, setShareByEmailModalOpen] = useState(false)

  const [toggleSurveyActivation] = useMutation(mutations.ToggleSurveyActivation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => message.success(formatMessage(sharedMessages.savedMessage)),
    refetchQueries: ['SurveysTableQuery'],
  })

  const config: Config = {
    publish: {
      title: <FormattedMessage {...messages.publish} />,
      alertText: <FormattedMessage {...messages.publishAlert} />,
      hasNoCancelButton: false,
      onConfirm: async () => {
        await toggleSurveyActivation({
          variables: {
            input: {
              motherId: getMotherId(),
              reportSourceId: survey?.id ?? '',
              status: survey.isEnabled ? ChannelStatus.Disabled : ChannelStatus.Enabled,
            },
          },
        })
        onClose()
      },
    },
    share: {
      title: <FormattedMessage {...messages.share} />,
      alertText: null,
      hasNoCancelButton: true,
      onConfirm: undefined,
    },
  }

  if (!mode) {
    return null
  }

  const currentConfig = config[mode]

  const surveyUrl = generateSurveyUrl(survey?.shortLink ?? '')

  const isAnyHrIntegrationEnabled = survey?.institution?.isAnyHrIntegrationEnabled ?? false

  if (shareByEmailModalOpen) {
    return (
      <ShareByEmailModal
        onClose={() => {
          setShareByEmailModalOpen(false)
          onClose()
        }}
        open={shareByEmailModalOpen}
        survey={survey}
      />
    )
  }

  return (
    <>
      {mode === 'publish' ? (
        <ModalConfirm
          title={currentConfig.title}
          opened
          width={644}
          onClose={onClose}
          hasNoCancelButton={currentConfig.hasNoCancelButton}
          onConfirm={currentConfig.onConfirm}
          confirmText={<FormattedMessage {...messages.publishButton} />}
          dataCy='publish-survey-modal'
        >
          <ModalChildren
            surveyUrl={surveyUrl}
            currentConfig={currentConfig}
            onShareByEmailClick={() => setShareByEmailModalOpen(true)}
            isAnyHrIntegrationEnabled={isAnyHrIntegrationEnabled}
          />
        </ModalConfirm>
      ) : (
        <Modal
          title={currentConfig.title}
          opened
          width={644}
          onClose={() => {
            onClose()
          }}
        >
          <ModalChildren
            surveyUrl={surveyUrl}
            currentConfig={currentConfig}
            onShareByEmailClick={() => setShareByEmailModalOpen(true)}
            isAnyHrIntegrationEnabled={isAnyHrIntegrationEnabled}
          />
        </Modal>
      )}
    </>
  )
}

const ModalChildren = ({
  surveyUrl,
  currentConfig,
  onShareByEmailClick,
  isAnyHrIntegrationEnabled,
}: {
  surveyUrl: string
  currentConfig: Config[keyof Config]
  onShareByEmailClick: () => void
  isAnyHrIntegrationEnabled: boolean
}) => {
  const [qRCodeType, setQRCodeType] = useState<QRCodeProps['type']>('canvas')

  const downloadQRCode = (format: 'png' | 'jpeg' | 'svg') => {
    if (format === 'svg') {
      setQRCodeType('svg')
      const svgElement = document
        .getElementById('share-survey-qr-code')
        ?.querySelector<SVGSVGElement>('svg')
      if (svgElement) {
        const svgData = new XMLSerializer().serializeToString(svgElement)
        const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgData)}`
        downloadImage(svgUrl, 'qr-code.svg')
      }
    } else {
      setQRCodeType('canvas')
      const canvas = document
        .getElementById('share-survey-qr-code')
        ?.querySelector<HTMLCanvasElement>('canvas')
      const mimeType = format === 'png' ? 'image/png' : 'image/jpeg'
      const url = canvas?.toDataURL(mimeType)
      if (url) {
        downloadImage(url, `qr-code.${format}`)
      }
    }
  }

  const collapseItems = [
    {
      key: '1',
      label: (
        <Flex gap='middle' align='center'>
          <Avatar size={32} color='primary'>
            <UntitledIcon size={14} icon={ulLink01} />
          </Avatar>
          <FormattedMessage {...messages.shareLinkTitle} />
        </Flex>
      ),
      'data-cy': 'share-survey-collapse-item',
      children: (
        <Flex gap='middle' vertical>
          <Text type='secondary'>
            <FormattedMessage {...messages.shareLinkDescription} />
          </Text>
          <FormItem label={<FormattedMessage {...messages.shareInputLabel} />}>
            <InputCopyable
              value={surveyUrl}
              buttonPosition='afterInput'
              copyButtonDataCy='copy-survey-link'
            />
          </FormItem>
        </Flex>
      ),
    },
    {
      key: '2',
      label: (
        <Flex gap='middle' align='center'>
          <Avatar size={32} color='primary'>
            <UntitledIcon size={14} icon={ulQrCode02} />
          </Avatar>
          <FormattedMessage {...messages.shareViaQR} />
        </Flex>
      ),
      children: (
        <Flex gap='middle' vertical>
          <Text type='secondary'>
            <FormattedMessage {...messages.shareViaQRDescription} />
          </Text>
          <FormItem label={<FormattedMessage {...messages.downloadQRCode} />}>
            <div id='share-survey-qr-code'>
              <QRCode
                type={qRCodeType}
                value={surveyUrl}
                style={{ display: 'none' }}
                color='black'
                bgColor='white'
              />
            </div>
            <Flex gap='4px'>
              <Input className='h-0 w-0 absolute invisible' />
              <Button variant='outlined' onClick={() => downloadQRCode('jpeg')}>
                JPG
              </Button>
              <Button variant='outlined' onClick={() => downloadQRCode('png')}>
                PNG
              </Button>
              <Button variant='outlined' onClick={() => downloadQRCode('svg')}>
                SVG
              </Button>
            </Flex>
          </FormItem>
        </Flex>
      ),
    },
    ...(isAnyHrIntegrationEnabled
      ? [
          {
            key: '3',
            label: (
              <Flex gap='middle' align='center'>
                <Avatar size={32} color='primary'>
                  <UntitledIcon size={14} icon={ulMail02} />
                </Avatar>
                <FormattedMessage {...messages.shareViaEmail} />
              </Flex>
            ),
            children: (
              <Flex gap='middle' vertical align='start'>
                <Text type='secondary'>
                  <FormattedMessage {...messages.shareViaEmailDescription} />
                </Text>
                <Button color='primary' variant='outlined' onClick={onShareByEmailClick}>
                  <FormattedMessage {...messages.shareViaEmail} />
                </Button>
              </Flex>
            ),
          },
        ]
      : []),
  ]

  return (
    <>
      <Collapse items={collapseItems} ghost expandIconPosition='end' />
      {currentConfig.alertText && <Alert type='info' showIcon message={currentConfig.alertText} />}
    </>
  )
}
