import { AnswerCard } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { BarChart } from '../../../Components/Statistics/Charts/BarChart'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useColors } from '../../../hooks/charts/useColors'

const messages = defineMessages({
  xAnswers: 'Administration.surveys.submissions.statistics.xAnswers',
})

type SurveyChartProps = {
  label: ReactNode
  answersStatistics: FragmentType<typeof fragments.AnswersChart_submissionStatistics>
}

export const fragments = {
  AnswersChart_submissionStatistics: graphql(`
    fragment AnswersChart_submissionStatistics on AnswersStatistics {
      answersCount
      statistics {
        label
        count
      }
    }
  `),
}

export const AnswersChart = ({
  label,
  answersStatistics: _answersStatistics,
}: SurveyChartProps) => {
  const answersStatistics = getFragmentData(
    fragments.AnswersChart_submissionStatistics,
    _answersStatistics
  )
  const { answersCount, statistics } = answersStatistics
  const colors = useColors(statistics.reduce((acc, { label }) => ({ ...acc, [label]: 0 }), {}))
  return (
    <AnswerCard>
      <div className='p-24px'>
        <AnswerCard.Title title={label} />
        <Typography.Text size='sm' type='secondary' strong>
          <FormattedMessage {...messages.xAnswers} values={{ count: answersCount }} />
        </Typography.Text>
        <BarChart
          data={statistics.map(({ label, count }) => [label, count])}
          colors={colors}
          overlay={!statistics.length}
        />
      </div>
    </AnswerCard>
  )
}
