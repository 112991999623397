import { useQuery } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { useParams } from '@faceup/router'
import { ContentLayout } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { SubmissionsDetails, SubmissionsStatistics } from './components'
import { SurveyContentHeader } from './components/SurveyContentHeader'

const messages = defineMessages({
  statistics: 'Administration.surveys.submissions.statistics',
  submissionDetails: 'Administration.surveys.submissions.submissionDetails',
})

const query = graphql(`
  query SubmissionsViewQuery(
    $channelId: ReportSourceGlobalId!
    $motherId: UUID!
  ) {
    survey(reportSourceId: $channelId, motherId: $motherId) {
      id
      ...SurveyContentHeader_reportSource
      ...SubmissionStatistics_reportSource
      ...SubmissionDetails_reportSource
    }
  }
`)

const SurveyDetailView = () => {
  const { id: surveyId } = useParams<{ id: string }>()

  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const { data, refetch } = useQuery(query, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      channelId: surveyId ?? '',
      motherId: getMotherId(),
    },
    skip: !surveyId,
  })

  const survey = data?.survey

  if (!surveyId || !survey) {
    return null
  }

  return (
    <ContentLayout
      header={
        <SurveyContentHeader
          survey={survey}
          surveyNavigationType='questions'
          arrowGoBackTo={routes => routes.surveyDetail({ id: surveyId })}
        />
      }
      menu={[
        {
          key: 'statistics',
          title: <FormattedMessage {...messages.statistics} />,
          route: routes => routes.surveySubmissionsStatistics({ id: surveyId }),
          element: <SubmissionsStatistics data={survey} />,
          attributes: { 'data-onboarding': 'submissions-statistics' },
        },
        {
          key: 'submissionDetails',
          title: <FormattedMessage {...messages.submissionDetails} />,
          route: routes => routes.surveySubmissionsDetails({ id: surveyId }),
          element: <SubmissionsDetails data={survey} refetch={refetch} />,
          attributes: { 'data-onboarding': 'submission-details' },
        },
      ]}
    />
  )
}

export default SurveyDetailView
