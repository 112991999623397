import { DEFAULT_CURRENCY } from '../constants'
import {
  BillingFrequency,
  ChargebeeSubscriptionStatus,
  Feature,
  FeatureType,
  Institution,
  PaymentMethod,
  Plan,
} from '../enums'
import { type AvailableCurrency, type Country, Currency } from '../localization'

export { defaultFeatures } from './features'

type ChargebeeFeatureOutput<T extends FeatureType> = T extends FeatureType.Switch
  ? boolean
  : T extends FeatureType.Custom | FeatureType.Quantity
    ? number
    : null

export const parseChargebeeFeatureValue = <T extends FeatureType>(
  type: T,
  value: string | 'true' | 'false' | 'Unlimited'
): ChargebeeFeatureOutput<T> => {
  switch (type) {
    case FeatureType.Custom: {
      const parsedNumber = Number(value)
      return (isNaN(parsedNumber) ? 0 : parsedNumber) as ChargebeeFeatureOutput<T>
    }
    case FeatureType.Quantity: {
      const parsedNumber = value === 'Unlimited' ? Number.MAX_SAFE_INTEGER : Number(value)
      return (isNaN(parsedNumber) ? 0 : parsedNumber) as ChargebeeFeatureOutput<T>
    }
    case FeatureType.Switch:
      return Boolean(value) as ChargebeeFeatureOutput<T>
    default:
      return null as ChargebeeFeatureOutput<T>
  }
}

export const parseChargebeeFeature = (feature: `${Feature}` | string): Feature | undefined =>
  Object.entries(Feature).find(([, value]) => value === feature)?.[0] as Feature

export const parseChargebeeFeatureType = (
  type: `${FeatureType}` | string
): FeatureType | undefined =>
  Object.entries(FeatureType).find(([, value]) => value === type)?.[0] as FeatureType

export type ChargebeeBillingFrequency = Lowercase<
  Exclude<keyof typeof BillingFrequency | 'every-3-months', 'Quarterly'>
>

export const CHARGEBEE_DEFAULT_PLAN_QUANTITY = 500

export const CHARGEBEE_PLAN_ID_SEPARATOR = '-'
export const parseChargebeePlanId = (planId = '') => {
  const uppercaseFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

  const [institution = '', plan = '', currency = DEFAULT_CURRENCY, ...billingFrequencyId] =
    planId.split(CHARGEBEE_PLAN_ID_SEPARATOR)

  const billingFrequency = parseChargebeeBillingToEnum(
    billingFrequencyId.join(CHARGEBEE_PLAN_ID_SEPARATOR)
  )

  return {
    institution: Institution[uppercaseFirstLetter(institution) as keyof typeof Institution] ?? null,
    plan: Plan[uppercaseFirstLetter(plan) as keyof typeof Plan] ?? null,
    currency: (Currency[currency as AvailableCurrency] as AvailableCurrency) ?? null,
    billingFrequency: billingFrequency ?? null,
  }
}

export const getChargebeeBillingFrequency = (
  billingFrequency: BillingFrequency
): ChargebeeBillingFrequency => {
  switch (billingFrequency) {
    case BillingFrequency.Yearly:
      return 'yearly'
    case BillingFrequency.Monthly:
      return 'monthly'
    case BillingFrequency.Quarterly:
      return 'every-3-months'
  }
}

type ChargeBusinessEntity = 'faceup-us' | 'faceup-cz'
export const getChargebeeBusinessEntityByCountry = (
  country: Uppercase<Country>
): ChargeBusinessEntity => {
  switch (country) {
    case 'US':
    case 'CA':
      return 'faceup-us'
    default:
      return 'faceup-cz'
  }
}

export const parseChargebeePaymentMethod = (
  auto_collection?: 'on' | 'off' | string
): PaymentMethod | undefined => {
  switch (auto_collection) {
    case 'off':
      return PaymentMethod.Invoice
    default:
      return PaymentMethod.CreditCard
  }
}

export const parseChargebeeBillingToEnum = (
  billingFrequency: ChargebeeBillingFrequency | string
): BillingFrequency | undefined => {
  switch (billingFrequency) {
    case 'yearly':
      return BillingFrequency.Yearly
    case 'monthly':
      return BillingFrequency.Monthly
    case 'every-3-months':
      return BillingFrequency.Quarterly
    default:
      return undefined
  }
}

// https://stackoverflow.com/a/65642944
type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? '_' : ''}${Lowercase<T>}${CamelToSnakeCase<U>}`
  : S
export type PascalToSnakeCase<S extends string> = CamelToSnakeCase<Uncapitalize<S>>
export const parseChargebeeSubscriptionStatusToEnum = (
  status: PascalToSnakeCase<ChargebeeSubscriptionStatus>
): ChargebeeSubscriptionStatus | undefined => {
  switch (status) {
    case 'active':
      return ChargebeeSubscriptionStatus.Active
    case 'cancelled':
      return ChargebeeSubscriptionStatus.Cancelled
    case 'future':
      return ChargebeeSubscriptionStatus.Future
    case 'in_trial':
      return ChargebeeSubscriptionStatus.InTrial
    case 'paused':
      return ChargebeeSubscriptionStatus.Paused
    case 'non_renewing':
      return ChargebeeSubscriptionStatus.NonRenewing
    default:
      return undefined
  }
}
