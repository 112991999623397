export enum EmployeeInvolvement {
  Reporter = 'Reporter',
  Involved = 'Involved',
  Witness = 'Witness',
}

export enum EmployeeProvider {
  UkgPro = 'UkgPro',
  UkgReady = 'UkgReady',
  BambooHr = 'BambooHr',
}

export enum EmployeeStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum RegistrationReason {
  Hr = 'Hr',
  Compliance = 'Compliance',
  Speakup = 'Speakup',
  Survey = 'Survey',
  Other = 'Other',
}

export enum Plan {
  Basic = 'Basic',
  Free = 'Free',
  Premium = 'Premium',
  Standard = 'Standard',
  Unregistered = 'Unregistered',
}

export enum WebhookStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Stopped = 'Stopped',
}

export enum WebhookType {
  ReportCreated = 'ReportCreated',
  MessageCreated = 'MessageCreated',
  InternalCommentCreated = 'InternalCommentCreated',
}

export enum InvoicedTo {
  Client = 'Client',
  Partner = 'Partner',
}

export enum RecipientsOnReportForm {
  Editable = 'Editable',
  Visible = 'Visible',
  Invisible = 'Invisible',
}

export enum BillingFrequency {
  Yearly = 'Yearly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
}

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
}

// match the feature_id in Chargebee
export enum Feature {
  Sso = 'sso',
  TeamMembers = 'team-members',
  Webhooks = 'webhooks',
  ReportingForms = 'reporting-forms',
  Api = 'api',
  Whitelabel = 'whitelabel',
  Integrations = 'integrations',
  Surveys = 'surveys',
}

export enum FeatureType {
  Custom = 'CUSTOM',
  Switch = 'SWITCH',
  Quantity = 'QUANTITY',
}

export enum ChannelStatus {
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

// to ensure that when we add new Feature TS will complain
const defaultFeatureEntitlements: Record<
  Feature,
  {
    feature_type: FeatureType
    value: string
    is_enabled: boolean
  }
> = {
  [Feature.Sso]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
  [Feature.TeamMembers]: {
    feature_type: FeatureType.Custom,
    value: '1',
    is_enabled: true,
  },
  [Feature.Webhooks]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
  [Feature.ReportingForms]: {
    feature_type: FeatureType.Custom,
    value: '1',
    is_enabled: true,
  },
  [Feature.Api]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
  [Feature.Whitelabel]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
  [Feature.Integrations]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
  [Feature.Surveys]: {
    feature_type: FeatureType.Switch,
    value: 'false',
    is_enabled: false,
  },
}

export const DEFAULT_FEATURE_ENTITLEMENTS: {
  feature_id: Feature
  feature_type: FeatureType
  value: string
  is_enabled: boolean
}[] = Object.entries(defaultFeatureEntitlements).map(([feature, value]) => ({
  feature_id: feature as Feature,
  ...value,
}))

export enum AccountType {
  Regular = 'Regular',
  Owner = 'Owner',
}

export const DEFAULT_FREQUENCY = BillingFrequency.Yearly

export const MAX_TRIAL_DAYS = 30

export const MAX_TRIAL_PROLONG_DAYS = 7

export enum LogoSize {
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const defaultLogoSize = LogoSize.md

export const defaultPageIcon: PageIcon = 'home'

export enum OrderByCompanyReportKey {
  Category = 'category',
  Deadline = 'deadline',
  Priority = 'priority',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at',
  Source = 'source',
}

export type OrderByCompanyReports = {
  key: OrderByCompanyReportsPossibleKeys
  order: OrderByKeyword
}

export enum OrderByCompanyReportsPossibleKeys {
  category = 'category',
  created_at = 'created_at',
  deadline = 'deadline',
  priority = 'priority',
  source = 'source',
  updated_at = 'updated_at',
}

export enum OrderByKeyword {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type PageIcon =
  | 'home'
  | 'homeLine'
  | 'heartCircle'
  | 'helpCircle'
  | 'infoCircle'
  | 'lifeBuoy'
  | 'building1'
  | 'building2'
  | 'medicalSquare'
  | 'announcement'
  | 'alertCircle'
  | 'file1'
  | 'file2'
  | 'fileCheck'
  | 'fileQuestion'
  | 'fileHeart'
  | 'clipboardCheck'
  | 'certificate'
  | 'bookOpen'
  | 'flag'
  | 'fileShield'
  | 'fileLock'
  | 'shieldTick'
  | 'phone'
  | 'annotationInfo'
  | 'users'

export enum Institution {
  School = 'School',
  Company = 'Company',
}

export enum FormItemType {
  SimpleText = 'SimpleText',
  Select = 'Select',
  MultiSelect = 'MultiSelect',
  MoreInformation = 'MoreInformation',
  Category = 'Category',
  OrganizationalUnit = 'OrganizationalUnit',
  SenderName = 'SenderName',
  Classroom = 'Classroom',
  MultilineText = 'MultilineText',
  Date = 'Date',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
}

export enum RecaptchaType {
  Invisible = 'Invisible',
  Checkbox = 'Checkbox',
}

export enum SupportedRegion {
  UsWest1 = 'us-west-1',
  EuWest1 = 'eu-west-1',
  MeCentral1 = 'me-central-1',
}

// https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html
export enum Region {
  UsEast2 = 'us-east-2',
  UsEast1 = 'us-east-1',
  UsWest1 = 'us-west-1',
  UsWest2 = 'us-west-2',
  AfSouth1 = 'af-south-1',
  ApEast1 = 'ap-east-1',
  ApSouth2 = 'ap-south-2',
  ApSoutheast3 = 'ap-southeast-3',
  ApSoutheast4 = 'ap-southeast-4',
  ApSouth1 = 'ap-south-1',
  ApNortheast3 = 'ap-northeast-3',
  ApNortheast2 = 'ap-northeast-2',
  ApSoutheast1 = 'ap-southeast-1',
  ApSoutheast2 = 'ap-southeast-2',
  ApNortheast1 = 'ap-northeast-1',
  CaCentral1 = 'ca-central-1',
  EuCentral1 = 'eu-central-1',
  EuWest1 = 'eu-west-1',
  EuWest2 = 'eu-west-2',
  EuSouth1 = 'eu-south-1',
  EuWest3 = 'eu-west-3',
  EuSouth2 = 'eu-south-2',
  EuNorth1 = 'eu-north-1',
  EuCentral2 = 'eu-central-2',
  MeSouth1 = 'me-south-1',
  MeCentral1 = 'me-central-1',
  SaEast1 = 'sa-east-1',
}

export const DEFAULT_REGION = Region.EuWest1

export enum ReportJustification {
  Justified = 'Justified',
  Unjustified = 'Unjustified',
  Unknown = 'Unknown',
}

// https://apidocs.chargebee.com/docs/api/subscriptions?prod_cat_ver=2#subscription_status
export enum ChargebeeSubscriptionStatus {
  Future = 'Future',
  InTrial = 'InTrial',
  Active = 'Active',
  Paused = 'Paused',
  Cancelled = 'Cancelled',
  NonRenewing = 'NonRenewing',
}

export enum CompanyAuditLogActionName {
  EditStatus = 'EditStatus',
  EditClosingJustification = 'EditClosingJustification',
  CreateReport = 'CreateReport',
  EditReport = 'EditReport',
  EditPriority = 'EditPriority',
  AssignMembers = 'AssignMembers',
  ReportChangedCompany = 'ReportChangedCompany',
  ChangedAccessors = 'ChangedAccessors',
  ChangedCategory = 'ChangedCategory',
  ChangedDeadline = 'ChangedDeadline',
  ChangedDeadlineDone = 'ChangedDeadlineDone',
  ChangedInternalComment = 'ChangedInternalComment',
  DeletedInternalComment = 'DeletedInternalComment',
  ChangedLabel = 'ChangedLabel',
  EditFormItemAnswer = 'EditFormItemAnswer',
  EditJustification = 'EditJustification',
  Export = 'Export',
  RestrictRecipient = 'RestrictRecipient',
}

/**
 * How was the report reported. `source` property of `CompanyReport`.
 * This can be customized only in administration. Reports from hotline and form use preset value.
 *
 * Not to be confused with report source (`report_sources` DB table) which is more of a channel.
 */
export enum ReportSourceType {
  InPerson = 'InPerson', // reporter came to the administrator and talked face to face
  Phone = 'Phone', // reporter called to the administrator
  AutomatedHotline = 'AutomatedHotline', // reporter used hotline, used for reports via automated hotline
  LiveHotline = 'LiveHotline', // reporter used hotline, used for reports via live hotline
  Email = 'Email', // reporter wrote an email to the administrator
  ReportingSystem = 'ReportingSystem', // reporter used form
  Other = 'Other', // reporter sent a pigeon or note in a bottle
  Survey = 'Survey',
}

export enum HotlinePhoneNumberType {
  Landline = 'Landline',
  TollFree = 'TollFree',
}

export enum ChannelType {
  AutomatedHotline = 'AutomatedHotline',
  LiveHotline = 'LiveHotline',
  ReportingSystem = 'ReportingSystem',
  Survey = 'Survey',
}

export enum MaterialType {
  Guide = 'Guide',
  Template = 'Template',
  Promotion = 'Promotion',
  Other = 'Other',
}
export const materialTypeDefinition = Object.values(MaterialType)

/**
 * By what means the report was created.
 */
export enum ReportCreationOrigin {
  Member = 'Member', // via reporting system or hotline
  Reporter = 'Reporter', // via administration
  Redaction = 'Redaction', // via redaction tool in administration
}

export enum MaterialGenerationType {
  Static = 'Static',
  Leaflet = 'Leaflet',
  Certificate = 'Certificate',
}

export enum PermissionType {
  ReportAccess = 'ReportAccess', // ViewCases
  EditCases = 'EditCases',
  ExportCases = 'ExportCases',
  DeleteCases = 'DeleteCases',
  WriteInternalComments = 'WriteInternalComments',
  WriteMessages = 'WriteMessages',
  /**
   * General settings.
   */
  SettingsAccess = 'SettingsAccess',
  /**
   * Represents the membership of user in the institution, not if they have the real access to reports.
   *
   * Effectively used only for UserType.Member, not others.
   *
   * When this permission is required one of institutionId, companyId (deprecated), motherId arguments have to be specified.
   * See apps/backend/src/auth/checkPermissions.ts.
   */
  InstitutionAccess = 'InstitutionAccess',
  /**
   * Billing page.
   *
   * Historically this was used for partners who were not members of the institution but needed access to billing section of client administration,
   * so they could pay the subscription. Now `allowPartnerHandleInstitutionBilling` serves this purpose.
   */
  BillingAccess = 'BillingAccess',
  /**
   * Members page.
   */
  ManageUsers = 'ManageUsers',
  /**
   * Categories page.
   */
  ManageCategories = 'ManageCategories',
  /**
   * Organizational units page.
   */
  ManageOrganizationalUnits = 'ManageOrganizationalUnits',
  /**
   * Reporting channels page.
   */
  ManageReportingChannels = 'ManageReportingChannels',
  Analytics = 'Analytics',
  Surveys = 'Surveys',
}

export enum FrontendPermissionType {
  ReportAccess = 'ReportAccess', // ViewCases
  EditCases = 'EditCases',
  ExportCases = 'ExportCases',
  DeleteCases = 'DeleteCases',
  WriteInternalComments = 'WriteInternalComments',
  WriteMessages = 'WriteMessages',
  /**
   * General settings.
   */
  SettingsAccess = 'SettingsAccess',
  /**
   * Billing page.
   *
   * Historically this was used for partners who were not members of the institution but needed access to billing section of client administration,
   * so they could pay the subscription. Now `allowPartnerHandleInstitutionBilling` serves this purpose.
   */
  BillingAccess = 'BillingAccess',
  /**
   * Members page.
   */
  ManageUsers = 'ManageUsers',
  /**
   * Categories page.
   */
  ManageCategories = 'ManageCategories',
  /**
   * Organizational units page.
   */
  ManageOrganizationalUnits = 'ManageOrganizationalUnits',
  /**
   * Reporting channels page.
   */
  ManageReportingChannels = 'ManageReportingChannels',
  Analytics = 'Analytics',
  Surveys = 'Surveys',
}

export enum LogoType {
  Rectangular = 'Rectangular',
  Square = 'Square',
}

/**
 * I use vague terms (soon and later) because they are flexible.
 */
export enum ReportDeadlineFilter {
  Today = 'Today',
  Overdue = 'Overdue',
  Soon = 'Soon',
  Later = 'Later',
}

export enum TranscriptionStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  Pending = 'Pending',
  TimedOut = 'TimedOut',
  NotStarted = 'NotStarted',
}

export enum ChannelPageType {
  BeforeSend = 'BeforeSend',
  FormItems = 'FormItems',
  AfterSend = 'AfterSend',
}

export enum ClosingReason {
  Resolved = 'Resolved',
  Rejected = 'Rejected',
  Spam = 'Spam',
  Other = 'Other',
}

export enum MessageAction {
  SpellCheck = 'SpellCheck',
  RewriteFriendly = 'RewriteFriendly',
  RewriteProfessional = 'RewriteProfessional',
  ExpandMessage = 'ExpandMessage',
}

export enum TokenType {
  ForgottenPassword = 'ForgottenPassword',
  CreatePassword = 'CreatePassword',
  MigrateToSSO = 'MigrateToSSO',
  MigrateFromSSO = 'MigrateFromSSO',
  VerifyTrialEmail = 'VerifyTrialEmail',
  VerifyPurchaseEmail = 'VerifyPurchaseEmail',
  VerifyVictimEmail = 'VerifyVictimEmail',
}

export enum ReportPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

export enum ReportStatus {
  // Open is null value
  Open = 'Open',
  Closed = 'Closed',
  Archived = 'Archived',
}

export enum HotlineOrderStatus {
  Processing = 'Processing',
  Completed = 'Completed',
}

export enum AutomatedHotlineTranslationType {
  WelcomeMessage = 'WelcomeMessage',
  AfterVoiceRecordingEndMessage = 'AfterVoiceRecordingEndMessage',
}

export enum VerificationStatus {
  // initial state
  Pending = 'Pending',
  // end state
  Approved = 'Approved',
  // end state
  Rejected = 'Rejected',
}

export enum Day {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum InvitationStatus {
  Active = 'Active',
  Expired = 'Expired',
  Sent = 'Sent',
}

export enum FuAdminRoleType {
  Admin = 'Admin', // all permissions
  Manager = 'Manager', // do not see reports
  Psychologist = 'Psychologist', // can see reports
}

export enum ContactIssue {
  BuyMaxVariantFree = 'BuyMaxVariantFree',
  BuyMaxVariantBasic = 'BuyMaxVariantBasic',
  BuyMaxVariantStandard = 'BuyMaxVariantStandard',
  BuyMaxVariantPremium = 'BuyMaxVariantPremium',
  ContactDuringTrial = 'ContactDuringTrial',
  ContactAfterTrial = 'ContactAfterTrial',
  ContactViaBilling = 'ContactViaBilling',
  ContactLicenceStartInFuture = 'ContactLicenceStartInFuture',
  Upsell = 'Upsell',
  Unknown = 'Unknown',
}

export enum NotificationType {
  NewReport = 'NewReport',
  ReportAssignedToMe = 'ReportAssignedToMe',
  NewInternalCommentMention = 'NewInternalCommentMention',
  NewFollowUpCommentForAssignedReport = 'NewFollowUpCommentForAssignedReport',
  NewFollowUpCommentForUnassignedReport = 'NewFollowUpCommentForUnassignedReport',
  ReportNearDeadline = 'ReportNearDeadline',
}

export enum NotificationScope {
  All = 'All',
  OnlyAssignedReports = 'OnlyAssignedReports',
}

export enum UserLoginType {
  FuAdmin = 'FuAdmin', // public.fu_admins table
  PublicUser = 'PublicUser', // public.members
  Victim = 'Victim', // public.victims table
}
