import {
  type PagesTranslationItems,
  type PagesTranslationVariants,
  usePageTemplate as useUiPageTemplate,
} from '@faceup/ui'
import { DEFAULT_LANGUAGE, type Language } from '@faceup/utils'
import { messagesPages } from '../AppProviders'
import { useGlobalInfo } from '../locales'
import useMockedReportMessages from './useMockedReportMessages'

/**
 * If language not specified, use the default language from UiProvider
 */
const usePageTemplate = (language?: Language) => {
  const { institutionName: name } = useGlobalInfo()
  // Default language because can't be undefined
  const { formatMessageWithConfigLanguage } = useMockedReportMessages(language ?? DEFAULT_LANGUAGE)

  const translatedMessages = Object.entries(messagesPages).reduce(
    (acc, [key, { title, content }]) => ({
      ...acc,
      [key]: {
        title: formatMessageWithConfigLanguage(title, { name }),
        content: formatMessageWithConfigLanguage(content, { name }),
      },
    }),
    {} as Record<PagesTranslationVariants, Record<PagesTranslationItems, string>>
  )

  // But we do not want to use this default language, we want to use the language from the app
  return useUiPageTemplate(language ? translatedMessages : undefined)
}

export default usePageTemplate
