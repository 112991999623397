import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { ulChevronUp } from '@faceup/icons/ulChevronUp'
import { Collapse as AntCollapse, type CollapseProps as AntCollapseProps } from '@faceup/ui-base'
import styles from './assets/Collapse.module.css'

type CollapseProps = Omit<AntCollapseProps, 'expandIcon'>

export const Collapse = (props: CollapseProps) => {
  return (
    <AntCollapse
      className={styles.collapse}
      {...props}
      expandIcon={panelProps =>
        panelProps.isActive ? (
          <UntitledIcon icon={ulChevronUp} size={16} />
        ) : (
          <UntitledIcon icon={ulChevronDown} size={16} />
        )
      }
    />
  )
}
