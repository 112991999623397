import { Link } from '@faceup/router'
import { Button, Modal } from '@faceup/ui'
import { ExclamationCircleFilled, Space, Typography } from '@faceup/ui-base'
import { ContactIssue } from '@faceup/utils'
import { useState } from 'react'
import { FormattedMessage, type TypedMessageDescriptor, defineMessages } from '../TypedIntl'
import useAnalytics from '../utils/analytics'
import ContactSalesModal from './ContactSalesModal'

const { Title } = Typography

const messages = defineMessages({
  action: 'Administration.trialEnded.action',
  contactSales: 'Administration.trialEnded.contactSales',
})

type ModalMessages = 'title' | 'description'

const messagesTrialEnded = defineMessages<ModalMessages>({
  title: 'Administration.trialEnded.title',
  description: 'Administration.trialEnded.description',
})

const messagesSubscriptionEnded = defineMessages<ModalMessages>({
  title: 'Administration.subscriptionEnded.title',
  description: 'Administration.subscriptionEnded.description',
})

type Variant = 'trialEnded' | 'subscriptionEnded'
type TrialEndedModalProps = {
  variant: Variant
  visible: boolean
}

const SubscriptionEndedModal = ({ variant, visible }: TrialEndedModalProps) => {
  const [isContactSalesModalOpened, setIsContactSalesModalOpened] = useState<boolean>(false)
  const { trackBuyActionClicked } = useAnalytics()

  const config: Record<
    Variant,
    {
      modalMessages: Record<ModalMessages, TypedMessageDescriptor>
      onBuyClick: () => void
    }
  > = {
    subscriptionEnded: {
      modalMessages: messagesSubscriptionEnded,
      onBuyClick: () => undefined,
    },
    trialEnded: {
      modalMessages: messagesTrialEnded,
      onBuyClick: () => trackBuyActionClicked(),
    },
  }
  const { modalMessages, onBuyClick } = config[variant]

  return (
    <>
      <Modal opened={visible} title={<FormattedMessage {...modalMessages.title} />} width={520}>
        <Space
          style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
          align='center'
          direction='vertical'
          size='large'
        >
          <ExclamationCircleFilled style={{ color: '#ef4a45', fontSize: '1.5rem' }} />
          <Space align='center' direction='vertical'>
            <Title level={2}>
              <FormattedMessage {...modalMessages.title} />
            </Title>
            <FormattedMessage {...modalMessages.description} />
            <Button
              sx={{ textDecoration: 'underline' }}
              variant='text'
              onClick={() => {
                setIsContactSalesModalOpened(true)
              }}
            >
              <FormattedMessage {...messages.contactSales} />
            </Button>
          </Space>
          <Link to={routes => routes.settingsInvoicing()} onClick={() => onBuyClick()}>
            <Button>
              <FormattedMessage {...messages.action} />
            </Button>
          </Link>
        </Space>
      </Modal>
      <ContactSalesModal
        contactIssue={ContactIssue.ContactAfterTrial}
        onClose={() => setIsContactSalesModalOpened(false)}
        opened={isContactSalesModalOpened}
      />
    </>
  )
}

export default SubscriptionEndedModal
