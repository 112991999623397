import styled from '@emotion/styled'
import { Button, type ButtonProps, type PolymorphicComponentProps } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import { type Plan, planMapName } from '@faceup/utils'
import { Tooltip } from '@mantine/core'
import moment from 'moment-timezone'
import type { ReactNode } from 'react'
import type { BillingFrequency } from '../Pages/CompanySettings/Components/BillingPeriodSwitcher'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import CheckIcon from './assets/check-icon.svg'
import PlusIcon from './assets/plus-icon.svg'

const messages = defineMessages({
  currentPlan: 'Administration.companyInvoicing.currentPlan',
  discountTill: 'Administration.companyInvoicing.discountTill',
})

const messagesPricingInfo = defineMessages<BillingFrequency>({
  yearly: 'Administration.companyInvoicing.pricingInfo.annually',
  monthly: 'Administration.companyInvoicing.pricingInfo.monthly',
  quarterly: 'Administration.companyInvoicing.pricingInfo.quarterly',
})

type Variant = 'default' | 'currentPlan' | 'highlighted'

type InvoicingPlanProps = {
  billingFrequency: BillingFrequency
  variant?: Variant
  name: Plan
  description?: string
  price?: ReactNode
  lineText?: string
  features?: ReactNode[]
  featureIcon?: 'check' | 'plus'
  button?: InvoicingPlanButtonProps
  isPricingInfoHidden?: boolean
  discountTill?: string | null
  originalPrice?: ReactNode
}

export type InvoicingPlanButtonProps = { tooltip?: ReactNode } & PolymorphicComponentProps<
  'button',
  ButtonProps
>
const InvoicingPlan = ({
  billingFrequency,
  variant = 'default',
  name,
  description,
  price,
  lineText,
  features = [],
  featureIcon = 'check',
  button,
  isPricingInfoHidden = false,
  discountTill,
  originalPrice,
}: InvoicingPlanProps) => (
  <Border variant={variant}>
    {variant === 'currentPlan' && (
      <TitleWrapper>
        <Title>
          <FormattedMessage {...messages.currentPlan} />
        </Title>
      </TitleWrapper>
    )}
    {discountTill && (
      <TitleWrapper>
        <Title>
          <FormattedMessage {...messages.discountTill} /> {moment(discountTill).format('LL')}
        </Title>
      </TitleWrapper>
    )}
    <Margin>
      <Name level={3}>{planMapName[name]}</Name>
      <Description>{description}</Description>

      <PriceWrapper>
        <Price>{price}</Price>
        <OriginalPrice>{originalPrice}</OriginalPrice>
      </PriceWrapper>

      {!isPricingInfoHidden && (
        <NoVatText>
          <FormattedMessage {...messagesPricingInfo[billingFrequency]} />
        </NoVatText>
      )}
    </Margin>
    <LineWrapper>
      <Line />
      {lineText && (
        <LineBoxWrapper>
          <LineBox>{lineText}</LineBox>
        </LineBoxWrapper>
      )}
    </LineWrapper>
    <Margin>
      {features.map((feature, i) => (
        <FeatureItem
          // biome-ignore lint/suspicious/noArrayIndexKey:
          key={`feature-${i}`}
        >
          <img src={featureIcon === 'check' ? CheckIcon : PlusIcon} alt='' />
          <FeatureText>{feature}</FeatureText>
        </FeatureItem>
      ))}
    </Margin>
    <BottomButtonWrapper>
      {button ? (
        <Tooltip label={button.tooltip} disabled={!button.tooltip}>
          {/* wrap button in div so tooltip works when button disabled */}
          <div>
            <Button
              {...button}
              data-cy={`invoicing-plan-button-${name}`}
              sx={{
                marginBlockStart: 18,
                marginBlockEnd: 24,
              }}
              isFullWidth
            />
          </div>
        </Tooltip>
      ) : (
        <PlaceholderInsteadButton />
      )}
    </BottomButtonWrapper>
  </Border>
)

const Border = styled.div<{ variant: Variant }>`
  border: 1px solid ${({ variant }) => (variant === 'default' ? '#e6eaec' : '#09bc8a')};
  border-radius: 10px;
  margin-block-start: ${({ variant }) => (variant === 'default' ? '32px' : '0')};
  box-shadow: ${({ variant }) =>
    ['currentPlan', 'highlighted'].includes(variant)
      ? '0px 8px 15px rgba(0, 0, 0, 0.08)'
      : 'initial'};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: ${({ variant }) => (variant === 'default' ? 'calc(100% - 32px)' : '100%')};
`

const TitleWrapper = styled.div`
  background-color: #09bc8a;
`

const Title = styled.p`
  color: #ffffff;
  padding-block: 4px;
  padding-inline: 12px;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  text-align: center;
`

const Name = styled(Typography.Title)`
  margin-block-start: 24px;
  margin-block-end: 0;
`

const Description = styled(Typography.Text)`
  display: block;
  margin-block-start: 8px;
  margin-block-end: 0;
  line-height: 22px;
  color: #516c7d;
`

const Price = styled(Typography.Text)`
  display: block;
  margin-block-start: 16px;
  margin-block-end: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
`
const OriginalPrice = styled.span`
  text-decoration-line: line-through;
  color: #6a8190;
  text-decoration-thickness: 1px;
  font-weight: 600;
  margin-block-start: 16px;
  padding-left: 8px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
`

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Margin = styled.div`
  margin-inline: 24px;
`

const NoVatText = styled(Typography.Text)`
  display: block;
  margin-block: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #8296a2;
`

const LineWrapper = styled.div`
  position: relative;
  margin-inline: 16px;
`

const Line = styled.hr`
  margin-block: 24px;
  border: 0;
  border-top: 1px solid #e6eaec;
`

const LineBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 9px;
`

const LineBox = styled(Typography.Text)`
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  background: #e6eaec;
  border-radius: 58px;
  padding: 4px 12px;
  color: #38576b;
`

const FeatureItem = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;
`

const FeatureText = styled(Typography.Text)`
  display: block;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #38576b;
`

const BottomButtonWrapper = styled(Margin)`
  margin-top: auto;
`

const PlaceholderInsteadButton = styled.div`
  height: 93px;
`

export default InvoicingPlan
