import { deletePersonalKeys } from '@faceup/crypto'
import type { FrontendPermissionType } from '@faceup/utils'
import * as Sentry from '@sentry/react'
import { LANGUAGE_STORAGE_KEY } from '../Contexts/LanguageProvider'
import { useGlobalInfo } from '../locales'

export const AUTH_TOKEN_NAME = 'jwt'

export const usePermissions = () => {
  const { permissions } = useGlobalInfo()

  const enabledPermissionTypes = permissions
    .filter(permission => permission?.enabled)
    .map(permission => permission?.type)

  const hasPermissions = (...requiredPermissions: FrontendPermissionType[]) =>
    requiredPermissions.every(requiredPermission =>
      enabledPermissionTypes.includes(requiredPermission)
    )

  return {
    hasPermissions,
  }
}

class Auth {
  public static logout = () => {
    Sentry.setUser(null)

    localStorage.removeItem(AUTH_TOKEN_NAME)
    sessionStorage.removeItem(AUTH_TOKEN_NAME)
    localStorage.removeItem(LANGUAGE_STORAGE_KEY)
    deletePersonalKeys()
  }

  public static setJwt = ({ jwt, persistent = false }: { jwt: string; persistent?: boolean }) => {
    Sentry.setUser({ id: Auth.getUserIdFromJWT(jwt) })

    if (persistent) {
      localStorage.setItem(AUTH_TOKEN_NAME, jwt)
    } else {
      sessionStorage.setItem(AUTH_TOKEN_NAME, jwt)
    }
  }

  public static getJwt = () => {
    const localToken = localStorage.getItem(AUTH_TOKEN_NAME)
    if (localToken) {
      Sentry.setUser({ id: Auth.getUserIdFromJWT(localToken) })
      return localToken
    }

    const sessionToken = sessionStorage.getItem(AUTH_TOKEN_NAME)
    if (sessionToken) {
      Sentry.setUser({ id: Auth.getUserIdFromJWT(sessionToken) })
      return sessionToken
    }

    Sentry.setUser(null)
    return null
  }

  public static isAuthenticated = () => Boolean(Auth.getJwt())

  public static isRemembered = () => Boolean(localStorage.getItem(AUTH_TOKEN_NAME))

  public static getUserIdFromJWT = (jwt: string) => {
    const payload = (jwt.split('.') ?? [])[1]
    if (!payload) {
      return ''
    }

    // id is undefined for SSO users
    const { id, username } = JSON.parse(atob(payload))

    return `${id ?? username}`
  }
}

export default Auth
