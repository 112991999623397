import type { UntitledIconData } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXCircle } from '@faceup/icons/ulXCircle'
import type { MessageArgsProps } from 'antd'
import type { AlertProps } from 'antd'
import AntSizeContext from 'antd/es/config-provider/SizeContext'
export type { Locale } from 'antd/es/locale'
export { ConfigContext } from 'antd/es/config-provider'
export {
  App,
  List,
  Select,
  type SelectProps,
  type RefSelectProps,
  Space,
  Calendar,
  type CardProps,
  Tooltip,
  Input,
  type InputProps,
  Upload,
  Form,
  Row,
  Col,
  Badge,
  type BadgeProps,
  Skeleton,
  Layout,
  notification,
  ConfigProvider,
  Checkbox,
  type CheckboxProps,
  Empty,
  Radio,
  Pagination,
  Card,
  Slider,
  Spin,
  Result,
  TreeSelect,
  InputNumber,
  type InputNumberProps,
  Divider,
  Tag,
  Segmented,
  Popconfirm,
  Popover,
  type PopoverProps,
  Progress,
  Rate,
  type InputRef,
  type ProgressProps,
  type SpaceProps,
  Descriptions,
  Flex,
  Menu,
  type MenuProps,
  type FormItemProps,
  QRCode,
  ColorPicker,
  type QRCodeProps,
  Collapse,
  type CollapseProps,
  type MessageArgsProps,
} from 'antd'
export type { TextAreaProps } from 'antd/es/input/TextArea'
export type { SizeType } from 'antd/es/config-provider/SizeContext'
export type { CheckboxChangeEvent } from 'antd/es/checkbox'
export type { BaseOptionType } from 'antd/es/select'
export type { DefaultOptionType } from 'antd/es/cascader'
export type { ItemType } from 'antd/es/menu/interface'
export type { SortOrder, SorterResult } from 'antd/es/table/interface'

export { Alert } from './Alert'
export { DatePicker, type DatePickerProps } from './DatePicker'
export { Dropdown, type DropdownProps } from './Dropdown'
export { TimePicker, type TimePickerProps } from './TimePicker'
export { Avatar, type AvatarProps } from './Avatar'
export { Modal, type ModalProps } from './Modal'
export { Button, type ButtonProps } from './Button'
export { UiBaseProvider, useUiBaseConfig } from './UiBaseProvider'
export { useModal } from './useModal'
export { Tabs, type TabsProps } from './Tabs'
export { Table, type TableProps } from './Table'
export { Typography } from './Typography'

export const SizeContext = AntSizeContext
export { constants } from './constants'
export { locales } from './locales'
export * from './Icons'
export * from './useMessage'

type AlertType = NonNullable<AlertProps['type']>
export type MessageStatusType = NonNullable<MessageArgsProps['type']>

export const statusIcons: Record<
  AlertType | Exclude<MessageStatusType, 'loading'>,
  UntitledIconData
> = {
  error: ulXCircle,
  info: ulInfoCircle,
  success: ulCheckCircle,
  warning: ulAlertCircle,
}
