import { useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { useMotherId } from '@faceup/institution'
import { Modal, Text } from '@faceup/ui'
import { Button, Dropdown, Flex, Table, Tooltip, notification, useModal } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { type Dispatch, useMemo, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { Question } from './Question'

const messages = defineMessages({
  delete: 'Administration.surveys.options.delete',
  viewDetails: 'Administration.surveys.options.viewDetails',
  hide: 'Administration.surveys.options.hide',
  show: 'Administration.surveys.options.show',
  submissionNumber: 'Administration.surveys.submissions.submissionNumber',
  submittedNumber: 'Administration.surveys.submissionsDetail.submittedNumber',
  submittedAt: 'Administration.surveys.submissionsDetail.submittedAt',
  emptyLabel: 'Administration.surveys.submissionsDetail.emptyLabel',
  emptyDescription: 'Administration.surveys.submissionsDetail.emptyDescription',
  deleteSubmissionTitle: 'Administration.submissions.delete.confirmationModal.title',
  deleteSubmissionContent: 'Administration.submissions.delete.confirmationModal.content',
  deletedSubmission: 'Administration.submissions.deleted',
  hideSubmissionTitle: 'Administration.submissions.hide.confirmationModal.title',
  hideSubmissionContent: 'Administration.submissions.hide.confirmationModal.content',
  hiddenSubmission: 'Administration.submissions.hidden',
  showSubmissionTitle: 'Administration.surveys.show.confirmationModal.title',
  showSubmissionContent: 'Administration.surveys.show.confirmationModal.content',
})

const fragments = {
  SubmissionDetails_reportSource: graphql(`
    fragment SubmissionDetails_reportSource on ReportSource {
      id
      config {
        ... on SurveyChannelConfiguration {
          formItems {
            id
            options {
              id
              order
              labelTranslations {
                translation
                language
              }
            }
            type
            labelTranslations {
              translation
              language
            }
          }
        }
      }
      allCases: cases(includeHiddenAndDeleted: true) {
        ... on Submission {
          id
          submittedAt
          isHidden
          isDeleted
          answers {
            id
            values
            formItem {
              id
            }
          }
        }
      }
    }
  `),
}

const mutations = {
  DeleteSurveySubmission: graphql(`
    mutation DeleteSurveySubmission($input: DeleteSurveySubmissionInput!) {
      deleteSurveySubmission(input: $input) {
        channel {
          id
          cases {
            ... on Submission {
              id
              isDeleted
            }
          }
        }
      }
    }
  `),
  ToggleSurveySubmissionVisibility: graphql(`
    mutation ToggleSurveySubmissionVisibility(
      $input: ToggleSurveySubmissionVisibilityInput!
    ) {
      toggleSurveySubmissionVisibility(input: $input) {
        submission {
          id
          isHidden
        }
      }
    }
  `),
}

type SubmissionDetails_reportSource = ResultOf<typeof fragments.SubmissionDetails_reportSource>
type Submission = SubmissionDetails_reportSource['allCases'][number]
type SubmissionWithOrder = Submission & { order: number }

export const SubmissionsDetails = ({
  data: _data,
  refetch,
}: {
  data: FragmentType<typeof fragments.SubmissionDetails_reportSource>
  refetch: () => void // need to refetch the data for the other tab
}) => {
  const data = getFragmentData(fragments.SubmissionDetails_reportSource, _data)
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    SubmissionWithOrder['id'] | null
  >()
  const { formatMessage } = useIntl()

  const submissionsWithOrders = useMemo(() => {
    const submissions = data?.allCases ?? []
    return submissions.map((submission, index) => ({
      ...submission,
      order: index + 1,
    }))
  }, [data?.allCases])

  const selectedSubmission = useMemo(
    () => submissionsWithOrders.find(submission => submission.id === selectedSubmissionId),
    [selectedSubmissionId, submissionsWithOrders]
  )

  const columns = [
    {
      key: 'submissionNumber',
      title: formatMessage(messages.submittedNumber),
      dataIndex: 'submissionNumber',
    },
    {
      key: 'submittedAt',
      title: formatMessage(messages.submittedAt),
      dataIndex: 'submittedAt',
    },
    {
      key: 'options',
      title: '',
      dataIndex: 'options',
      width: 54,
    },
  ]

  const dataSource = submissionsWithOrders.map(submission => ({
    submissionNumber: (
      <Flex gap='12px' align='center'>
        <Text color={submission.isDeleted || submission.isHidden ? 'dark.50' : undefined}>
          #{submission.order}
        </Text>
        {submission.isDeleted ? (
          <Tooltip title={<FormattedMessage {...messages.deletedSubmission} />}>
            <UntitledIcon icon={ulTrash01} color='#ef4a45' />
          </Tooltip>
        ) : (
          submission.isHidden && (
            <Tooltip title={<FormattedMessage {...messages.hiddenSubmission} />}>
              <UntitledIcon icon={ulEyeOff} />
            </Tooltip>
          )
        )}
      </Flex>
    ),
    submittedAt: (
      <Text color={submission.isDeleted || submission.isHidden ? 'dark.50' : undefined}>
        {moment(submission.submittedAt).format('LLL')}
      </Text>
    ),
    options: !submission.isDeleted && (
      <CellOptions
        cell={submission}
        channel={data}
        selectedSubmission={selectedSubmission}
        setSelectedSubmissionId={setSelectedSubmissionId}
        refetch={refetch}
      />
    ),
  }))

  return <Table columns={columns} dataSource={dataSource} pagination={false} shadow />
}

const menuOptions = {
  viewItem: 'viewItem',
  toggleVisibilityItem: 'toggleVisibilityItem',
  deleteItem: 'deleteItem',
} as const

type ItemType = keyof typeof menuOptions

const CellOptions = ({
  cell: submission,
  channel,
  selectedSubmission,
  setSelectedSubmissionId,
  refetch,
}: {
  cell: SubmissionWithOrder
  channel: SubmissionDetails_reportSource
  selectedSubmission: SubmissionWithOrder | null | undefined
  setSelectedSubmissionId: Dispatch<SubmissionWithOrder['id'] | null>
  refetch: () => void
}) => {
  const modal = useModal()
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const formItems =
    channel.config?.__typename === 'SurveyChannelConfiguration' ? channel.config.formItems : []

  const [deleteSurveySubmission] = useMutation(mutations.DeleteSurveySubmission, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      refetch()
    },
  })

  const [toggleSurveySubmissionVisibility] = useMutation(
    mutations.ToggleSurveySubmissionVisibility,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted: () => {
        refetch()
      },
    }
  )

  const confirmToggleVisibility = (submission: SubmissionWithOrder) => {
    const toggleVisibilityMessages = {
      isVisible: {
        title: messages.hideSubmissionTitle,
        content: messages.hideSubmissionContent,
      },
      isHidden: {
        title: messages.showSubmissionTitle,
        content: messages.showSubmissionContent,
      },
    }

    const { title, content } =
      toggleVisibilityMessages[submission.isHidden ? 'isHidden' : 'isVisible']

    modal.confirm({
      type: 'info',
      title: formatMessage(title),
      content: formatMessage(content),
      onOk: async () => {
        await toggleSurveySubmissionVisibility({
          variables: {
            input: {
              motherId: getMotherId(),
              channelId: channel.id,
              submissionId: submission.id,
              isVisible: !submission.isHidden,
            },
          },
        })
      },
    })
  }

  const actions: Record<ItemType, () => void> = {
    viewItem: () => {
      setSelectedSubmissionId(submission.id)
    },
    deleteItem: () => {
      modal.confirm({
        type: 'warning',
        title: formatMessage(messages.deleteSubmissionTitle),
        content: formatMessage(messages.deleteSubmissionContent),
        onOk: async () => {
          await deleteSurveySubmission({
            variables: {
              input: {
                motherId: getMotherId(),
                channelId: channel.id,
                submissionId: submission.id,
              },
            },
          })

          setSelectedSubmissionId(null)

          return
        },
      })
    },
    toggleVisibilityItem: () => {
      confirmToggleVisibility(submission)
    },
  }

  const handleClick = (menuItem: ItemType) => {
    const action = actions[menuItem]
    action()
  }

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: menuOptions.viewItem,
              label: <FormattedMessage {...messages.viewDetails} />,
            },
            {
              key: menuOptions.toggleVisibilityItem,
              label: submission.isHidden ? (
                <FormattedMessage {...messages.show} />
              ) : (
                <FormattedMessage {...messages.hide} />
              ),
            },
            {
              key: menuOptions.deleteItem,
              label: <FormattedMessage {...messages.delete} />,
              danger: true,
            },
          ],
          onClick: info => handleClick(info.key as ItemType),
        }}
      >
        <Button type='text' data-onboarding='more-options-button'>
          <UntitledIcon icon={ulDotsVertical} />
        </Button>
      </Dropdown>
      {selectedSubmission?.id === submission.id && (
        <Modal
          opened={Boolean(selectedSubmission.id === submission.id)}
          width={644}
          onClose={() => setSelectedSubmissionId(null)}
          title={
            <Flex gap='8px' align='center'>
              <FormattedMessage
                {...messages.submissionNumber}
                values={{ number: selectedSubmission.order }}
              />
              <UntitledIcon icon={selectedSubmission.isHidden ? ulEyeOff : ulEye} />
            </Flex>
          }
        >
          <Flex vertical gap='large'>
            <Text color='subtleText' variant='body' size='small'>
              <Flex gap='middle'>
                <div>
                  {`${formatMessage(messages.submittedAt)}: ${moment(selectedSubmission.submittedAt).format('LLL')}`}
                </div>
              </Flex>
            </Text>
            <Flex vertical gap='32px'>
              {formItems.map(formItem => {
                const answer = selectedSubmission.answers.find(
                  answer => answer.formItem.id === formItem.id
                )

                switch (formItem.type) {
                  case FormItemType.SimpleText:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation ?? ''}
                        type={formItem.type}
                        answer={answer?.values[0] ?? ''}
                      />
                    )
                  case FormItemType.MultiSelect:
                  case FormItemType.Select:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation}
                        type={formItem.type}
                        answers={formItem.options.map(option => ({
                          id: option.id,
                          title: option.labelTranslations?.[0]?.translation ?? '',
                          active: answer?.values.includes(option.id) ?? false,
                        }))}
                      />
                    )
                  default:
                    return null
                }
              })}
            </Flex>
            <Flex gap='4px'>
              <Button
                type='text'
                size='small'
                onClick={() => {
                  confirmToggleVisibility(selectedSubmission)
                }}
                icon={<UntitledIcon icon={selectedSubmission.isHidden ? ulEye : ulEyeOff} />}
              >
                <FormattedMessage
                  {...(selectedSubmission.isHidden ? messages.show : messages.hide)}
                />
              </Button>
              <Button
                type='text'
                size='small'
                icon={<UntitledIcon icon={ulTrash01} />}
                danger
                onClick={() => {
                  modal.confirm({
                    type: 'warning',
                    title: formatMessage(messages.deleteSubmissionTitle),
                    content: formatMessage(messages.deleteSubmissionContent),
                    onOk: async () => {
                      await deleteSurveySubmission({
                        variables: {
                          input: {
                            motherId: getMotherId(),
                            channelId: channel.id,
                            submissionId: selectedSubmission.id,
                          },
                        },
                      })

                      setSelectedSubmissionId(null)

                      return
                    },
                  })
                }}
              >
                <FormattedMessage {...messages.delete} />
              </Button>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}
