import {
  type CountryCode as CountryCodeImport,
  getCountryCallingCode as getCountryCallingCodeImport,
  isPossiblePhoneNumber,
} from 'libphonenumber-js'
import { default as isBase64Validator } from 'validator/lib/isBase64'
import { default as isEmailValidator } from 'validator/lib/isEmail'
import { default as isHexColorValidator } from 'validator/lib/isHexColor'
import { default as isIPValidator } from 'validator/lib/isIP'
import { default as isLengthValidator } from 'validator/lib/isLength'
import { default as isNumericValidator } from 'validator/lib/isNumeric'
import { default as isStrongPasswordValidator } from 'validator/lib/isStrongPassword'
import { default as isURLValidator } from 'validator/lib/isURL'
import { default as isUUIDValidator } from 'validator/lib/isUUID'
import type { InferType } from 'yup'
import * as yup from 'yup'
import { ReportJustification } from './enums'
import { USER_PASSWORD_MAX_LENGTH, USER_PASSWORD_MIN_LENGTH } from './validationConfig'

export const isPasswordValid = (password: string) =>
  isStrongPassword(password) && password.length <= USER_PASSWORD_MAX_LENGTH

export const isStrongPassword = (password: string) =>
  isStrongPasswordValidator(password, {
    minLength: USER_PASSWORD_MIN_LENGTH,
    minNumbers: 1,
    minLowercase: 1,
    minUppercase: 1,
    minSymbols: 1,
  })

export const getPasswordStrengthScore = (password: string): number =>
  isStrongPasswordValidator(password, { returnScore: true }) as unknown as number

export const isEmail = (email: string) => isEmailValidator(email, { allow_utf8_local_part: false })

export const isUUIDv7 = (uuid: string) => isUUIDValidator(uuid, 7)

// base64 in the rough sense that it works with `atob`
// not checking url safety because motherIds used in urls don't currently correspond to that standard
export const isBase64 = (base64: string) => isBase64Validator(base64)

// https://libsodium.gitbook.io/doc/helpers#base64-encoding-decoding
// https://base64.guru/standards/base64url
// should correspond to libsodium's base64 url-safe no-padding output format
// this isn't base64 in the sense that it works with `atob`
export const isBase64UrlSafeNoPadding = (base64: string) =>
  isBase64Validator(base64, { urlSafe: true })

export const isUrl = (url: string) =>
  isURLValidator(url, {
    protocols: ['http', 'https'],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_port: false,
    require_valid_protocol: true,
    allow_underscores: false,
    allow_trailing_dot: false,
    allow_protocol_relative_urls: false,
    allow_fragments: true,
    allow_query_components: true,
    disallow_auth: false,
    validate_length: true,
  })

export const isNumeric = isNumericValidator
export const isHexColor = isHexColorValidator
export const isLength = isLengthValidator
export const isIP = isIPValidator

export const isCompanyEmail = async (email: string) => {
  const { isCompanyEmail: isCompanyEmailFn } = await import('free-email-domains-list')
  return isCompanyEmailFn(email)
}

// we are not as strict as we could be, we should use function `isValidPhoneNumber`
export const isValidPhoneNumber = (phoneNumber: string) => isPossiblePhoneNumber(phoneNumber)

// unduplicated exports
export type CountryCode = CountryCodeImport
export const getCountryCallingCode = getCountryCallingCodeImport

type PrevCurrValue = string | boolean | number | number[] | string[] | null | undefined

const editFormItemAnswerSchema = yup.object().shape({
  formItemId: yup.string().required(),
  value: yup.string().required().nullable(),
})

type EditFormItemAnswer = InferType<typeof editFormItemAnswerSchema>

export const isEditFormItemAnswerType = (value: unknown): value is EditFormItemAnswer => {
  return editFormItemAnswerSchema.isValidSync(value)
}

const editReportSchema = yup.object().shape({
  body: yup.string().required().nullable(),
  body_nonce: yup.string().required().nullable(),
  created_at: yup.string().required(),
  category_id: yup.number().required(),
  // these are not required for older audit logs
  organizational_unit_id: yup.string().nullable(),
  source: yup.string().nullable(),
  category_name: yup.string().nullable(),
  organizational_unit_name: yup.string().nullable(),
})

const editReportReturnTypeSchema = yup.object().shape({
  body: yup.string().required().nullable(),
  body_nonce: yup.string().required().nullable(),
  created_at: yup.string().required(),
  category_id: yup.string().required(),
  // these are not required for older audit logs
  organizational_unit_id: yup.string().nullable(),
  source: yup.string().nullable(),
  category_name: yup.string().nullable(),
  organizational_unit_name: yup.string().nullable(),
})

type EditReport = InferType<typeof editReportSchema>
type EditReportReturnType = InferType<typeof editReportReturnTypeSchema>

export const isEditReportType = (value: unknown): value is EditReport => {
  return editReportSchema.isValidSync(value)
}

export const isEditReportReturnType = (value: unknown): value is EditReportReturnType => {
  return editReportReturnTypeSchema.isValidSync(value)
}

const changedCategoryReturnSchema = yup.object().shape({
  category_id: yup.number().required(),
  category_name: yup.string(),
})

type ChangedCategoryReturn = InferType<typeof changedCategoryReturnSchema>

export const isChangedCategoryReturnType = (value: unknown): value is ChangedCategoryReturn => {
  return changedCategoryReturnSchema.isValidSync(value)
}

const internalCommentReturnSchema = yup.object().shape({
  ciphertext: yup.string().required().nullable(),
  nonce: yup.string().required(),
  attachments: yup
    .array(
      yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required(),
      })
    )
    .required(),
})

const internalCommentSchema = yup.object().shape({
  ciphertext: yup.string().required().nullable(),
  nonce: yup.string().required(),
  attachments: yup
    .array(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .required(),
})

type ChangedInternalComment = InferType<typeof internalCommentSchema>
export type ChangedInternalCommentReturn = InferType<typeof internalCommentReturnSchema>

export const isChangedInternalCommentType = (
  value: unknown
): value is ChangedInternalCommentReturn => {
  return internalCommentReturnSchema.isValidSync(value)
}

const deletedInternalCommentReturnSchema = internalCommentReturnSchema

type DeletedInternalComment = InferType<typeof deletedInternalCommentReturnSchema>

export const isDeletedInternalReturnCommentType = (
  value: unknown
): value is DeletedInternalComment => {
  return deletedInternalCommentReturnSchema.isValidSync(value)
}

enum ReportPriority {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
  Urgent = 'Urgent',
}

const reportPrioritySchema = yup
  .mixed<ReportPriority>()
  .oneOf(Object.values(ReportPriority))
  .required()

export const isReportPriorityType = (value: unknown): value is ReportPriority => {
  return reportPrioritySchema.isValidSync(value)
}

const reportJustificationSchema = yup
  .mixed<ReportJustification>()
  .oneOf(Object.values(ReportJustification))
  .required()

export const isReportJustificationType = (value: unknown): value is ReportJustification => {
  return reportJustificationSchema.isValidSync(value)
}

const auditLogJustificationReturnSchema = yup.object().shape({
  closingReason: yup.string().required().nullable(),
  closingCommentBody: yup.string().required().nullable(),
  closingCommentNonce: yup.string().required().nullable(),
})

export type AuditLogJustificationReturn = InferType<typeof auditLogJustificationReturnSchema>

export const isAuditLogJustificationReturnType = (
  value: unknown
): value is AuditLogJustificationReturn => {
  return auditLogJustificationReturnSchema.isValidSync(value)
}

export type PrevCurrPossibleValues =
  | EditFormItemAnswer
  | EditReport
  | PrevCurrValue
  | ChangedInternalComment
  | DeletedInternalComment
  | AuditLogJustificationReturn

export type PrevCurrPossibleValuesReturn =
  | EditFormItemAnswer
  | EditReportReturnType
  | PrevCurrValue
  | ChangedInternalCommentReturn
  | DeletedInternalComment
  | ChangedCategoryReturn
  | ReportPriority
  | ReportJustification
  | AuditLogJustificationReturn
