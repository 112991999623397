import { ulEdit05 } from '@faceup/icons/ulEdit05'
import type { RouteCallback } from '@faceup/router'
import { ContentLayout } from '@faceup/ui'
import { useDisclosure } from '@mantine/hooks'
import type { ComponentProps } from 'react'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { EditReportSourceNameModal } from '../../InstitutionCustomization/EditReportSourceNameModal'
import { SurveyNavigation } from './SurveyNavigation'

const fragments = {
  SurveyContentHeader_reportSource: graphql(`
    fragment SurveyContentHeader_reportSource on ReportSource {
      id
      name
      ...SurveyNavigationFragment_reportSource
    }
  `),
}

type Props = {
  survey: FragmentType<typeof fragments.SurveyContentHeader_reportSource>
  surveyNavigationType: ComponentProps<typeof SurveyNavigation>['variant']
  arrowGoBackTo: RouteCallback
}

export const SurveyContentHeader = ({
  survey: _survey,
  arrowGoBackTo,
  surveyNavigationType,
}: Props) => {
  const survey = getFragmentData(fragments.SurveyContentHeader_reportSource, _survey)
  const [isEditNameModalOpen, { toggle: toggleEditNameModal }] = useDisclosure()

  if (!survey) {
    return null
  }

  return (
    <>
      <ContentLayout.Header
        title={survey.name}
        arrowGoBackTo={arrowGoBackTo}
        action={{
          icon: ulEdit05,
          onAction: toggleEditNameModal,
        }}
        secondaryContent={<SurveyNavigation variant={surveyNavigationType} data={survey} />}
      />

      <EditReportSourceNameModal
        id={survey.id}
        name={survey.name}
        editModalOpen={isEditNameModalOpen}
        setEditModalOpen={toggleEditNameModal}
      />
    </>
  )
}
