import { useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { ulShare06 } from '@faceup/icons/ulShare06'
import { useMotherId } from '@faceup/institution'
import { useNavigate } from '@faceup/router'
import { usePaginator } from '@faceup/ui'
import { Button, Flex, Pagination, Table, Tag, Typography, notification } from '@faceup/ui-base'
import { ChannelStatus } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { useMemo, useState } from 'react'
import { channelStatusMessages, sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { graphql } from '../../../__generated__'
import { CreateSurveyModal } from './CreateSurveyModal'
import { PublishSurveyModal } from './PublishSurveyModal'
import { SurveyOptionsMenu } from './SurveyOptionsMenu'

const messages = defineMessages({
  nameLabel: 'Administration.surveys.table.name',
  numberOfAnswers: 'Administration.surveys.table.numberOfAnswers',
  shortLink: 'Administration.surveys.table.shortLink',
  createdAt: 'Administration.surveys.table.createdAt',
  updatedAt: 'Administration.surveys.table.updatedAt',
  status: 'Administration.surveys.table.status',
  viewDetails: 'Administration.surveys.options.viewDetails',
  duplicate: 'Administration.surveys.options.duplicate',
  deactivate: 'Administration.surveys.options.deactivate',
  activate: 'Administration.surveys.options.activate',
  delete: 'Administration.surveys.options.delete',
  confirmDelete: 'Administration.surveys.confirmDelete',
  downloadData: 'Administration.surveys.detail.downloadData',
  changeLanguage: 'Administration.surveys.detail.changeLanguage',
  emptyLabel: 'Administration.surveys.table.emptyLabel',
  emptyDescription: 'Administration.surveys.table.emptyDescription',
  createSurvey: 'Administration.surveys.createSurvey',
  totalItems: 'Administration.surveys.totalItems',
})

type Survey = NonNullable<ResultOf<typeof surveysTableQuery>['surveys']>['items'][number]

export const surveysTableQuery = graphql(`
  query SurveysTableQuery(
    $motherId: UUID!
    $name: GraphQLString
    $page: Int!
    $rowsPerPage: Int!
  ) {
    surveys(
      motherId: $motherId
      name: $name
      page: $page
      rowsPerPage: $rowsPerPage
    ) {
      totalCount
      items {
        id
        name
        shortLink
        isEnabled
        status
        createdByMember {
          id
          name
        }
        createdAt
        updatedAt
        ...SurveyOptionsMenu_reportSource
        ...PublishSurveyModalFragment
        config {
          ... on SurveyChannelConfiguration {
            id
            submissionsCount
          }
        }
      }
    }
  }
`)

export const SurveyTable = ({ name }: { name: string }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [survey, setSurvey] = useState<Survey | null>(null)
  const { page, rowsPerPage, setPage } = usePaginator()
  const { getMotherId } = useMotherId()
  const navigate = useNavigate()

  const { data, loading } = useQuery(surveysTableQuery, {
    variables: { motherId: getMotherId(), name, page, rowsPerPage },
    fetchPolicy: 'cache-and-network',
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const { formatMessage } = useIntl()

  const columns = [
    { key: 'name', title: formatMessage(messages.nameLabel), dataIndex: 'name' },
    {
      key: 'numberOfAnswers',
      title: formatMessage(messages.numberOfAnswers),
      dataIndex: 'numberOfAnswers',
    },
    { key: 'status', title: formatMessage(messages.status), dataIndex: 'status' },
    {
      key: 'options',
      title: '',
      dataIndex: 'options',
      width: 104,
    },
  ]

  const surveys = useMemo(() => data?.surveys?.items ?? [], [data?.surveys?.items])

  const dataSource = surveys.map(survey => {
    const createdAt = moment(survey.createdAt)
    const updatedAt = moment(survey.updatedAt)
    const showUpdatedAt = !createdAt.isSame(updatedAt, 'day')
    const config = survey?.config
    const id = survey?.id ?? ''
    const status = survey?.status
    const statusColors: Record<ChannelStatus, 'red' | 'green'> = {
      [ChannelStatus.Enabled]: 'green',
      [ChannelStatus.Disabled]: 'red',
    }

    return {
      name: (
        <div className='flex flex-col'>
          <Typography.Text strong>{survey?.name}</Typography.Text>
          <Typography.Text>
            {`${formatMessage(messages.createdAt)}: ${createdAt.format('LL')}`}
            {showUpdatedAt && `, ${formatMessage(messages.updatedAt)}: ${updatedAt.format('LL')}`}
          </Typography.Text>
        </div>
      ),
      numberOfAnswers:
        config?.__typename === 'SurveyChannelConfiguration' ? (
          <Flex align='center' gap={16}>
            <div style={{ minWidth: '16px' }}>{config?.submissionsCount}</div>
            <Button
              onClick={event => {
                event.stopPropagation()
                return navigate(routes => routes.surveySubmissionsStatistics({ id }))
              }}
              type='text'
              icon={
                <UntitledIcon
                  color={config?.submissionsCount === 0 ? '#92B4DB' : '#062D46'}
                  icon={ulEye}
                />
              }
              disabled={config?.submissionsCount === 0}
              size='middle'
            />
          </Flex>
        ) : (
          ''
        ),
      status: status ? (
        <Tag color={statusColors[status]} bordered={false}>
          <FormattedMessage {...channelStatusMessages[status]} />
        </Tag>
      ) : (
        ''
      ),
      options: survey && (
        <Flex gap={8}>
          <Button
            onClick={event => {
              event.stopPropagation()
              setSurvey(survey)
            }}
            type='text'
            icon={<UntitledIcon icon={ulShare06} />}
            size='middle'
          />
          <Flex align='center' onClick={e => e.stopPropagation()}>
            <SurveyOptionsMenu
              survey={survey}
              optionsToDisplay={[
                'viewDetails',
                'duplicate',
                'deactivate',
                'sharedWithEmployees',
                'delete',
              ]}
            />
          </Flex>
        </Flex>
      ),
      id: survey?.id ?? '',
    }
  })

  return (
    <div className='flex flex-col gap-24px'>
      <Table
        shadow
        dataSource={dataSource}
        columns={columns}
        loading={loading}
        pagination={false}
        onRow={record => ({
          onClick: () => {
            if (record.id) {
              navigate(routes => routes.surveyDetail({ id: record.id }))
            }
          },
          style: { cursor: 'pointer' },
        })}
      />
      {(data?.surveys?.totalCount || 0) > 10 && (
        <div className='flex justify-between'>
          <Typography.Text type='secondary' strong>
            <FormattedMessage {...messages.totalItems} values={{ count: surveys.length }} />
          </Typography.Text>
          <Pagination
            total={data?.surveys?.totalCount}
            current={page + 1}
            onChange={newPage => setPage(newPage - 1)}
            pageSize={rowsPerPage}
          />
        </div>
      )}
      <CreateSurveyModal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {survey && (
        <PublishSurveyModal mode='share' survey={survey} onClose={() => setSurvey(null)} />
      )}
    </div>
  )
}
