import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { Divider, Empty, Typography } from '@faceup/ui-base'
import { ReportCreationOrigin, omitNullInArray } from '@faceup/utils'
import { useMantineTheme } from '@mantine/core'
import { useDocumentVisibility } from '@mantine/hooks'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { FormattedMessage as FormattedMessageDef } from 'react-intl'
import { defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import FollowUpChatMessage from './FollowUpChatMessage'
import { RelativeMessagesContext } from './RelativeMessagesContext'
import { ucEmptyChat } from './customIcons/ucEmptyChat'

const { Text } = Typography

const messages = defineMessages({
  newMessage: 'Report.chat.newMessage',
})

const fragments = {
  CompanyChat_report: graphql(`
    fragment CompanyChat_report on CompanyReport {
      status
      isDemo
      creationOrigin
      victim {
        id
      }
      followUpActivities(page: 0, rowsPerPage: 10000) {
        items {
          id
          author {
            ... on CompanyVictim {
              id
            }

            ... on Member {
              id
            }
          }
          ...FollowUpChatMessage_followUpActivityNode
        }
      }
    }
  `),
}

type Props = {
  report: FragmentType<typeof fragments.CompanyChat_report> | null
  subscribeToNewMessages?: () => (() => void) | null
  onMessageSeen?: () => void
  userId: string
  chatRef: React.RefObject<HTMLDivElement>
  fileDownloadHandler?: (blob: Blob, name: string) => Promise<void>
  firstUnreadMessageId?: string
}

const Chat = ({
  report: _report,
  userId,
  fileDownloadHandler,
  onMessageSeen,
  chatRef,
  firstUnreadMessageId,
}: Props) => {
  const report = getFragmentData(fragments.CompanyChat_report, _report)
  const theme = useMantineTheme()
  const relativeMessages = useContext(RelativeMessagesContext)
  const followUpActivities = report?.followUpActivities?.items ?? []
  const scrollTargetRef = useRef<HTMLDivElement | null>(null)

  const documentState = useDocumentVisibility()

  const scrollToNewestMessage = useCallback(() => {
    scrollTargetRef.current?.scrollIntoView({
      // for some reason, 'smooth' behavior was erratic (would scroll on some occasions and not on others)
      behavior: 'auto',
      // nearest will only scroll inner container; "center" will scroll the whole page
      block: 'nearest',
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (scrollTargetRef.current) {
        scrollToNewestMessage()
      } else {
        if (chatRef?.current) {
          chatRef.current.scrollTop = chatRef?.current?.scrollHeight || 0
        }
      }
    }, 700)
  }, [chatRef.current, scrollToNewestMessage])

  // biome-ignore lint/correctness/useExhaustiveDependencies(scrollToNewestMessage): will make useEffect run on every render
  useEffect(() => {
    setTimeout(() => {
      if (scrollTargetRef.current && documentState === 'visible') {
        scrollToNewestMessage()
      }
    }, 700)
  }, [documentState])

  return (
    <>
      {followUpActivities.length === 0 && (
        <EmptyWrapper>
          <Empty
            image={
              <UntitledIcon
                icon={ucEmptyChat}
                fontSize={100}
                color={theme.colors['primary']?.[7]}
              />
            }
            description={
              <Text style={{ color: '#688699' }}>
                <FormattedMessageDef {...relativeMessages.empty} />
              </Text>
            }
          />
        </EmptyWrapper>
      )}
      {((report?.creationOrigin &&
        [ReportCreationOrigin.Reporter, ReportCreationOrigin.Redaction].includes(
          report.creationOrigin
        )) ||
        report?.isDemo) &&
        omitNullInArray(followUpActivities).map(node => (
          <>
            {node?.id === firstUnreadMessageId && (
              <div ref={scrollTargetRef} style={{ marginInlineEnd: '12px' }}>
                <NewMessageDivider />
              </div>
            )}
            <FollowUpChatMessage
              onMessageSeen={onMessageSeen}
              orientation={node?.author?.id === userId ? 'right' : 'left'}
              key={node?.id}
              message={node}
              fileDownloadHandler={fileDownloadHandler}
            />
          </>
        ))}
    </>
  )
}

export const NewMessageDivider = ({ style }: { style?: React.CSSProperties }) => {
  return (
    <Divider
      // keeping `style` so I don't have to override styles with !important
      style={{ ...style, borderColor: '#E42457', color: '#E42457', fontSize: '12px' }}
      orientation='right'
      orientationMargin='0'
    >
      <FormattedMessageDef {...messages.newMessage} />
    </Divider>
  )
}

const EmptyWrapper = styled.div`
  height: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Chat
