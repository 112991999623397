import { UntitledIcon } from '@faceup/icons'
import { type ReactNode, useCallback, useMemo } from 'react'
import { App, type MessageArgsProps, type MessageStatusType, statusIcons } from './index'

type JointContent = React.ReactNode | MessageArgsProps

const isMessageArgsProps = (content: JointContent): content is MessageArgsProps => {
  return typeof content === 'object' && content !== null && 'content' in content
}

type MessageFunction = {
  (content: ReactNode, duration?: number, onClose?: VoidFunction): void
  (content: MessageArgsProps): void
}

export const useMessage = (): Record<MessageStatusType, MessageFunction> => {
  const app = App.useApp()

  const generateMessage = useCallback(
    (
      type: MessageStatusType,
      content: JointContent,
      icon?: ReactNode,
      duration?: number,
      onClose?: VoidFunction
    ) => {
      if (isMessageArgsProps(content)) {
        return app.message[type]({
          icon,
          ...content,
        })
      } else {
        return app.message[type]({
          content,
          icon,
          duration,
          onClose,
        })
      }
    },
    [app.message]
  )

  const createMessageFunction = useCallback(
    (type: MessageStatusType, icon?: ReactNode): MessageFunction => {
      return (content: JointContent, duration?: number, onClose?: VoidFunction) => {
        generateMessage(type, content, icon, duration, onClose)
      }
    },
    [generateMessage]
  )

  return useMemo(
    () => ({
      success: createMessageFunction(
        'success',
        <UntitledIcon icon={statusIcons.success} color='#32C27B' />
      ),
      info: createMessageFunction('info', <UntitledIcon icon={statusIcons.info} color='#1890FF' />),
      warning: createMessageFunction(
        'warning',
        <UntitledIcon icon={statusIcons.warning} color='#FA914B' />
      ),
      error: createMessageFunction(
        'error',
        <UntitledIcon icon={statusIcons.error} color='#FF4D4F' />
      ),
      loading: createMessageFunction('loading'),
    }),
    [createMessageFunction]
  )
}
