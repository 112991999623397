import { useMutation } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { notification } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import type { ReactNode } from 'react'
import { sharedMessages } from '../../../../../../Shared/translations'
import { useIntl } from '../../../../../../TypedIntl'
import { graphql } from '../../../../../../__generated__'
import { AbstractSurveyFormItem } from './AbstractSurveyFormItem'

const mutations = {
  CreateSurveyItem: graphql(`
    mutation CreateSurveyItem($input: CreateSurveyItemInput!) {
      createSurveyItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
  CreateSurveySelectItem: graphql(`
    mutation CreateSurveySelectItem($input: CreateSurveySelectItemInput!) {
      createSurveySelectItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
}

type CreateSurveyFormItemProps = {
  reportSourceId: string
  type: FormItemType
  order: number
  onClose: () => void
  onSuccess: () => void
}

export const CreateSurveyFormItem = ({
  type,
  onClose,
  reportSourceId,
  onSuccess,
  order,
}: CreateSurveyFormItemProps) => {
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const [createSurveyItem] = useMutation(mutations.CreateSurveyItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })
  const [createSurveySelectItem] = useMutation(mutations.CreateSurveySelectItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })
  const items: Record<FormItemType, () => ReactNode> = {
    [FormItemType.MultilineText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultilineText}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.Select]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Select}
        variant='create'
        defaultValues={{
          question: '',
          responses: [
            {
              id: 'new-1',
              label: '',
            },
            {
              id: 'new-2',
              label: '',
            },
          ],
        }}
        onClose={onClose}
        opened
        onSubmit={async values => {
          const result = await createSurveySelectItem({
            variables: {
              input: {
                channelId: reportSourceId,
                motherId: getMotherId(),
                options: values.responses.map((response, order) => ({
                  label: response.label,
                  order,
                })),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  type: FormItemType.Select,
                },
                order,
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MultiSelect]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultiSelect}
        variant='create'
        defaultValues={{
          question: '',
          responses: [
            {
              id: 'new-1',
              label: '',
            },
            {
              id: 'new-2',
              label: '',
            },
          ],
        }}
        onClose={onClose}
        opened
        onSubmit={async values => {
          const result = await createSurveySelectItem({
            variables: {
              input: {
                channelId: reportSourceId,
                motherId: getMotherId(),
                options: values.responses.map((response, order) => ({
                  label: response.label,
                  order,
                })),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  type: FormItemType.MultiSelect,
                  maxResponses: values.showLimits ? values.maxResponses : null,
                  minResponses: values.showLimits ? values.minResponses : null,
                },
                order,
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MoreInformation]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MoreInformation}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.Category]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Category}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.OrganizationalUnit]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.OrganizationalUnit}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.SenderName]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SenderName}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.Classroom]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Classroom}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.SimpleText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SimpleText}
        variant='create'
        defaultValues={{
          question: '',
          maxLength: 500,
        }}
        onClose={onClose}
        opened
        onSubmit={async values => {
          const result = await createSurveyItem({
            variables: {
              input: {
                channelId: reportSourceId,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  type: FormItemType.SimpleText,
                  maxLength: values.maxLength,
                },
                order,
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.Date]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Date}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.Email]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Email}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
    [FormItemType.PhoneNumber]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.PhoneNumber}
        variant='create'
        defaultValues={{}}
        onClose={onClose}
        opened
        onSubmit={() => true}
      />
    ),
  }

  return items[type]()
}
