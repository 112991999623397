import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { ModalsContext } from '../Contexts/ModalsContext'
import { graphql } from '../__generated__'

const HUBSPOT_ID = 'hubspot-messages-loader'
const HUBSPOT_CHAT_URL = 'https://js-eu1.usemessages.com/conversations-embed.js'
const HUBSPOT_PORTAL_ID = '26763534'

const mutations = {
  RetrieveHubSpotIdenfiticationToken: graphql(`
    mutation RetrieveHubSpotIdenfiticationToken {
      retrieveHubSpotIdentificationToken {
        token
        email
      }
    }
  `),
}

const useHubSpotChat = () => {
  const { setIsChatVisible, setChatUnreadMessages, setIsChatLoading } = useContext(ModalsContext)
  const [retrieveToken] = useMutation(mutations.RetrieveHubSpotIdenfiticationToken, {
    onCompleted: data => {
      const { token, email } = data?.retrieveHubSpotIdentificationToken ?? {}
      initForm(email ?? '', token ?? '')
    },
  })

  const initForm = (email: string, token: string) => {
    window.hsConversationsSettings = {
      loadImmediately: true,
      inlineEmbedSelector: '#hubspot-chat',
      identificationEmail: email,
      identificationToken: token,
    }

    const script = document.createElement('script')
    script.src = HUBSPOT_CHAT_URL
    script.id = HUBSPOT_ID
    script.setAttribute('data-loader', 'hs-scriptloader')
    script.setAttribute('data-hsjs-portal', HUBSPOT_PORTAL_ID)
    script.setAttribute('data-hsjs-env', 'prod')
    script.setAttribute('data-hsjs-hublet', 'eu1')

    document.body.appendChild(script)

    const onConversationsAPIReady = () => {
      window.HubSpotConversations?.on('unreadConversationCountChanged', payload =>
        setChatUnreadMessages(Number(payload?.['unreadCount']))
      )
      setIsChatLoading(false)
      window.HubSpotConversations?.widget?.open?.()
    }

    if (window.HubSpotConversations) {
      onConversationsAPIReady()
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady]
    }

    window.HubSpotConversations?.widget?.load?.()
  }

  const load = async () => {
    setIsChatVisible(true)
    window?.productFruits?.api?.inAppCenter?.close()

    const existingScript = document?.getElementById(HUBSPOT_ID)

    if (!existingScript) {
      retrieveToken()
    } else {
      window.HubSpotConversations?.widget?.open?.()
      setIsChatLoading(false)
    }
  }

  return { load }
}

export default useHubSpotChat
