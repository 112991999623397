import styled from '@emotion/styled'
import { Segmented } from '@faceup/ui-base'
import type { BillingFrequency as GlobalBillingFrequency } from '@faceup/utils'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'

const messages = defineMessages({
  monthly: 'Administration.companyInvoicing.billingPeriodSwitcher.monthly',
  annually: 'Administration.companyInvoicing.billingPeriodSwitcher.annually',
  quarterly: 'Administration.companyInvoicing.billingPeriodSwitcher.quarterly',
  saveUpTo: 'Administration.companyInvoicing.billingPeriodSwitcher.saveUpTo',
})

export type BillingFrequency = Lowercase<GlobalBillingFrequency>

export type BillingPeriodSwitcherProps = {
  frequency: BillingFrequency
  onChange: (frequency: BillingFrequency) => void
  disabled?: boolean
  initialFrequency?: BillingFrequency
}

const BillingPeriodSwitcher = ({
  frequency,
  onChange,
  disabled,
  initialFrequency,
}: BillingPeriodSwitcherProps) => (
  <OuterWrapper>
    <InnerWrapper>
      <Switcher
        value={frequency}
        onChange={value => onChange(value as BillingFrequency)}
        disabled={disabled}
        options={[
          ...(initialFrequency === 'quarterly'
            ? [
                {
                  label: <FormattedMessage {...messages.quarterly} />,
                  value: 'quarterly',
                },
              ]
            : [
                {
                  label: <FormattedMessage {...messages.monthly} />,
                  value: 'monthly',
                },
              ]),
          {
            label: <FormattedMessage {...messages.annually} />,
            value: 'yearly',
          },
        ]}
      />
      <SaveUpToWrapper>
        <SaveUpTo>
          <FormattedMessage {...messages.saveUpTo} />
        </SaveUpTo>
      </SaveUpToWrapper>
    </InnerWrapper>
  </OuterWrapper>
)

const OuterWrapper = styled.div`
  display: inline-block;
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Switcher = styled(Segmented)`
  &.ant-segmented,
  &.ant-segmented:not(.ant-segmented-disabled):hover,
  &.ant-segmented:not(.ant-segmented-disabled):focus {
    background-color: unset;
  }

  .ant-segmented-thumb {
    background-color: #062d46;
    margin-top: 3px;
    height: 40px;
  }

  .ant-segmented-group {
    border: 1px solid #e6eaec;
    background: #f5f7f8;
    border-radius: 12px;
    padding: 3px;
  }
  .ant-segmented-item {
    color: #5b7d93;
    font-weight: 600;
    font-size: 14px;
    padding: 6px 10px;
    border-radius: 8px;
  }
  .ant-segmented-item-selected {
    background-color: #062d46;
    color: #ffffff;
  }
`

const SaveUpToWrapper = styled.div`
  position: absolute;
  top: -16px;
  pointer-events: none;
`

const SaveUpTo = styled.div`
  background-color: #2eb271;
  border-radius: 10px;
  color: #ffffff;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  display: inline-block;
  padding: 1px 7px;
  margin-inline: -5px;
`

export default BillingPeriodSwitcher
