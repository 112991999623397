import {
  ChargebeeSubscriptionStatus,
  Feature,
  FrontendPermissionType,
  Institution,
} from '@faceup/utils'
import { useContext } from 'react'
import { UserContext, useUser } from '../providers'
import { useGlobalInfo } from './useGlobalInfo'
import { usePartner } from './usePartner'

export type UseAccessRightsReturn = {
  isAllowedAdvancedCustomization: boolean
  isAllowedSettingsAccess: boolean
  isAllowedSSO: boolean
  isAllowedWebhooks: boolean
  isAllowedApi: boolean
  isVisibleApi: boolean
  isVisibleWhitelabel: boolean
  isAllowedWhitelabel: boolean
  isVisibleEnableAccessKey: boolean
  isAllowedEnableAccessKey: boolean
  isAllowedE2EE: boolean
  areReportsNeedingAttentionVisible: boolean
  areVisibleWebhooks: boolean
  isBioVisible: boolean
  isAllowedMultipleReportSources: boolean
  isVisiblePdfPasswordAlert: boolean
  isAllowedRedaction: boolean
  isAllowedStatistics: boolean
  isAllowedSurveys: boolean
  isAllowedCustomizationEdit: boolean
  isVisibleForPermission: Record<FrontendPermissionType, boolean>
  isPartnerAdministration: boolean
  mothersWithPermission: Record<FrontendPermissionType, string[]>
  isAllowedIntegration: boolean
  isAllowedReportsBadge: boolean
  isAllowedCreateHotline: boolean
  isAllowedCreateLiveHotline: boolean
  areReportAttachmentsVisible: boolean
  areVisibleDataManagementEmployees: boolean
  areVisibleReportEmployees: boolean
}

type FnUseAccessRights = () => UseAccessRightsReturn

export const useAccessRights: FnUseAccessRights = () => {
  const { usePaidVersion, institution, entitlements, subscriptionStatus, integratedApps } =
    useGlobalInfo()
  const { hasPermissions } = usePermissions()
  const { isPartnerAdministration } = usePartner()
  const { viewer } = useContext(UserContext)

  const isActive = [
    ChargebeeSubscriptionStatus.Active,
    ChargebeeSubscriptionStatus.NonRenewing,
  ].includes(subscriptionStatus ?? ChargebeeSubscriptionStatus.Cancelled)

  /*
   * isAllowed - has access to item (e.g. to persist data) - to show buy higher pricing plan info
   * isVisible - is item visible or fully hidden in page
   */
  const isSchool = institution === Institution.School
  const isFreeOrNotActiveInstitution = !usePaidVersion || !isActive

  const isVisibleForPermission = Object.values(FrontendPermissionType).reduce(
    (acc, permission) => ({
      ...acc,
      [permission]: hasPermissions(permission),
    }),
    {} as Record<FrontendPermissionType, boolean>
  )

  const isEntitlementEnabled = (feature: Feature) =>
    entitlements.find(entitlement => entitlement.id === feature)?.isEnabled ?? false

  const organizationalUnitsWithAccess = new Set(viewer?.partnerCompanyIds)
  const mothersWithPermission = Object.values(FrontendPermissionType).reduce(
    (acc, permission) => ({
      ...acc,
      [permission]:
        viewer?.keys.partnerPermissions
          ?.filter(
            partnerPermission => partnerPermission.type === permission && partnerPermission.enabled
          )
          .map(permission => permission.motherId)
          .filter(id => id && organizationalUnitsWithAccess.has(id)) ?? [],
    }),
    {} as Record<FrontendPermissionType, string[]>
  )

  return {
    // Instead of changing all the places where this property is used I assume that partner is always allowed to
    // edit advanced customization for partner config (motherId does not exist). For institution config the same
    // rules as for other users still apply.
    isAllowedAdvancedCustomization: Boolean(usePaidVersion),
    isAllowedSettingsAccess: isVisibleForPermission.SettingsAccess,
    isAllowedSSO: isEntitlementEnabled(Feature.Sso),
    isAllowedWebhooks: isEntitlementEnabled(Feature.Webhooks),
    isAllowedApi: isEntitlementEnabled(Feature.Api),
    isVisibleApi: isVisibleForPermission.SettingsAccess && !(isSchool && !usePaidVersion),
    isVisibleWhitelabel: !usePaidVersion && isVisibleForPermission.SettingsAccess,
    isAllowedWhitelabel: isEntitlementEnabled(Feature.Whitelabel),
    isVisibleEnableAccessKey: isSchool,
    isAllowedEnableAccessKey: Boolean(usePaidVersion),
    isAllowedE2EE: Boolean(usePaidVersion),
    areReportsNeedingAttentionVisible:
      Boolean(usePaidVersion) && isVisibleForPermission.ReportAccess,
    areVisibleWebhooks: isVisibleForPermission.SettingsAccess && !(isSchool && !usePaidVersion),
    isBioVisible: Boolean(usePaidVersion),
    isAllowedMultipleReportSources: Boolean(usePaidVersion) && isActive,
    isVisiblePdfPasswordAlert: !usePaidVersion,
    isAllowedRedaction: Boolean(usePaidVersion),
    isAllowedStatistics: isVisibleForPermission.Analytics && Boolean(usePaidVersion),
    isAllowedSurveys: isVisibleForPermission.Surveys && Boolean(usePaidVersion),
    isAllowedCustomizationEdit:
      Boolean(usePaidVersion) && isVisibleForPermission.ManageReportingChannels,
    isVisibleForPermission,
    isPartnerAdministration,
    mothersWithPermission,
    isAllowedIntegration:
      isEntitlementEnabled(Feature.Integrations) && isVisibleForPermission.SettingsAccess,
    isAllowedReportsBadge: Boolean(usePaidVersion),
    isAllowedCreateHotline: !isFreeOrNotActiveInstitution,
    isAllowedCreateLiveHotline: !isFreeOrNotActiveInstitution,
    areReportAttachmentsVisible: Boolean(usePaidVersion),
    areVisibleDataManagementEmployees: Boolean(integratedApps?.isAnyHrIntegrationEnabled),
    areVisibleReportEmployees: Boolean(integratedApps?.isAnyHrIntegrationEnabled),
  }
}

const usePermissions = () => {
  const { permissions } = useGlobalInfo()
  const user = useUser()

  const hasPermissions = (...requiredPermissions: FrontendPermissionType[]) => {
    if (user.application === 'kredenc') {
      return true
    }

    const enabledPermissionTypes = permissions
      .filter(permission => permission?.enabled)
      .map(permission => permission?.type)

    return requiredPermissions.every(requiredPermission =>
      enabledPermissionTypes.includes(requiredPermission)
    )
  }

  return {
    hasPermissions,
  }
}
