import { useQuery } from '@apollo/client'
import type { Country } from '@faceup/utils'
import { type ReactNode, createContext } from 'react'
import { graphql } from '../__generated__'

const query = {
  GeoData: graphql(`
    query GeoData {
      userLocationByGeoIp {
        callingCode
        country
      }
    }
  `),
}

type GeoDataContextProps = {
  loading: boolean
  callingCode: string | null
  country: Country | null
}

type GeoDataProviderProps = { children: ReactNode }

export const GeoDataContext = createContext<GeoDataContextProps>({
  loading: true,
  callingCode: null,
  country: null,
})

const GeoDataProvider = ({ children }: GeoDataProviderProps) => {
  const { data, loading } = useQuery(query.GeoData)

  const userLocationByGeoIp = data?.userLocationByGeoIp
  const callingCode = userLocationByGeoIp?.callingCode ?? null
  const country = userLocationByGeoIp?.country ?? null

  return (
    <GeoDataContext.Provider
      value={{
        callingCode,
        country,
        loading,
      }}
    >
      {children}
    </GeoDataContext.Provider>
  )
}

export default GeoDataProvider
