import {
  type Day,
  type FrontendPermissionType,
  ReportDeadlineFilter,
  ReportJustification,
  ReportPriority,
  ReportSourceType,
  type ReportStatus,
} from '@faceup/utils'
import { type DefineMessagesType, defineMessages } from '../TypedIntl'

export const sharedMessages = defineMessages({
  functionNotReady: 'Administration.functionNotReady',
  apiError: 'Shared.global.apiError',
  savedMessage: 'Administration.global.saved',
  encryptionError: 'Shared.global.encryptionError',

  attachmentsTooManyFiles: 'Shared.attachments.tooManyFiles',
  attachmentsTooLargeFile: 'Shared.attachments.tooLargeFile',
  attachmentsLocked: 'Administration.attachments.lockedFeature',
  metadataRemoved: 'Shared.attachments.metadataRemoved',
  metadataUnableToRemove: 'Shared.attachments.metadataUnableToRemove',
  unsupportedMimetype: 'Shared.attachments.unsupportedMimetype',

  upgradeAlert: 'Administration.upgrade.alert',
  upgradeButton: 'Administration.upgrade.button',

  permissionEditableOnlyMotherTooltip: 'Administration.permissions.editableOnlyMother.tooltip',

  // errors
  invalidNameError: 'Shared.global.invalidInput.name',
  invalidEmailError: 'Administration.global.invalidInput.email',
  invalidPhoneError: 'Administration.global.invalidInput.phone',
  invalidPasswordError: 'Administration.global.invalidInput.password',
  invalidAddressError: 'Administration.global.invalidInput.address',
  passwordsDontMatchError: 'Administration.global.passwordsDontMatchError',
  invalidInputError: 'Shared.global.invalidInput',

  // labels
  emailLabel: 'Administration.label.email',
  nameLabel: 'Administration.label.name',
  bioLabel: 'Administration.label.bio',
  fullNameLabel: 'Administration.label.nameFull',
  phoneLabel: 'Administration.label.phone',
  passwordLabel: 'Administration.label.password',
  organizationLabel: 'Shared.report.organization',
  categoryLabel: 'Shared.report.category',
  moreInfoLabel: 'Shared.report.moreInfo',
  classNameLabel: 'Shared.report.classRoom',
  globalNameLabel: 'Shared.global.name',

  bio: 'Shared.bio',
  partnerBio: 'Shared.bio.partner',

  // actions
  send: 'Shared.action.send',
  delete: 'Shared.action.delete',
  save: 'Shared.action.save',
  next: 'Administration.action.next',
  enable: 'Administration.action.enable',
  disable: 'Administration.action.disable',
  edit: 'Administration.action.edit',
  copy: 'Shared.action.copy',
  print: 'Administration.action.print',
  show: 'Administration.action.show',
  download: 'Administration.action.download',
  backHome: 'Administration.action.backHome',
  add: 'Administration.action.add',
  removeFilter: 'Administration.action.removeFilter',
  back: 'Administration.action.back',
  reset: 'Shared.action.reset',
  ok: 'Shared.ok',
  cancel: 'Shared.cancel',
  sendAnonymously: 'Shared.report.sendAnonymously',

  copied: 'Shared.global.copied',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
})

export const reportStatusMessages = defineMessages<ReportStatus>({
  Open: 'Shared.report.status.Open',
  Closed: 'Shared.report.status.Closed',
  Archived: 'Shared.report.status.Archived',
})

export const reportSourceMessages = defineMessages<ReportSourceType>({
  Email: 'Administration.report.source.Email',
  Phone: 'Administration.report.source.Phone',
  InPerson: 'Administration.report.source.InPerson',
  Other: 'Administration.report.source.Other',
  ReportingSystem: 'Administration.report.source.ReportingSystem',
  AutomatedHotline: 'Administration.report.source.AutomatedHotline',
  LiveHotline: 'Administration.report.source.LiveHotline',
  Survey: 'Administration.report.source.Survey',
})

export const reportPrioritiesMessages = defineMessages<ReportPriority>({
  [ReportPriority.Urgent]: 'Administration.priority.urgent',
  [ReportPriority.High]: 'Administration.priority.high',
  [ReportPriority.Medium]: 'Administration.priority.medium',
  [ReportPriority.Low]: 'Administration.priority.low',
})

export const reportDeadlineFilterMessages = defineMessages<ReportDeadlineFilter>({
  [ReportDeadlineFilter.Today]: 'Administration.deadline.today',
  [ReportDeadlineFilter.Soon]: 'Administration.deadline.soon',
  [ReportDeadlineFilter.Later]: 'Administration.deadline.later',
  [ReportDeadlineFilter.Overdue]: 'Administration.deadline.overdue',
})

export const dayMessages = defineMessages<Day>({
  Monday: 'Administration.day.Monday',
  Tuesday: 'Administration.day.Tuesday',
  Wednesday: 'Administration.day.Wednesday',
  Thursday: 'Administration.day.Thursday',
  Friday: 'Administration.day.Friday',
  Saturday: 'Administration.day.Saturday',
  Sunday: 'Administration.day.Sunday',
})

export const reportSourcesMessages = defineMessages<ReportSourceType>({
  [ReportSourceType.Email]: 'Administration.reportSource.email',
  [ReportSourceType.AutomatedHotline]: 'Administration.reportSource.automatedHotline',
  [ReportSourceType.LiveHotline]: 'Administration.reportSource.liveHotline',
  [ReportSourceType.InPerson]: 'Administration.reportSource.inPerson',
  [ReportSourceType.ReportingSystem]: 'Administration.reportSource.reportingSystem',
  [ReportSourceType.Phone]: 'Administration.reportSource.phone',
  [ReportSourceType.Other]: 'Administration.reportSource.other',
  [ReportSourceType.Survey]: 'Administration.reportSource.survey',
})

export const reportJustificationMessages = defineMessages<ReportJustification>({
  [ReportJustification.Unknown]: 'Administration.reportJustification.unknown',
  [ReportJustification.Justified]: 'Administration.reportJustification.justified',
  [ReportJustification.Unjustified]: 'Administration.reportJustification.unjustified',
})

export const permissionTypeMessages: Record<
  FrontendPermissionType,
  DefineMessagesType<'title'> | DefineMessagesType<'title' | 'hint'>
> = {
  ReportAccess: defineMessages({
    title: 'Administration.settings.permissions.ReportAccess.title',
    hint: 'Administration.settings.permissions.ReportAccess.hint',
  }),
  SettingsAccess: defineMessages({
    title: 'Administration.settings.permissions.SettingsAccess.title',
    hint: 'Administration.settings.permissions.SettingsAccess.hint',
  }),
  BillingAccess: defineMessages({
    title: 'Administration.settings.permissions.BillingAccess.title',
    hint: 'Administration.settings.permissions.BillingAccess.description',
  }),
  ManageCategories: defineMessages({
    title: 'Administration.settings.permissions.ManageCategories.title',
    hint: 'Administration.settings.permissions.ManageCategories.description',
  }),
  ManageOrganizationalUnits: defineMessages({
    title: 'Administration.settings.permissions.ManageOrganizationalUnits.title',
    hint: 'Administration.settings.permissions.ManageOrganizationalUnits.description',
  }),
  ManageReportingChannels: defineMessages({
    title: 'Administration.settings.permissions.ManageReportingChannels.title',
    hint: 'Administration.settings.permissions.ManageReportingChannels.description',
  }),
  ManageUsers: defineMessages({
    title: 'Administration.settings.permissions.ManageUsers.title',
    hint: 'Administration.settings.permissions.ManageUsers.description',
  }),
  Analytics: defineMessages({ title: 'Administration.settings.permissions.Analytics.title' }),
  EditCases: defineMessages({
    title: 'Administration.settings.permissions.EditCases.title',
    hint: 'Administration.settings.permissions.EditCases.description',
  }),
  ExportCases: defineMessages({
    title: 'Administration.settings.permissions.ExportCases.title',
    hint: 'Administration.settings.permissions.ExportCases.description',
  }),
  DeleteCases: defineMessages({
    title: 'Administration.settings.permissions.DeleteCases.title',
    hint: 'Administration.settings.permissions.DeleteCases.description',
  }),
  WriteInternalComments: defineMessages({
    title: 'Administration.settings.permissions.WriteInternalComments.title',
    hint: 'Administration.settings.permissions.WriteInternalComments.description',
  }),
  WriteMessages: defineMessages({
    title: 'Administration.settings.permissions.WriteMessages.title',
    hint: 'Administration.settings.permissions.WriteMessages.description',
  }),
  Surveys: defineMessages({ title: 'Administration.settings.permissions.Surveys.title' }),
}
