import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { createComment } from '@faceup/crypto'
import { sharedMessages } from '@faceup/localization'
import { type Attachment, Button, Prompt } from '@faceup/ui'
import { Alert, Checkbox, Form, notification } from '@faceup/ui-base'
import { Plan, ReportCreationOrigin, ReportStatus } from '@faceup/utils'
import { type ComponentProps, type ReactElement, type ReactNode, useContext, useState } from 'react'
import {
  FormattedMessage,
  FormattedMessage as FormattedMessageDef,
  useIntl,
  useIntl as useIntlDef,
} from 'react-intl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import type { ChatInput } from '../ChatInput'
import { ChatPopupAlertType, PopupsContext } from './PopupsContext'
import { RelativeMessagesContext } from './RelativeMessagesContext'

const mutations = {
  SendMessageMutation: graphql(`
    mutation SendMessageMutation(
      $input: CreateCompanyReportFollowUpCommentInput!
      $isVictim: Boolean!
    ) {
      createCompanyReportFollowUpComment(input: $input) {
        createdCompanyReportComment {
          id
          report {
            id
            lastChangeDate
            assignedMembers @skip(if: $isVictim) {
              id
            }
          }
        }
      }
    }
  `),

  EditCompanyReportNameVisibility: graphql(`
    mutation EditCompanyReportNameVisibilityMutation(
      $input: EditCompanyReportNameVisibilityInput!
    ) {
      editCompanyReportNameVisibility(input: $input) {
        report {
          id
          isNameHidden
        }
      }
    }
  `),
}

export const fragments = {
  CompanyChatForm_report: graphql(`
    fragment CompanyChatForm_report on CompanyReport {
      id
      isDemo
      isNameHidden
      status
      encryptionKey
      creationOrigin
      company {
        id
        mother {
          id
        }

        billing {
          id
          plan
        }
      }

      victim {
        id
      }
    }
  `),
}

type Props = {
  report: FragmentType<typeof fragments.CompanyChatForm_report>
  isLoading?: boolean
  userId: string
  sendClicked?: () => void
  input: (
    props: { forceDisableSubmitButton: (state: boolean) => void } & Pick<
      ComponentProps<typeof ChatInput>,
      'disabled' | 'value' | 'onChange' | 'attachments'
    >
  ) => ReactNode
  _wrapWithPermissionTooltip?: (content: (hasPermissions: boolean) => ReactElement) => ReactElement
}

const ChatForm = ({
  report: _report,
  userId,
  sendClicked,
  input,
  isLoading,
  _wrapWithPermissionTooltip = content => <>{content(true)}</>,
}: Props) => {
  const report = getFragmentData(fragments.CompanyChatForm_report, _report)
  const relativeMessages = useContext(RelativeMessagesContext)
  const { formatMessage: formatMessageDef } = useIntlDef()
  const { formatMessage } = useIntl()
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [isSubmitButtonForciblyDisabled, setIsSubmitButtonForciblyDisabled] =
    useState<boolean>(false)
  const { alertType } = useContext(PopupsContext)
  const [text, setText] = useState('')

  const disabled = !report?.isDemo && (report?.status !== ReportStatus.Open || !report?.victim?.id)
  const emptyInput = !text.trim().length && !attachments.length
  const allowAttachments =
    report?.company?.billing?.plan === Plan.Basic ||
    report?.company?.billing?.plan === Plan.Standard ||
    report?.company?.billing?.plan === Plan.Premium

  const [sendMessage, { loading: loadingSendMessage }] = useMutation(
    mutations.SendMessageMutation,
    {
      onCompleted: () => {
        setText('')
        setAttachments([])
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
    }
  )

  const reportId = report?.id ?? null
  const motherId = report?.company?.mother?.id ?? report?.company?.id

  const [toggleHideName] = useMutation(mutations.EditCompanyReportNameVisibility, {
    onError: error => {
      console.error(error.message)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      input: {
        reportId,
        motherId,
      },
    },
  })

  const isUserVictim = Boolean(report?.victim?.id) && userId === report?.victim?.id

  return (
    <Form
      layout='vertical'
      style={{ textAlign: 'end', width: '100%' }}
      data-cy='send-message-form'
      onFinish={async () => {
        if (isSubmitButtonForciblyDisabled) {
          return
        }
        if (text.trim().length || attachments.length) {
          const payload = await createComment(
            text.trim(),
            report?.encryptionKey ?? '',
            attachments.map(({ file }) => file)
          )

          if (payload.isErr()) {
            console.error(payload.error)
            notification.error({
              message: formatMessage(sharedMessages.encryptionError),
            })
            return
          }

          sendClicked?.()
          await sendMessage({
            variables: {
              input: {
                motherId,
                reportId: report?.id ?? '',
                ...payload.value,
              },
              isVictim: isUserVictim,
            },
          })
        }
      }}
    >
      <Form.Item label={<FormattedMessageDef {...relativeMessages.noteLabel} />} noStyle>
        {alertType === ChatPopupAlertType.NotVictimReport && !report?.isDemo ? (
          <AlertManualReport
            message={
              relativeMessages.type === 'Admin' && (
                <FormattedMessageDef {...relativeMessages.functionalityUnsupported} />
              )
            }
            type='info'
            showIcon
          />
        ) : (
          input({
            forceDisableSubmitButton: setIsSubmitButtonForciblyDisabled,
            value: text,
            onChange: setText,
            disabled,
            attachments: allowAttachments
              ? {
                  attachments,
                  setAttachments,
                  hookAttachmentsToDelete: null,
                }
              : null,
          })
        )}
      </Form.Item>
      {relativeMessages.type === 'Victim' && (
        <Prompt when={text.length > 0} message={formatMessageDef(relativeMessages.leaveQuestion)} />
      )}
      <ChatFooter>
        {!isUserVictim &&
          report?.creationOrigin !== ReportCreationOrigin.Member &&
          _wrapWithPermissionTooltip(hasPermissions => (
            <Checkbox
              checked={report?.isNameHidden ?? false}
              onChange={() => toggleHideName()}
              disabled={!hasPermissions}
            >
              <span style={{ color: '#688699' }}>
                <FormattedMessageDef
                  {...(relativeMessages.type === 'Admin' && relativeMessages.hideMyName)}
                  tagName='span'
                />
              </span>
            </Checkbox>
          ))}
        <Button
          loading={loadingSendMessage || isLoading}
          type='submit'
          disabled={disabled || emptyInput || isSubmitButtonForciblyDisabled}
          sx={{
            marginInlineStart: 'auto',
            marginTop: 10,
          }}
          data-cy='submit-chat-message-button'
        >
          <FormattedMessage {...sharedMessages.send} />
        </Button>
      </ChatFooter>
    </Form>
  )
}

const AlertManualReport = styled(Alert)`
  text-align: start;

  &.ant-alert-info {
    border: 0;
    background-color: #dcf1fe;
  }
`

const ChatFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default ChatForm
