import type { Locale } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { createContext } from 'react'

export type TextDirection = 'ltr' | 'rtl'

type State = {
  language?: Language
  changeLanguage: (language: Language) => void
  messages: Record<string, string> | undefined
  basename: string
  antLocale: Locale | undefined
  basenameIfAvailable: string
  direction: TextDirection
}

export const LanguageContext = createContext<State>({
  language: undefined,
  changeLanguage: () => undefined,
  messages: undefined,
  basename: '/',
  antLocale: undefined,
  basenameIfAvailable: '/',
  direction: 'ltr',
})
