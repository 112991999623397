import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { sharedMessages, useIntl } from '@faceup/localization'
import { Button } from '@faceup/ui'
import { Alert, notification } from '@faceup/ui-base'
import { ReportStatus } from '@faceup/utils'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { ChatPopupAlertType, PopupsContext } from './PopupsContext'
import { RelativeMessagesContext } from './RelativeMessagesContext'

const fragments = {
  ReportStatusAlert_report: graphql(`
    fragment ReportStatusAlert_report on CompanyReport {
      id
      status
      isDemo
      company {
        id
        workingHours {
          id
          enabled
          outsideWorkingHoursMessage
        }
      }
      victim {
        id
      }
    }
  `),
}

const mutations = {
  ReopenCompanyReportMutation: graphql(`
    mutation ReopenCompanyReportMutation {
      reopenCompanyReport {
        report {
          id
          status
        }
      }
    }
  `),
}

type Props = {
  userId: string
  report: FragmentType<typeof fragments.ReportStatusAlert_report>
  isFirstMessage?: boolean
}

const ReportStatusAlert = ({ report: _report, userId, isFirstMessage }: Props) => {
  const report = getFragmentData(fragments.ReportStatusAlert_report, _report)
  const relativeMessages = useContext(RelativeMessagesContext)
  const { formatMessage } = useIntl()
  const { alertType, closeNotOpenReportAlert } = useContext(PopupsContext)
  const [reopenChat] = useMutation(mutations.ReopenCompanyReportMutation, {
    onError: error => {
      console.error(error.message)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      closeNotOpenReportAlert()
    },
  })

  const userIsVictim = Boolean(report?.victim?.id) && userId === report?.victim?.id
  const isArchived = report.status === ReportStatus.Archived
  const isFollowUp = relativeMessages.type === 'Victim'

  if (alertType === ChatPopupAlertType.ReportStatus) {
    return (
      <>
        <AlertMessage
          data-cy='report-status-alert-report-closed'
          message={
            <FormattedMessage
              {...(isArchived
                ? relativeMessages.reportWasArchived
                : relativeMessages.reportWasClosed)}
            />
          }
          type={isArchived ? 'info' : 'success'}
          showIcon
          status={report.status}
          isFollowUp={isFollowUp}
        />
        {userIsVictim && (
          <Button sx={{ margin: 'auto' }} onClick={() => reopenChat()}>
            {isFollowUp && <FormattedMessage {...relativeMessages.reopen} />}
          </Button>
        )}
      </>
    )
  }

  if (alertType === ChatPopupAlertType.OutsideWorkingHours && userIsVictim && isFirstMessage) {
    return (
      <AlertMessage
        data-cy='report-status-alert-outside-working-hours'
        message={report?.company?.workingHours?.outsideWorkingHoursMessage ?? ''}
        type='warning'
        closable
        afterClose={() => closeNotOpenReportAlert()}
        showIcon
        status={report.status}
        isFollowUp
      />
    )
  }

  return null
}

const AlertMessage = styled(Alert)<{ isFollowUp?: boolean; status: ReportStatus | null }>`
  width: 100%;
  margin: 0 auto 20px;
  padding: 16px;
  border-radius: 6px;

  ${({ isFollowUp }) =>
    isFollowUp &&
    css`
      border: 0;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
    `}

  background: ${({ status }) =>
    status === ReportStatus.Archived
      ? '#0e9af714'
      : status === ReportStatus.Closed
        ? '#0cce6b14'
        : '#f7990e14'};

  .ant-alert-message {
    color: ${({ status }) =>
      status === ReportStatus.Archived
        ? '#0e9af7'
        : status === ReportStatus.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }

  button svg {
    color: ${({ status }) =>
      status === ReportStatus.Archived
        ? '#0e9af7'
        : status === ReportStatus.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }
`

export default ReportStatusAlert
