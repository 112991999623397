import type { UntitledIconData } from '../../UntitledIconData'

const ulLink01: UntitledIconData = {
  prefix: 'ul',
  name: 'link-01',
  paths: [
    {
      shape:
        'M12.7076 18.3639L11.2933 19.7781C9.34072 21.7308 6.1749 21.7308 4.22228 19.7781C2.26966 17.8255 2.26966 14.6597 4.22228 12.7071L5.63649 11.2929M18.3644 12.7071L19.7786 11.2929C21.7312 9.34024 21.7312 6.17441 19.7786 4.22179C17.826 2.26917 14.6602 2.26917 12.7076 4.22179L11.2933 5.636M8.50045 15.4999L15.5005 8.49994',
    },
  ],
}

export default ulLink01
