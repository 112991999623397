import { Modal, UserAvatar } from '@faceup/ui'
import { List, Typography } from '@faceup/ui-base'
import { Divider } from '@mantine/core'
import React from 'react'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'

const { Text } = Typography

const messages = defineMessages({
  emailModalSharedToTitle: 'Administration.surveys.emailModal.sharedTo.title',
  displayingXEmployeesOutOfY:
    'Administration.surveys.emailModal.sharedTo.displayingXEmployeesOutOfY',
})

type SurveySharedWithEmployeesProps = {
  onClose: () => void
  onClickBackArrow?: () => void
  selectedEmployees: {
    email: string
    name: string
  }[]
  totalCount?: number
}

export const SurveySharedWithEmployeesModal = ({
  onClose,
  onClickBackArrow,
  selectedEmployees,
  totalCount,
}: SurveySharedWithEmployeesProps) => {
  return (
    <Modal
      width={620}
      title={<FormattedMessage {...messages.emailModalSharedToTitle} />}
      opened
      onClose={onClose}
      onClickBackArrow={onClickBackArrow}
    >
      <List
        itemLayout='horizontal'
        dataSource={selectedEmployees}
        renderItem={(employee, index) => (
          <React.Fragment key={employee.email}>
            <List.Item>
              <List.Item.Meta
                avatar={<UserAvatar user={{ name: employee.name }} color='purple' />}
                title={<Text strong>{employee.name}</Text>}
                description={<Text type='secondary'>{employee.email}</Text>}
              />
            </List.Item>
            {index !== selectedEmployees.length - 1 && (
              <Divider style={{ borderColor: '#E6ECF9' }} />
            )}
          </React.Fragment>
        )}
      />
      {totalCount && totalCount > selectedEmployees.length && (
        <Text type='secondary'>
          <FormattedMessage
            {...messages.displayingXEmployeesOutOfY}
            values={{ x: selectedEmployees.length, y: totalCount }}
          />
        </Text>
      )}
    </Modal>
  )
}
