import { UntitledIcon } from '@faceup/icons'
import { Alert as AntAlert, type AlertProps as AntAlertProps } from 'antd'
import { statusIcons } from './index'

type AlertProps = AntAlertProps

export const Alert = (props: AlertProps) => {
  return (
    <AntAlert
      icon={<UntitledIcon icon={statusIcons[props.type ?? 'info']} />}
      showIcon
      {...props}
    />
  )
}
