import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { ulFileQuestion03 } from '@faceup/icons/ulFileQuestion03'
import { ulLinkExternal01 } from '@faceup/icons/ulLinkExternal01'
import { ulMessageTextSquare02 } from '@faceup/icons/ulMessageTextSquare02'
import { ulSend01 } from '@faceup/icons/ulSend01'
import { ulShare06 } from '@faceup/icons/ulShare06'
import { useNavigate, useParams } from '@faceup/router'
import { Tooltip } from '@faceup/ui'
import { Button, Space } from '@faceup/ui-base'
import { buildUrl } from '@faceup/utils'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import useConfigForProject from '../../../hooks/useConfigForProject'
import { PublishSurveyModal } from './PublishSurveyModal'
import { SurveyOptionsMenu } from './SurveyOptionsMenu'

const messages = defineMessages({
  xSubmissions: 'Administration.surveys.submissions.statistics.xSubmissions',
  xQuestions: 'Administration.surveys.submissions.statistics.xQuestions',
  share: 'Administration.surveys.detail.share',
  publish: 'Administration.surveys.detail.publish',
  preview: 'Administration.surveys.detail.preview',
  cannotBePublished: 'Administration.surveys.navigation.cannotBePublished',
})

export type SurveyModalModes = 'share' | 'publish'

const fragments = {
  SurveyNavigationFragment_reportSource: graphql(`
    fragment SurveyNavigationFragment_reportSource on ReportSource {
      id
      ...PublishSurveyModalFragment
      ...SurveyOptionsMenu_reportSource
      shortLink
      isEnabled
      config {
        ... on SurveyChannelConfiguration {
          id
          submissionsCount
          formItems {
            id
          }
        }
      }
    }
  `),
}

export type SurveyNavigationProps = {
  data: FragmentType<typeof fragments.SurveyNavigationFragment_reportSource>
  variant: 'questions' | 'submissions'
}

export const SurveyNavigation = ({ variant, data: _data }: SurveyNavigationProps) => {
  const data = getFragmentData(fragments.SurveyNavigationFragment_reportSource, _data)
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { generateSurveyUrl } = useConfigForProject()
  const [shareModal, setShareModal] = useState<SurveyModalModes>()

  if (!id) {
    return null
  }

  const config = data?.config?.__typename === 'SurveyChannelConfiguration' ? data.config : null
  const isSurveyEnabled = data.isEnabled

  const surveyUrl = buildUrl(generateSurveyUrl(data.shortLink), { preview: '' })
  const submissionsCount = config?.submissionsCount ?? 0
  const questionsCount = config?.formItems.length ?? 0

  const isSubmissionButtonVisible =
    variant === 'submissions' && (isSurveyEnabled || submissionsCount > 0)

  return (
    <>
      <SpacePullRight>
        {isSurveyEnabled ? (
          <Button
            type='primary'
            icon={<UntitledIcon icon={ulShare06} />}
            onClick={() => setShareModal('share')}
            data-cy='survey-share-button'
          >
            <FormattedMessage {...messages.share} />
          </Button>
        ) : (
          <Tooltip
            title={
              questionsCount === 0 ? (
                <FormattedMessage {...messages.cannotBePublished} />
              ) : undefined
            }
          >
            <div>
              <Button
                type='primary'
                disabled={config?.formItems.length === 0}
                icon={<UntitledIcon icon={ulSend01} />}
                onClick={() => setShareModal('publish')}
                data-cy='publish-survey-button'
              >
                <FormattedMessage {...messages.publish} />
              </Button>
            </div>
          </Tooltip>
        )}
        {isSubmissionButtonVisible && (
          <Button
            icon={<UntitledIcon icon={ulMessageTextSquare02} />}
            onClick={() => navigate(routes => routes.surveySubmissionsStatistics({ id }))}
          >
            <FormattedMessage {...messages.xSubmissions} values={{ submissionsCount }} />
          </Button>
        )}
        {variant === 'questions' && (
          <Button
            icon={<UntitledIcon icon={ulFileQuestion03} />}
            onClick={() => navigate(routes => routes.surveyDetail({ id }))}
          >
            <FormattedMessage {...messages.xQuestions} values={{ questionsCount }} />
          </Button>
        )}
        <Button
          icon={<UntitledIcon icon={ulLinkExternal01} />}
          rel='noopener noreferrer'
          target='_blank'
          href={surveyUrl}
        >
          <FormattedMessage {...messages.preview} />
        </Button>
        <SurveyOptionsMenu
          showBackground
          survey={data}
          optionsToDisplay={['duplicate', 'downloadData', 'changeLanguage', 'deactivate', 'delete']}
        />
      </SpacePullRight>
      {shareModal && (
        <PublishSurveyModal
          survey={data}
          mode={shareModal}
          onClose={() => setShareModal(undefined)}
        />
      )}
    </>
  )
}

const SpacePullRight = styled(Space)`
  width: 100%;
  justify-content: flex-end;
`
