import styles from './assets/EmailWrapper.module.css'

type EmailWrapperProps = {
  emailHTML: string
}

export const EmailWrapper = ({ emailHTML }: EmailWrapperProps) => {
  return (
    <div className={styles.wrapper}>
      {/* biome-ignore lint/security/noDangerouslySetInnerHtml: this should be our html, sanitized from BE */}
      <div dangerouslySetInnerHTML={{ __html: emailHTML ?? '' }} />
    </div>
  )
}
