import { useNavigate } from '@faceup/router'
import { AlertBar } from '@faceup/ui'
import type { ComponentProps, ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useAlertOpen } from '../../hooks/useAlertOpen'
import useSubscriptionDetail, {
  type SubscriptionDetailReturn,
} from '../../hooks/useSubscriptionDetail'
import { PaymentNoticeMessage } from './PaymentNoticeMessage'

const messages = defineMessages({
  buy: 'Administration.trial.buy',
  renew: 'Administration.paymentNotice.renew',
})

const fragments = {
  PaymentNoticeAlertBar_company: graphql(`
    fragment PaymentNoticeAlertBar_company on Company {
      id

      billing {
        id
        subscriptionStatus
        subscriptionEnd
      }

      ...SubscriptionDetail_company
      ...PaymentNoticeMessage_company
    }
  `),
}

type PaymentNoticeAlertBarProps = {
  company: FragmentType<typeof fragments.PaymentNoticeAlertBar_company>
}

export const PaymentNoticeAlertBar = ({ company: _company }: PaymentNoticeAlertBarProps) => {
  const company = getFragmentData(fragments.PaymentNoticeAlertBar_company, _company)
  const inTrialAlert = useAlertOpen({
    key: 'inTrial',
  })
  const nonRenewingAlert = useAlertOpen({
    key: 'nonRenewing',
  })

  const { state } = useSubscriptionDetail({ institution: company })

  const navigate = useNavigate()

  const states: Record<
    SubscriptionDetailReturn['state'],
    {
      alertBar: Pick<ComponentProps<typeof AlertBar>, 'color'>
      hook: ReturnType<typeof useAlertOpen>
      button: {
        text: ReactNode
      }
    } | null
  > = {
    trialExpired: null,
    cancelled: null,
    inTrial: {
      alertBar: { color: 'orange' },
      hook: inTrialAlert,
      button: {
        text: <FormattedMessage {...messages.buy} />,
      },
    },
    future: null,
    active: null,
    nonRenewing: {
      alertBar: { color: 'orange' },
      hook: nonRenewingAlert,
      button: {
        text: <FormattedMessage {...messages.renew} />,
      },
    },
  }
  if (!states[state]) {
    return null
  }
  return (
    <>
      <AlertBar
        {...states[state].hook}
        {...states[state].alertBar}
        button={{
          ...states[state].button,
          onClick: () => {
            navigate(routes => routes.settingsInvoicing())
          },
        }}
      >
        <PaymentNoticeMessage institution={company} isSingleColor isWrapped={false} />
      </AlertBar>
    </>
  )
}
