import { LanguageDropdown as LocalizationLanguageDropdown } from '@faceup/localization'
import { Language } from '@faceup/utils'
import type { ComponentProps } from 'react'

type DropdownLanguageProps = {
  selectedLanguage: Language
  onClickLanguage: (language: Language) => void
  allowedLanguages?: Language[]
  areLanguagesNative?: boolean
  styles?: ComponentProps<typeof LocalizationLanguageDropdown>['styles']
}

const LanguageDropdown = ({
  selectedLanguage,
  onClickLanguage,
  allowedLanguages,
  areLanguagesNative,
  styles,
}: DropdownLanguageProps) => {
  const filteredSortedLanguages = Object.values(Language).filter(
    lang => allowedLanguages?.includes(lang) ?? true
  )

  return (
    <LocalizationLanguageDropdown
      languages={filteredSortedLanguages}
      activeLanguage={selectedLanguage}
      onChangeLanguage={onClickLanguage}
      areLanguagesNative={areLanguagesNative}
      styles={styles}
    />
  )
}

export default LanguageDropdown
