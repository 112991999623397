import { useMutation } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { type PageTranslations, pagesIcons } from '@faceup/ui'
import { notification, useModal } from '@faceup/ui-base'
import { ChannelPageType, ChannelType, type Language, reorder } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useEffect } from 'react'
import ReactDragListView from 'react-drag-listview'
import { DragListItem } from '../../../../../Components/DragListItem'
import MoreOptions from '../../../../../Components/MoreOptions'
import { sharedMessages } from '../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { useMotherId } from '../../../../../hooks/useMotherId'
import usePageTemplate from '../../../../../hooks/usePageTemplate'

type PagesList_reportSource = ResultOf<typeof fragments.PagesList_reportSource>
type PagesList_reportSource_pages = PagesList_reportSource['pages'][number]

const messages = defineMessages({
  deleteMessage: 'Administration.customization.pages.deleteMessage',
  untitledTitle: 'Administration.customization.pages.title.untitled',
})

const ChannelPageNavigationTitles = defineMessages<ChannelPageType>({
  [ChannelPageType.BeforeSend]: 'Shared.surveys.pages.beforeSend.title',
  [ChannelPageType.FormItems]: 'Administration.surveys.pages.formItems.title',
  [ChannelPageType.AfterSend]: 'Administration.surveys.pages.afterSend.navigationTitle',
})

const fragments = {
  PagesList_reportSource: graphql(`
    fragment PagesList_reportSource on ReportSource {
      id
      isDefault
      sourceType
      pages(language: $language) {
        ... on Page {
          id
          icon
          title
          order
        }
        ... on ChannelPage {
          id
          icon
          order
          title
          type
        }
      }
    }
  `),
}

const mutation = {
  ChangePageOrderMutation: graphql(`
    mutation ChangePageOrderMutation(
      $input: ChangePageOrderInput!
      $language: Language!
    ) {
      changePageOrder(input: $input) {
        reportSource {
          id

          ...PagesList_reportSource
        }
      }
    }
  `),
  DeletePageMutation: graphql(`
    mutation DeletePageMutation($input: DeletePageInput!, $language: Language!) {
      deletePage(input: $input) {
        reportSource {
          id

          ...PagesList_reportSource
        }
      }
    }
  `),
}

type PagesProps = {
  reportSource: FragmentType<typeof fragments.PagesList_reportSource>
  language: Language
  activePage: { id: string } | null | undefined
  setActivePageId: (pageId: string) => void
  isNewPageActive: boolean
}

const dragListItemIdentifier = 'page'

export const PagesList = ({
  reportSource: _reportSource,
  language,
  activePage,
  setActivePageId,
  isNewPageActive,
}: PagesProps) => {
  const reportSource = getFragmentData(fragments.PagesList_reportSource, _reportSource)
  const pages = reportSource.pages
  const { getMotherId } = useMotherId()
  const { fillObject, setIsDefaultForm } = usePageTemplate(language)
  const { formatMessage } = useIntl()
  const modal = useModal()

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  const [changePagesOrder] = useMutation(mutation.ChangePageOrderMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [deletePage] = useMutation(mutation.DeletePageMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const canManipulateWithPages = reportSource.sourceType !== ChannelType.Survey

  return (
    <>
      <ReactDragListView
        handleSelector={`.draggable.${dragListItemIdentifier}`}
        nodeSelector={`.${dragListItemIdentifier}-item`}
        onDragEnd={(fromIndex, toIndex) => {
          // toIndex is -1 when the item is dragged outside the list
          if (toIndex < 0) {
            return
          }
          const newPagesOrders = reorder(pages, fromIndex, toIndex).map(({ id, order }) => ({
            id,
            order,
          }))
          void changePagesOrder({
            variables: {
              input: {
                reportSourceId: reportSource.id,
                motherId: getMotherId(),
                newPagesOrders,
                language,
              },
              language,
            },
          })
        }}
        lineClassName='drag-list-line'
      >
        {pages.map(page => {
          let filledPage: PageTranslations<PagesList_reportSource_pages>
          if (page.__typename === 'ChannelPage') {
            filledPage = fillObject({
              ...page,
              type: page.type as ChannelPageType,
            })
          } else {
            filledPage = fillObject(page)
          }
          const { id, title, icon } = filledPage

          const navigationTitle =
            page.__typename === 'ChannelPage' ? (
              <FormattedMessage {...ChannelPageNavigationTitles[page.type]} />
            ) : undefined

          return (
            <DragListItem
              key={id}
              onClick={() => setActivePageId(id)}
              isFocused={id === activePage?.id}
              icon={pagesIcons[icon]}
              label={navigationTitle ?? title}
              identifier={dragListItemIdentifier}
              secondary={
                canManipulateWithPages && (
                  <MoreOptions isMantine>
                    <MoreOptions.Item
                      disabled={pages.length === 1}
                      icon={<UntitledIcon icon={ulTrash03} />}
                      color='red'
                      onClick={() => {
                        modal.confirm({
                          title: formatMessage(sharedMessages.delete),
                          content: formatMessage(messages.deleteMessage),
                          okText: formatMessage(sharedMessages.ok),
                          okType: 'primary',
                          centered: true,
                          cancelText: formatMessage(sharedMessages.cancel),
                          onOk: async () => {
                            await deletePage({
                              variables: {
                                input: {
                                  reportSourceId: reportSource.id,
                                  motherId: getMotherId(),
                                  pageId: id,
                                },
                                language,
                              },
                            })
                          },
                        })
                      }}
                    >
                      <FormattedMessage {...sharedMessages.delete} />
                    </MoreOptions.Item>
                  </MoreOptions>
                )
              }
              isDraggable={canManipulateWithPages}
            />
          )
        })}
      </ReactDragListView>
      {isNewPageActive && (
        <DragListItem
          onClick={() => null}
          isFocused
          icon={pagesIcons['home']}
          label={formatMessage(messages.untitledTitle)}
          isDraggable={false}
          identifier='not-used'
        />
      )}
    </>
  )
}
