import { useQuery } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { sharedMessages } from '@faceup/localization'
import { Flex, notification } from '@faceup/ui-base'
import {
  type Language,
  Plan,
  ReportStatus,
  getTranslateReportLanguage,
  removeTranslateReportLanguage,
  saveTranslateReportLanguage,
} from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import {
  type ComponentProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useIntl } from 'react-intl'
import { graphql } from '../../__generated__'
import { NotRedactableWrapper } from '../Redaction'
import { TranslateMenu } from '../TranslateMenu'
import Chat from './Chat'
import ChatForm from './ChatForm'
import PopupsProvider from './PopupsProvider'
import { RelativeMessagesProvider } from './RelativeMessagesContext'
import { ReportRating } from './ReportRating'
import ReportStatusAlert from './ReportStatusAlert'

export const query = {
  CompanyReportFollowUpChatQuery: graphql(`
    query CompanyReportFollowUpChatQuery(
      $reportId: CompanyReportGlobalId!
      $sourceLanguage: Language
      $targetLanguage: Language
    ) {
      report(reportId: $reportId) {
        id
        status
        isNameHidden
        translation(
          sourceLanguage: $sourceLanguage
          targetLanguage: $targetLanguage
        ) {
          sourceLanguage
          targetLanguage
        }

        victim {
          id
        }

        followUpActivities(page: 0, rowsPerPage: 10000) {
          items {
            id
            author {
              ... on CompanyVictim {
                id
              }

              ... on Member {
                id
              }
            }
            ...FollowUpChatMessage_followUpActivityNode
          }
        }

        company {
          id
          isE2EE
          billing {
            id
            plan
          }

          workingHours {
            id
            outsideWorkingHoursMessage
            timezone
            enabled
            days {
              name
              from
              to
            }
          }
        }

        ...CompanyChatForm_report
        ...CompanyChat_report
        ...ReportStatusAlert_report
        ...ReportRating_report
      }
    }
  `),
}

// BAD PRACTICE: DO NOT INSPIRE FROM THIS, SHOULD BE REFACTORED
export type CompanyReportFollowUpChatQuery = ResultOf<typeof query.CompanyReportFollowUpChatQuery>

const subscriptions = {
  NewFollowUpReportActivitySubscription: graphql(`
    subscription NewFollowUpReportActivitySubscription(
      $reportId: CompanyReportGlobalId!
      $sourceLanguage: Language
      $targetLanguage: Language
    ) {
      newCompanyReportFollowUpActivity(input: { reportId: $reportId }) {
        id
        author {
          ... on CompanyVictim {
            id
          }

          ... on Member {
            id
          }
        }
        ...FollowUpChatMessage_followUpActivityNode
      }
    }
  `),
}

type Props = {
  isExportMode?: boolean
  reportId: string
  userId: string

  onMessageSend?: () => void
  onMessageSeen?: () => void
  onReady?: () => void
  fileDownloadHandler?: (blob: Blob, name: string) => Promise<void>

  relativeMessages: ComponentProps<typeof RelativeMessagesProvider>['relativeMessages']
  sourceLanguage?: Language | null
  targetLanguage?: Language | null
  input: ComponentProps<typeof ChatForm>['input']
  isLoading?: boolean
  firstUnreadMessageId?: string
  _wrapWithPermissionTooltip?: ComponentProps<typeof ChatForm>['_wrapWithPermissionTooltip']
}

const ChatWrapper = ({
  isExportMode = false,
  reportId,
  userId,
  onMessageSend,
  onMessageSeen,
  onReady,
  fileDownloadHandler,
  relativeMessages,
  sourceLanguage: defaultSourceLanguage,
  targetLanguage: defaultTargetLanguage,
  input,
  isLoading,
  firstUnreadMessageId,
  // This component is used in multiple places and in one of those places we need to wrap some content with a component
  //  this prop caters for that
  _wrapWithPermissionTooltip,
}: Props) => {
  const { formatMessage } = useIntl()
  const chatRef = useRef<HTMLDivElement>(null)
  const [savedSourceLanguage, savedTargetLanguage] = getTranslateReportLanguage(reportId)
  const [sourceLanguage, setSourceLanguage] = useState<Language | 'auto' | null>(null)
  const [targetLanguage, setTargetLanguage] = useState<Language | null>(null)

  const { data, subscribeToMore, refetch } = useQuery(query.CompanyReportFollowUpChatQuery, {
    onError: error => {
      console.error(error.message)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      reportId,
      sourceLanguage: savedSourceLanguage === 'auto' ? null : savedSourceLanguage,
      targetLanguage: savedTargetLanguage,
    },
    onCompleted: () => onReady?.(),
  })

  const subscribeToNewMessages = useCallback(() => {
    const unsubscribe = subscribeToMore({
      document: subscriptions.NewFollowUpReportActivitySubscription,
      variables: {
        reportId,
        sourceLanguage: savedSourceLanguage === 'auto' ? null : savedSourceLanguage,
        targetLanguage: savedTargetLanguage,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data || prev?.report?.__typename !== 'CompanyReport') {
          return prev
        }

        const incomingActivities = subscriptionData.data.newCompanyReportFollowUpActivity

        const existingIds = new Set(prev?.report?.followUpActivities?.items?.map(item => item?.id))

        const newActivities = incomingActivities?.filter(activity => !existingIds.has(activity?.id))

        if (!newActivities?.length) {
          return prev
        }

        return {
          ...prev,
          report: {
            ...prev.report,
            followUpActivities: {
              ...prev.report.followUpActivities,
              __typename: 'CompanyReportFollowUpCommentConnection' as const,
              items: [...(prev.report.followUpActivities?.items ?? []), ...(newActivities ?? [])],
            },
          },
        }
      },
    })

    return unsubscribe
  }, [subscribeToMore, reportId, savedSourceLanguage, savedTargetLanguage])

  const [isFirstMessage, setIsFirstMessage] = useState(false)

  const setMessage = () => {
    onMessageSend?.()
    if (!isFirstMessage) {
      setIsFirstMessage(true)
    }
  }

  useEffect(() => {
    if (savedSourceLanguage) {
      setSourceLanguage(savedSourceLanguage === 'auto' ? null : savedSourceLanguage)
    }

    if (savedTargetLanguage) {
      setTargetLanguage(savedTargetLanguage)
    }
  }, [savedSourceLanguage, savedTargetLanguage])

  useLayoutEffect(() => subscribeToNewMessages?.() ?? undefined, [subscribeToNewMessages])

  useEffect(() => {
    if (defaultSourceLanguage) {
      setSourceLanguage(defaultSourceLanguage)
    }

    if (defaultTargetLanguage) {
      setTargetLanguage(defaultTargetLanguage)
    }
  }, [defaultSourceLanguage, defaultTargetLanguage])

  // biome-ignore lint/correctness/useExhaustiveDependencies(sourceLanguage):
  // biome-ignore lint/correctness/useExhaustiveDependencies(targetLanguage):
  useEffect(() => {
    refetch()
  }, [sourceLanguage, targetLanguage, refetch])

  const report = data?.report ?? null
  if (report?.__typename !== 'CompanyReport') {
    return null
  }

  const showTranslateMenu =
    userId === report.victim?.id &&
    !report?.company?.isE2EE &&
    Boolean(report?.followUpActivities?.items?.length) &&
    [Plan.Basic, Plan.Standard, Plan.Premium].includes(
      report?.company?.billing?.plan ?? Plan.Unregistered
    )

  return (
    <PopupsProvider report={report}>
      <RelativeMessagesProvider relativeMessages={relativeMessages}>
        <ScrollbarChatWrapper
          ref={chatRef}
          exportMode={isExportMode}
          centerContent={report?.followUpActivities?.items?.length === 0}
        >
          <ChatWrap data-cy='follow-up-chat'>
            <Chat
              firstUnreadMessageId={firstUnreadMessageId}
              report={report ?? null}
              subscribeToNewMessages={subscribeToNewMessages}
              userId={userId}
              fileDownloadHandler={fileDownloadHandler}
              onMessageSeen={onMessageSeen}
              chatRef={chatRef}
            />
          </ChatWrap>
        </ScrollbarChatWrapper>

        <Flex gap='24px' vertical>
          {!isExportMode && (
            <ReportStatusAlert userId={userId} report={report} isFirstMessage={isFirstMessage} />
          )}
          <ReportRating userId={userId} report={report} />
        </Flex>

        {!isExportMode && report.status === ReportStatus.Open && (
          <NotRedactableWrapper>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center',
              }}
            >
              {showTranslateMenu && (
                <div style={{ marginBottom: '1rem' }}>
                  <TranslateMenu
                    defaultLanguages={[sourceLanguage ?? 'auto', targetLanguage]}
                    onTranslate={(source, target) => {
                      setSourceLanguage(source)
                      setTargetLanguage(target)
                      saveTranslateReportLanguage(reportId, [source, target])
                    }}
                    onShowOriginal={() => {
                      setSourceLanguage(null)
                      setTargetLanguage(null)
                      removeTranslateReportLanguage(reportId)
                    }}
                    translations={{
                      detectLanguage: formatMessage(relativeMessages.detectLanguage),
                      showOriginal: formatMessage(relativeMessages.showOriginal),
                      translate: formatMessage(relativeMessages.translate),
                      to: formatMessage(relativeMessages.to),
                      sameLanguageError: formatMessage(relativeMessages.sameLanguageError),
                      disabledReasonE2EE: formatMessage(relativeMessages.disabledReasonE2EE),
                      onUseWarningTitle: formatMessage(relativeMessages.onUseWarningTitle),
                      onUseWarningContent: formatMessage(relativeMessages.onUseWarningContent),
                      onUseWarningDoNotShowAgain: formatMessage(
                        relativeMessages.onUseWarningDoNotShowAgain
                      ),
                    }}
                    {...(report.company?.isE2EE ? { disabledReason: 'e2ee' } : {})}
                  />
                </div>
              )}
              <ChatForm
                report={report ?? null}
                sendClicked={setMessage}
                userId={userId}
                input={input}
                _wrapWithPermissionTooltip={_wrapWithPermissionTooltip}
                isLoading={isLoading}
              />
            </div>
          </NotRedactableWrapper>
        )}
      </RelativeMessagesProvider>
    </PopupsProvider>
  )
}

const ScrollbarChatWrapper = styled.div<{
  centerContent?: boolean
  exportMode: boolean
}>`
  overflow: auto;

  ${({ exportMode }) =>
    !exportMode &&
    css`
      height: 365px;
    `}

  min-height: 300px;
  display: flex;
  flex-direction: column-reverse;
  ${({ centerContent }) =>
    centerContent &&
    css`
      justify-content: center;
      align-items: center;
    `}
`

const ChatWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export default ChatWrapper
