import { useMutation } from '@apollo/client'
import { LanguageSelect, ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { useLocalization } from '@faceup/localization'
import { useNavigate } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { DEFAULT_LANGUAGE, Language } from '@faceup/utils'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { graphql } from '../../../__generated__'

const messages = defineMessages({
  modalTitle: 'Administration.surveys.modal.title',
  nameLabel: 'Administration.surveys.modal.nameLabel',
  language: 'Administration.surveys.create.language',
})

const mutation = graphql(`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      survey {
        id
      }
    }
  }
`)

type CreateSurveyModalProps = {
  isModalOpen: boolean
  onClose: () => void
}

export const CreateSurveyModal = ({ isModalOpen, onClose }: CreateSurveyModalProps) => {
  const { language } = useLocalization()
  const navigate = useNavigate()
  const schema = yup.object({
    name: yup.string().required(),
    language: yup.string().oneOf(Object.values(Language)).required(),
  })

  const form = useForm({
    schema,
    afterSubmit: 'resetValues',
    defaultValues: {
      language: language ?? DEFAULT_LANGUAGE,
    },
  })

  const { formatMessage } = useIntl()

  const { getMotherId } = useMotherId()

  const [createSurvey] = useMutation(mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: data => {
      onClose()
      navigate(routes => routes.surveyDetail({ id: data.createSurvey?.survey?.id ?? '' }))
    },
    refetchQueries: ['SurveysTableQuery'],
  })

  return (
    <ModalForm
      form={form}
      onClose={onClose}
      onSubmit={async values => {
        const result = await createSurvey({
          variables: { input: { ...values, motherId: getMotherId() } },
        })

        if (result.errors) {
          return false
        }
        return true
      }}
      opened={isModalOpen}
      submitButtonText='add'
      title={<FormattedMessage {...messages.modalTitle} />}
      width={644}
    >
      <TextInput
        name='name'
        control={form.control}
        label={<FormattedMessage {...messages.nameLabel} />}
        data-cy='survey-name-input'
      />
      <LanguageSelect
        name='language'
        control={form.control}
        label={<FormattedMessage {...messages.language} />}
      />
    </ModalForm>
  )
}
