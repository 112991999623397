import { useApolloClient, useMutation } from '@apollo/client'
import {
  getCurrentEncryptionVersion,
  prehashPassword,
  processMigrationFromE2EE,
  processVersionMigration,
  savePersonalKeys,
} from '@faceup/crypto'
import { useCryptoErrorHandler } from '@faceup/report'
import { type Location, useLocation } from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { INVALID_LOGIN_CREDENTIALS_ERROR, UserLoginType } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useState } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import useAnalytics from '../../utils/analytics'
import Auth from '../../utils/auth'
import useRegion from '../../utils/useRegion'
import type { FormType } from './Login'

const query = {
  viewer: graphql(`
    query LoginViewer {
      memberViewer {
        id
        __typename
        isPartner

        keys {
          id
          salt
          recoveryKeyEncrypted
          recoveryKeyEncryptedNonce
          recoveryKeySystemEncrypted
        }

        motherImplicit {
          id
          organizationalUnitName
          isE2EE
        }
      }

      systemInfo {
        id
        publicKey
      }
    }
  `),
}

const mutation = {
  migrateUser: graphql(`
    mutation MigrateUserMutation($input: EditUserPasswordInput!) {
      editUserPassword(input: $input) {
        isValid
      }
    }
  `),
  preLogin: graphql(`
    mutation UserPreLoginMutation($input: UserPreLoginInput!) {
      userPreLogin(input: $input) {
        version
        salt
      }
    }
  `),
  login: graphql(`
    mutation UserLoginMutation($input: UserLoginInput!) {
      userLogin(input: $input) {
        token
        country
        userType
        publicKey
        privateKey
        nonce
        version
      }
    }
  `),
}

const useLogin = (setLoading: (isLoading: boolean) => void) => {
  const [code, setCode] = useState({ value: '', error: false })
  const [email, setEmail] = useState({ value: '', error: false })
  const [password, setPassword] = useState({ value: '', error: false })
  const [formType, setFormType] = useState<FormType>('login')
  const [rememberMe, setRememberMe] = useState(false)
  const { discoverByEmail } = useRegion()
  const { trackLogin } = useAnalytics()
  const { formatMessage } = useIntl()

  const client = useApolloClient()
  const handleError = useCryptoErrorHandler()
  const location = useLocation() as Location & { state?: { from: string } }
  const [serverLoginFormError, setServerLoginFormError] = useState(false)

  const [loginFirstPhase] = useMutation(mutation.preLogin, {
    variables: {
      input: {
        loginType: UserLoginType.PublicUser,
        email: email.value.trim(),
      },
    },
    onError: error => {
      setLoading(false)
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: ({ userPreLogin }) => {
      if (userPreLogin) {
        processFirstPhase({ userPreLogin })
      }
    },
  })

  const startLogin = async () => {
    await discoverByEmail(email.value.trim())

    return loginFirstPhase()
  }

  const processFirstPhase = async ({ userPreLogin }: ResultOf<typeof mutation.preLogin>) => {
    const version = (userPreLogin?.version ?? 1) as 1 | 2
    const salt = userPreLogin?.salt ?? ''
    const prehashedPassword = await prehashPassword({
      password: password.value.trim(),
      salt,
      version,
    })

    if (prehashedPassword.isErr()) {
      setLoading(false)
      return handleError(prehashedPassword.error.message)
    }

    const { passwordKeyPrehash, passwordKey } = prehashedPassword.value

    loginSecondPhase({
      variables: {
        input: {
          email: email.value.trim(),
          passwordPrehash: passwordKeyPrehash,
          rememberMe,
          loginType: UserLoginType.PublicUser,
          version,

          ...(formType === '2fa' && { code: code.value.trim() }),
        },
      },
      onCompleted: userLogin => {
        if (userLogin) {
          processSecondPhase(userLogin, passwordKey)
        }
      },
    })
  }

  const processSecondPhase = async (
    { userLogin }: ResultOf<typeof mutation.login>,
    passwordKey: string
  ) => {
    // > After login I should -> set jwt token + refetch viewer
    // > It's easier to redirect and reload page

    const defaultVersion = getCurrentEncryptionVersion()
    if (!userLogin?.token) {
      return
    }

    Auth.setJwt({
      jwt: userLogin.token,
      persistent: rememberMe,
    })

    await savePersonalKeys({
      publicKey: userLogin.publicKey ?? '',
      privateKey: userLogin.privateKey ?? '',
      nonce: userLogin.nonce ?? '',
      passwordKey,
      rememberMe,
      version: userLogin.version ?? defaultVersion,
    })

    const { data } = await client.query({ query: query.viewer })
    const systemInfo = data?.systemInfo
    const viewer = data?.memberViewer

    const navigate = () => {
      const previousLocationUrl = location?.state?.from
      const motherId = data.memberViewer?.motherImplicit?.id
      const newLocation =
        previousLocationUrl ||
        // Add motherId to the url if institution login
        `${motherId ? `/${motherId}` : ''}/${location.search}${location.hash}`

      window.location.replace(newLocation)
      // if there is hash in url, we need to reload the page, because changing hash does not trigger page reload
      if (previousLocationUrl?.includes('#') || location.hash) {
        window.location.reload()
      }
    }

    if (userLogin.version !== defaultVersion) {
      const result = await processVersionMigration({
        password: password.value.trim(),
        version: userLogin.version ?? defaultVersion,
        publicKey: userLogin.publicKey ?? '',
        privateKey: userLogin.privateKey ?? '',
        systemPublicKey: systemInfo?.publicKey ?? '',
      })

      if (result.isErr()) {
        setLoading(false)
        return handleError(result.error.message)
      }

      const { recoveryKeyPlain, ...input } = result.value

      await migrateUser({ variables: { input: { ...input, isMigration: true } } })
    } else if (
      viewer?.__typename === 'Member' &&
      !viewer?.isPartner &&
      !viewer?.motherImplicit?.isE2EE &&
      !viewer?.keys?.recoveryKeySystemEncrypted
    ) {
      const result = await processMigrationFromE2EE({
        password: password.value.trim(),
        salt: viewer?.keys?.salt ?? '',
        recoveryKeyEncrypted: viewer?.keys?.recoveryKeyEncrypted ?? '',
        recoveryKeyEncryptedNonce: viewer?.keys?.recoveryKeyEncryptedNonce ?? '',
        systemPublicKey: systemInfo?.publicKey ?? '',
      })

      if (result.isErr()) {
        setLoading(false)
        return handleError(result.error.message)
      }

      const input = {
        ...result.value,
        newNonce: userLogin.nonce ?? '',
        newPrivateKeyEncrypted: userLogin.privateKey ?? '',
      }

      await migrateUser({ variables: { input: { ...input, isMigration: true } } })
    }

    trackLogin()
    // maybe the consuming component should decide what to do after the process is done
    navigate()
  }

  const [loginSecondPhase] = useMutation(mutation.login, {
    onError: error => {
      setLoading(false)
      if (error.graphQLErrors[0]?.message === 'Missing 2FA code') {
        setFormType('2fa')
      } else if (formType === '2fa') {
        setCode({ ...code, error: true })
      } else {
        console.error(error)
        if (error.message === INVALID_LOGIN_CREDENTIALS_ERROR) {
          setServerLoginFormError(true)
        } else {
          notification.error({
            message: formatMessage(sharedMessages.apiError),
            description: error.message,
          })
        }
      }
    },
  })

  const [migrateUser] = useMutation(mutation.migrateUser, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  return {
    serverLoginFormError,
    login: () => {
      setLoading(true)
      startLogin()
    },
    code,
    setCode,
    email,
    setEmail,
    password,
    setPassword,
    formType,
    setFormType,
    rememberMe,
    setRememberMe,
  }
}

export default useLogin
