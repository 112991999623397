import { Form } from '@faceup/ui'
import {
  FormItemType,
  type Language,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
  getTranslation,
  isLength,
} from '@faceup/utils'
import { TextInput } from '@mantine/core'
import type { UseFormReturnType } from '@mantine/form'
import { type ReactNode, useMemo } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import useMockedReportMessages from '../../../hooks/useMockedReportMessages'

const messages = defineMessages({
  label: 'Administration.customization.formItems.label.label',
})

const fragments = {
  ReportSourceNameFormItem_formItem: graphql(`
    fragment ReportSourceNameFormItem_formItem on FormItem {
      id
      formItemId
      type
      labelTranslations {
        language
        translation
      }
    }
  `),
}

export type ReportSourceNameFormItems = { name: string | null }

export const useReportSourceNameFormHookHelper = (
  _formItem: FragmentType<typeof fragments.ReportSourceNameFormItem_formItem> | null,
  language: Language,
  defaultLanguage: Language
) => {
  const formItem = getFragmentData(fragments.ReportSourceNameFormItem_formItem, _formItem)
  const { formatMessage } = useIntl()
  const { formatMessageWithConfigLanguage } = useMockedReportMessages(language ?? defaultLanguage)

  return useMemo(() => {
    const getDefaultValueIfEmpty = () => {
      if (!formItem) {
        return null
      }
      const labelMessages: Record<FormItemType, string | null> = {
        [FormItemType.Category]: formatMessageWithConfigLanguage(sharedMessages.categoryLabel),
        [FormItemType.MoreInformation]: formatMessageWithConfigLanguage(
          sharedMessages.moreInfoLabel
        ),
        [FormItemType.Classroom]: formatMessageWithConfigLanguage(sharedMessages.classNameLabel),
        [FormItemType.OrganizationalUnit]: formatMessageWithConfigLanguage(
          sharedMessages.organizationLabel
        ),
        [FormItemType.SenderName]: formatMessageWithConfigLanguage(sharedMessages.globalNameLabel),
        [FormItemType.SimpleText]: null,
        [FormItemType.Select]: null,
        [FormItemType.MultiSelect]: null,
        [FormItemType.MultilineText]: null,
        [FormItemType.Email]: null,
        [FormItemType.Date]: null,
        [FormItemType.PhoneNumber]: null,
      }
      return labelMessages[formItem.type]
    }
    const initialNameValue =
      getTranslation(formItem?.labelTranslations ?? [], language, defaultLanguage) ||
      (getDefaultValueIfEmpty() ?? '')
    return {
      validate: {
        name: (value: string) => {
          if (!isLength(value.trim(), { min: STRING_MIN_LENGTH })) {
            return formatMessage(sharedMessages.invalidInputError)
          }
          return null
        },
      },
      initialValues: {
        name: initialNameValue,
      },
      transformValues: {
        name: (name: string | null) => {
          if (!name) {
            return null
          }
          const trimmedName = name.trim()
          const isNameSameAsOriginal = getDefaultValueIfEmpty() === trimmedName
          return isNameSameAsOriginal ? null : trimmedName
        },
      },
    }
  }, [formItem, language, defaultLanguage, formatMessageWithConfigLanguage, formatMessage])
}

type ReportSourceNameFormItemPropsFormHook<D extends Record<string, unknown>> = {
  form: UseFormReturnType<ReportSourceNameFormItems & D>
  value?: never
  onChange?: never
  errorMessage?: never
}

type ReportSourceNameFormItemPropsCallback = {
  form?: never
  value: string
  onChange: (value: string) => void
  errorMessage: ReactNode | null
}

type ReportSourceNameFormItemProps<D extends Record<string, unknown>> =
  | ReportSourceNameFormItemPropsFormHook<D>
  | ReportSourceNameFormItemPropsCallback

export const ReportSourceNameFormItem = <D extends Record<string, unknown>>(
  props: ReportSourceNameFormItemProps<D>
) => {
  const { form, value, onChange, errorMessage } = props
  return (
    <Form.Item
      label={<FormattedMessage {...messages.label} />}
      withAsterisk
      errorMessage={errorMessage}
    >
      <TextInput
        {...(form
          ? form.getInputProps('name')
          : {
              value,
              onChange: event => onChange(event.target.value),
            })}
        maxLength={SHORT_STRING_MAX_LENGTH}
      />
    </Form.Item>
  )
}
