import { Modal, Text } from '@faceup/ui'
import { Flex } from '@faceup/ui-base'
import { Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { UpsellIcon } from './UpsellIcon'
import { UpgradeButtons } from './components'
import type { UpsellData } from './upsellData'

const { Title } = Typography

const messages = defineMessages({
  upgradeTo: 'Administration.upsell.upgradePlanTo',
})

type UpsellModalProps = {
  isOpened: boolean
  onClose: () => void
  upsell: UpsellData
}

export const UpsellModal = (props: UpsellModalProps) => {
  const { isOpened, onClose, upsell } = props
  const { title, description } = upsell

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      width={800}
      title={
        <Flex gap='small' align='center'>
          <UpsellIcon />
          <FormattedMessage {...messages.upgradeTo} values={{ plan: upsell.upgradeTo }} />
        </Flex>
      }
      footer={
        <Flex justify='flex-end'>
          <UpgradeButtons upsell={upsell} />
        </Flex>
      }
    >
      {/* TODO: hide the style when modal is refactored */}
      <Flex vertical gap='large' style={{ overflow: 'clip' }}>
        <Title level={4}>{title}</Title>
        <Text color='subtleText'>{description}</Text>
      </Flex>
    </Modal>
  )
}
