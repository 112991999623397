import { useLazyQuery, useMutation } from '@apollo/client'
import { ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulEye } from '@faceup/icons/ulEye'
import { useMotherId } from '@faceup/institution'
import { EmailWrapper, FormItem } from '@faceup/ui'
import { Button, Flex, Typography, notification } from '@faceup/ui-base'
import { useState } from 'react'
import {
  EmployeeMultiSelect,
  useYupEmployeeMultiSelect,
} from '../../../Components/Form/EmployeeMultiSelect'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import type { Variant } from '../../../__generated__/graphql'
import { SurveySharedWithEmployeesModal } from './SurveySharedWithEmployeesModal'

const { Text } = Typography

const messages = defineMessages({
  emailModalTitle: 'Administration.surveys.emailModal.title',
  emailModalName: 'Administration.surveys.emailModal.fromNameLabel',
  employeesLabel: 'Administration.surveys.emailModal.employeesLabel',
  employeesPlaceholder: 'Administration.surveys.emailModal.employeesPlaceholder',
  emailModalContinue: 'Administration.surveys.emailModal.continue',
  emailModalSuccessMessage: 'Administration.surveys.emailModal.successMessage',
  emailModalConfirmAndSave: 'Administration.surveys.emailModal.confirmAndSave',
  emailModalButtonTextLabel: 'Administration.surveys.emailModal.buttonLabel',
  emailModalButtonTextHint: 'Administration.surveys.emailModal.buttonHint',
  emailModalSummarySubjectLabel: 'Administration.surveys.emailModal.summary.subjectLabel',
  emailModalSummaryMessageLabel: 'Administration.surveys.emailModal.summary.messageLabel',
  emailModalSummaryRecipients: 'Administration.surveys.emailModal.summary.numberOfRecipients',
  emailModalSummaryAntispam: 'Administration.surveys.emailModal.summary.antiSpam',
  emailModalSummaryFromLabel: 'Administration.surveys.emailModal.summary.fromLabel',
  emailModalSummaryTitle: 'Administration.surveys.emailModal.summary.title',
})

const fragments = {
  ShareSurveyByEmailFragment_reportSource: graphql(`
    fragment ShareSurveyByEmailFragment_reportSource on ReportSource {
      id
      config {
        ... on SurveyChannelConfiguration {
          id
        }
      }
    }
  `),
}

const mutations = {
  ShareSurveyByEmail: graphql(`
    mutation ShareSurveyByEmail($input: ShareSurveyByEmailInput!) {
      shareSurveyByEmail(input: $input) {
        config {
          id
          ... on SurveyChannelConfiguration {
            sharedWithEmployees {
              id
            }
          }
        }
      }
    }
  `),
}

const queries = {
  ShareByEmailModal_sharedEmail: graphql(`
    query ShareByEmailModal_sharedEmail(
      $motherId: UUID!
      $channelId: ReportSourceGlobalId!
      $fromName: GraphQLShortString!
      $buttonText: GraphQLShortString!
    ) {
      shareSurveyEmail(
        motherId: $motherId
        channelId: $channelId
        fromName: $fromName
        buttonText: $buttonText
      ) {
        html
        subject
      }
    }
  `),
  ShareByEmailModal_employees: graphql(`
    query ShareByEmailModal_employees(
      $institutionId: UUID!
      $employees: EmployeesWithSelectAllOption!
      $activeOnly: Boolean!
      $surveyChannelConfigId: UUID
      $page: Int!
      $rowsPerPage: Int!
    ) {
      employeesWithSelectAll(
        employees: $employees
        motherId: $institutionId
        activeOnly: $activeOnly
        surveyChannelConfigId: $surveyChannelConfigId
        page: $page
        rowsPerPage: $rowsPerPage
      ) {
        items {
          id
          name
          email
        }
        totalCount
      }
    }
  `),
}

type ShareByEmailModalProps = {
  onClose: () => void
  open: boolean
  survey: FragmentType<typeof fragments.ShareSurveyByEmailFragment_reportSource>
}

export const ShareByEmailModal = ({ survey: _survey, ...props }: ShareByEmailModalProps) => {
  const survey = getFragmentData(fragments.ShareSurveyByEmailFragment_reportSource, _survey)
  const [step, setStep] = useState<'form' | 'summary' | 'employeeList'>('form')
  const [selectedEmployees, setSelectedEmployees] = useState<{ email: string; name: string }[]>([])
  const [employeesTotalCount, setEmployeesTotalCount] = useState<number>(0)
  const [emailHTML, setEmailHTML] = useState<string | null>(null)
  const [emailSubject, setEmailSubject] = useState<string | null>(null)
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const shareByEmailSchema = yup.object().shape({
    name: yup.string().required(),
    employees: useYupEmployeeMultiSelect({ canBeEmpty: false }).required(),
    buttonText: yup.string().required(),
  })

  const employeesPageSize = 100

  const form = useForm({
    schema: shareByEmailSchema,
    afterSubmit: 'persistValues',
    defaultValues: {
      name: '',
      buttonText: '',
    },
  })

  const [shareSurvey] = useMutation(mutations.ShareSurveyByEmail, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'API error',
        description: error.message,
      })
    },
    onCompleted: () => {
      props.onClose()
    },
  })

  const [fetchEmail] = useLazyQuery(queries.ShareByEmailModal_sharedEmail, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: data => {
      setEmailHTML(data?.shareSurveyEmail?.html ?? '')
      setEmailSubject(data?.shareSurveyEmail?.subject ?? '')
      setStep('summary')
    },
  })

  const [fetchEmployees] = useLazyQuery(queries.ShareByEmailModal_employees, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: data => {
      setSelectedEmployees(data.employeesWithSelectAll?.items ?? [])
      setEmployeesTotalCount(data?.employeesWithSelectAll?.totalCount ?? 0)
    },
  })

  if (step === 'employeeList') {
    return (
      <SurveySharedWithEmployeesModal
        onClose={() => setStep('summary')}
        onClickBackArrow={() => setStep('summary')}
        selectedEmployees={selectedEmployees}
        totalCount={employeesTotalCount}
      />
    )
  }

  return (
    <ModalForm
      width={620}
      title={
        step === 'form' ? (
          <FormattedMessage {...messages.emailModalTitle} />
        ) : (
          <FormattedMessage {...messages.emailModalSummaryTitle} />
        )
      }
      opened={props.open}
      onClose={props.onClose}
      form={form}
      customSubmitSuccessText={<FormattedMessage {...messages.emailModalSuccessMessage} />}
      customSubmitButtonText={
        step === 'form' ? (
          <FormattedMessage {...messages.emailModalContinue} />
        ) : (
          <FormattedMessage {...messages.emailModalConfirmAndSave} />
        )
      }
      onSubmit={async values => {
        if (step === 'form') {
          await fetchEmployees({
            variables: {
              institutionId: getMotherId(),
              employees: {
                variant: values.employees.variant as Variant,
                ids: values.employees.ids,
              },
              surveyChannelConfigId:
                survey.config?.__typename === 'SurveyChannelConfiguration' ? survey.config.id : '',
              activeOnly: true,
              page: 0,
              rowsPerPage: employeesPageSize,
            },
          })
          await fetchEmail({
            variables: {
              motherId: getMotherId(),
              channelId: survey.id,
              fromName: values.name,
              buttonText: values.buttonText,
            },
          })

          return false
        }
        if (step === 'summary') {
          if (values.employees._type === 'empty') {
            form.setError('employees', {
              type: 'required',
            })
          }
          await shareSurvey({
            variables: {
              input: {
                motherId: getMotherId(),
                channelId: survey.id,
                employees: {
                  variant: values.employees.variant as Variant,
                  ids: values.employees.ids,
                },
                fromName: values.name,
                buttonText: values.buttonText,
              },
            },
          })
          props.onClose()
          return true
        }
        return false
      }}
    >
      {step === 'form' && (
        <>
          <TextInput
            name='name'
            control={form.control}
            label={<FormattedMessage {...messages.emailModalName} />}
          />
          <EmployeeMultiSelect
            name='employees'
            showSelectAll
            placement='bottomLeft'
            control={form.control}
            label={<FormattedMessage {...messages.employeesLabel} />}
            placeholder={<FormattedMessage {...messages.employeesPlaceholder} />}
            config={{
              type: 'surveyShare',
              surveyConfigId:
                survey.config?.__typename === 'SurveyChannelConfiguration' ? survey.config.id : '',
            }}
          />
          <TextInput
            control={form.control}
            name='buttonText'
            label={<FormattedMessage {...messages.emailModalButtonTextLabel} />}
            hint={<FormattedMessage {...messages.emailModalButtonTextHint} />}
          />
        </>
      )}
      {step === 'summary' && (
        <>
          <Flex gap='16px' vertical>
            <FormItem label={<FormattedMessage {...messages.emailModalSummaryFromLabel} />}>
              {form.getValues('name')}
            </FormItem>
            <FormItem label={<FormattedMessage {...messages.emailModalSummarySubjectLabel} />}>
              {emailSubject}
            </FormItem>
            <FormItem label={<FormattedMessage {...messages.emailModalSummaryMessageLabel} />}>
              <EmailWrapper emailHTML={emailHTML ?? ''} />
            </FormItem>
            <FormItem label={<FormattedMessage {...messages.emailModalSummaryRecipients} />}>
              <Flex gap='small' align='center'>
                {employeesTotalCount}
                <Button
                  type='link'
                  onClick={() => {
                    setStep('employeeList')
                  }}
                  style={{
                    padding: '0',
                    color: '#062D46',
                    height: 'fit-content',
                  }}
                >
                  <UntitledIcon icon={ulEye} />
                </Button>
              </Flex>
            </FormItem>
          </Flex>
          <Text type='secondary'>
            <FormattedMessage {...messages.emailModalSummaryAntispam} />
          </Text>
        </>
      )}
    </ModalForm>
  )
}
