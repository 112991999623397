import styled from '@emotion/styled'
import { downloadFileWithReportKey, readComment, readReport } from '@faceup/crypto'
import { usAttachment01 } from '@faceup/icons/usAttachment01'
import { sharedMessages } from '@faceup/localization'
import { TextUrlfy, Tooltip, UserAvatar } from '@faceup/ui'
import { EyeInvisibleOutlined, Space, Typography, notification } from '@faceup/ui-base'
import { Box } from '@mantine/core'
import moment from 'moment-timezone'
import { useContext, useEffect, useState } from 'react'
import {
  FormattedMessage,
  FormattedMessage as FormattedMessageDef,
  useIntl as useIntlDef,
} from 'react-intl'
import { defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useMarkMessagesAsRead } from '../../hooks/useMarkMessagesAsRead'
import { ChatAttachmentList } from '../AttachmentList'
import { TextToRedact, UsernameAvatarRedact, UsernameRedact } from '../Redaction'
import { RelativeMessagesContext } from './RelativeMessagesContext'

const fragments = {
  FollowUpChatMessage_followUpActivityNode: graphql(`
    fragment FollowUpChatMessage_followUpActivityNode on CompanyReportFollowUpComment {
      __typename
      id
      author {
        ... on CompanyVictim {
          id
        }

        ... on Member {
          id
          name
          profileImageUrl
        }
      }
      translation(
        sourceLanguage: $sourceLanguage
        targetLanguage: $targetLanguage
      ) {
        ciphertext
        nonce
        sourceLanguage
        targetLanguage
      }
      isAuthorRedacted
      isNameHidden
      deleted
      readByMemberIds
      isMessageReadByVictim
      report {
        id
        encryptionKey
        translation {
          id
          body
          bodyNonce
        }
        company {
          id
          organizationalUnitName
          mother {
            id
          }
        }
      }
      attachments {
        id
        name
        url
        mimetype
      }
      createdAt

      ...UseMarkMessagesAsReadFragments_comment
    }
  `),
}

const messages = defineMessages({
  anonymous: 'Shared.chat.anonymous',
  notSeenYet: 'Shared.chat.notSeenYet',
  read: 'Shared.chat.read',
  anonymizedUser: 'Report.chat.anonymizedUser',
})

type Props = {
  orientation: 'left' | 'right'
  message: FragmentType<typeof fragments.FollowUpChatMessage_followUpActivityNode>
  fileDownloadHandler?: (blob: Blob, name: string) => Promise<void>
  onMessageSeen?: () => void
}

const ChatMessage = ({
  orientation,
  message: _message,
  fileDownloadHandler,
  onMessageSeen,
}: Props) => {
  const message = getFragmentData(fragments.FollowUpChatMessage_followUpActivityNode, _message)
  const [authorName, setAuthorName] = useState('')
  const [text, setText] = useState('')
  const relativeMessages = useContext(RelativeMessagesContext)
  const { formatMessage: formatMessageDef } = useIntlDef()
  const { formatMessage } = useIntl()
  const isFollowUp = relativeMessages.type === 'Victim'

  const authorType = message?.author?.__typename

  const isViewersMessage =
    (authorType === 'CompanyVictim' && isFollowUp) || (authorType === 'Member' && !isFollowUp)

  const { ref } = useMarkMessagesAsRead({
    message,
    onCompleted: () => onMessageSeen?.(),
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies(authorName):
  // biome-ignore lint/correctness/useExhaustiveDependencies(relativeMessages):
  // biome-ignore lint/correctness/useExhaustiveDependencies(formatMessageDef):
  // biome-ignore lint/correctness/useExhaustiveDependencies(isFollowUp):
  useEffect(() => {
    const updateAuthorName = async () => {
      const author = message?.author
      const report = message?.report
      const anonymousMessage = formatMessage(messages.anonymous)

      if (author?.__typename === 'Member') {
        if (message?.isAuthorRedacted) {
          setAuthorName(formatMessage(messages.anonymizedUser))
        } else {
          setAuthorName(author?.name ?? report?.company?.organizationalUnitName ?? '')
        }
      }

      if (author?.__typename !== 'CompanyVictim') {
        return
      }

      const payload = await readReport(
        report?.translation?.body ?? '',
        report?.translation?.bodyNonce ?? '',
        report?.encryptionKey ?? ''
      )

      if (payload.isErr()) {
        console.error(payload)
        notification.error({
          message: formatMessage(sharedMessages.encryptionError),
          description: payload.error.message,
        })
        return
      }

      setAuthorName(payload.value.victimName || anonymousMessage)
    }

    const updateText = async () => {
      const report = message?.report
      const payload = await readComment(
        message?.translation?.ciphertext ?? '',
        message?.translation?.nonce ?? '',
        report?.encryptionKey ?? ''
      )

      if (payload.isErr()) {
        console.error(payload)
        notification.error({
          message: formatMessage(sharedMessages.encryptionError),
          description: payload.error.message,
        })
        return
      }

      if (payload.value) {
        setText(payload.value)
      }
    }

    updateText()
    updateAuthorName()
  }, [message, authorName, relativeMessages, formatMessageDef, formatMessage, isFollowUp])

  return (
    <MessageWrapper isRightAligned={orientation === 'right'}>
      <Wrapper isRightAligned={orientation === 'right'}>
        <Box
          sx={theme => ({
            background:
              message?.author?.__typename === 'CompanyVictim'
                ? theme?.colors?.['primary']?.[0]
                : '#f0f3f4',
            whiteSpace: 'pre-line',
            overflow: 'hidden',
            wordBreak: 'break-word',
            borderRadius: orientation === 'right' ? '12px 12px 2px' : '12px 12px 12px 2px',
          })}
          data-cy='message-text'
        >
          <MessageHeaderSender>
            {message?.isNameHidden ? (
              <Tooltip
                title={
                  relativeMessages.type === 'Admin' && (
                    <FormattedMessageDef {...relativeMessages.nameHidden} />
                  )
                }
                placement='top'
              >
                <Space>
                  <UsernameRedact
                    type={
                      message?.author?.__typename === 'Member'
                        ? {
                            type: 'chatMemberUsername',
                            id: message.author?.id ?? '',
                          }
                        : {
                            type: 'sender',
                          }
                    }
                    text={authorName}
                  >
                    {authorName}
                  </UsernameRedact>
                  <EyeInvisibleOutlined />
                </Space>
              </Tooltip>
            ) : (
              <UsernameRedact
                type={
                  message?.author?.__typename === 'Member'
                    ? {
                        type: 'chatMemberUsername',
                        id: message.author?.id ?? '',
                      }
                    : {
                        type: 'sender',
                      }
                }
                text={authorName}
              >
                {authorName}
              </UsernameRedact>
            )}
          </MessageHeaderSender>
          {text && (
            <MessageText className='sensitive'>
              <TextToRedact
                type={{
                  type: 'chatMessages',
                  id: message?.id ?? '',
                }}
                text={text}
              >
                <TextUrlfy text={text} />
              </TextToRedact>
            </MessageText>
          )}
          <ChatAttachmentList
            attachments={message?.attachments ?? []}
            icons={{
              attachment: usAttachment01,
            }}
            download={attachment =>
              downloadFileWithReportKey(
                attachment,
                message?.report?.encryptionKey ?? '',
                fileDownloadHandler
              )
            }
          />
        </Box>
        <MessageFooter isRightAligned={orientation === 'right'}>
          {isViewersMessage && (
            <>
              <MessageFooterContent>
                {(authorType === 'Member' && message?.isMessageReadByVictim) ||
                (authorType === 'CompanyVictim' && message?.readByMemberIds.length) ? (
                  <FormattedMessage {...messages.read} />
                ) : (
                  <FormattedMessage {...messages.notSeenYet} />
                )}
              </MessageFooterContent>
              <Divider />
            </>
          )}
          <MessageFooterContent
            // Should be referenced at bottom of the message.
            // Long messages may not fit entirely within the visible area and might not be marked as read.
            ref={ref}
          >
            {moment(message?.createdAt).format('LLL')}
          </MessageFooterContent>
        </MessageFooter>
      </Wrapper>
      <UsernameAvatarRedact
        type={
          message?.author?.__typename === 'Member'
            ? {
                type: 'chatMemberUsername',
                id: message.author?.id ?? '',
              }
            : {
                type: 'sender',
              }
        }
        text={authorName}
      >
        <AvatarWrapper>
          <UserAvatar user={{ ...message?.author, name: authorName }} />
        </AvatarWrapper>
      </UsernameAvatarRedact>
    </MessageWrapper>
  )
}

const AvatarWrapper = styled.span`
  .ant-avatar {
    @media print {
      display: none;
    }

    background: #0e9af7;
    min-width: 32px;
  }
`

const Wrapper = styled.div<{ isRightAligned: boolean }>`
  display: inline-flex;
  flex-flow: nowrap column;
  padding-bottom: 20px;
  max-width: 80%;
`

const MessageText = styled.div`
  padding: 2px 16px 8px 16px;
  max-width: 600px;
`

const MessageFooterContent = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: #688699;
`

const MessageFooter = styled.div<{ isRightAligned: boolean }>`
  display: flex;
  flex-flow: ${({ isRightAligned }) => (isRightAligned ? 'row-reverse' : 'row')} nowrap;
  gap: 4px;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
`

const Divider = styled.div`
  color: #688699;
  padding: 0 2px;
  &:before {
    content: '•';
  }
`
const MessageWrapper = styled.div<{ isRightAligned: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex-direction: ${({ isRightAligned }) => (isRightAligned ? 'row' : 'row-reverse')};
  margin-inline-end: ${({ isRightAligned }) => (isRightAligned ? '12px' : '0')};
  gap: 8px;
`

const MessageHeaderSender = styled(Typography.Text)`
  display: block;
  padding: 8px 16px 2px 16px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
`

export default ChatMessage
