import { useMutation } from '@apollo/client'
import { Form, ModalConfirm } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { SHORT_STRING_MAX_LENGTH, STRING_MIN_LENGTH, isLength } from '@faceup/utils'
import { TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { useMotherId } from '../../hooks/useMotherId'

const messages = defineMessages({
  editReportSourceTitle: 'Administration.customization.overview.settings.title',
  invalidName: 'Administration.customization.overview.settings.invalidName',
})

const mutations = {
  EditReportSourceName: graphql(`
    mutation EditReportSourceNameMutation($input: EditReportSourceNameInput!) {
      editReportSourceName(input: $input) {
        reportSource {
          id
          name
        }
      }
    }
  `),
}

export const EditReportSourceNameModal = ({
  editModalOpen,
  setEditModalOpen,
  name,
  id,
}: {
  editModalOpen: boolean
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  name: string
  id: string
}) => {
  const { formatMessage } = useIntl()

  const { getMotherId } = useMotherId()

  const form = useForm({
    initialValues: {
      name,
    },
    validate: {
      name: value => {
        if (
          !isLength(value.trim(), {
            min: STRING_MIN_LENGTH,
            max: SHORT_STRING_MAX_LENGTH,
          })
        ) {
          return (
            <FormattedMessage
              {...messages.invalidName}
              values={{ max: SHORT_STRING_MAX_LENGTH, min: STRING_MIN_LENGTH }}
            />
          )
        }

        return null
      },
    },
  })

  const [editReportSourceName, { loading: editLoading }] = useMutation(
    mutations.EditReportSourceName,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted: () => {
        setEditModalOpen(false)
      },
      refetchQueries: ['InstitutionCustomizationFormListQuery'],
    }
  )

  const submitName = () => {
    form.validate()
    if (form.isValid()) {
      editReportSourceName({
        variables: {
          input: {
            motherId: getMotherId(),
            reportSourceId: id,
            name: form.values.name,
          },
        },
      })
    }
  }

  return (
    <ModalConfirm
      title={<FormattedMessage {...messages.editReportSourceTitle} />}
      opened={editModalOpen}
      onClose={() => {
        setEditModalOpen(false)
      }}
      onConfirm={() => {
        submitName()
      }}
      confirmState={{
        loading: editLoading,
        disabled: !form.isDirty(),
      }}
    >
      <Form onSubmit={submitName}>
        <TextInput {...form.getInputProps('name')} />
      </Form>
    </ModalConfirm>
  )
}
